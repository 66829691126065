export const isObj = o => typeof o === 'object' && o !== null;
export const isFn = o => typeof o === 'function' && o !== null;
export const isNully = o => o === null || o === undefined;

export const compareArraysAsSetEquality = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr2.indexOf(arr1[i]) < 0) {
      return false;
    }
  }

  for (let i = 0; i < arr2.length; i++) {
    if (arr1.indexOf(arr2[i]) < 0) {
      return false;
    }
  }

  return true;
};

export const get = (obj, path, defaultVal = null) => {
  if (!Array.isArray(path)) return defaultVal;

  let ref = obj;

  const recurse = idx => {
    if (idx === path.length) return ref;
    if (typeof ref !== 'object' || ref === null || ref === undefined)
      return defaultVal;
    if (idx >= path.length) return defaultVal;

    const key = path[idx];
    ref = ref[key];

    return recurse(idx + 1);
  };

  return recurse(0);
};

export const reverseLookup = (o, v) => {
  if (typeof o !== 'object') return null;
  for (const x of Object.keys(o)) {
    if (o[x] === v) return x;
  }

  return null;
};

export const cloneDataObject = o => {
  if (isObj(o)) {
    return JSON.parse(JSON.stringify(o));
  } else {
    return null;
  }
};

export const isDeepEqual = (obj1, obj2, stringCaseInsensitiveMatch) => {
  if (obj1 === obj2) return true;

  if (isPrimitive(obj1) && isPrimitive(obj2)) {
    if (stringCaseInsensitiveMatch && isString(obj1) && isString(obj2)) {
      return obj1.toLowerCase() === obj2.toLowerCase();
    }
    return obj1 === obj2;
  }

  if (Object.keys(obj1).length !== Object.keys(obj2).length) return false;

  for (let key in obj1) {
    if (!(key in obj2)) return false;
    if (!isDeepEqual(obj1[key], obj2[key])) return false;
  }

  return true;
};

export const isCaseInsensitiveEqual = (obj1, obj2) => {
  return isDeepEqual(obj1, obj2, true);
};

export const isString = value => {
  return typeof value === 'string' || value instanceof String;
};

const isPrimitive = obj => {
  return obj !== Object(obj);
};

export const isDefined = r => r !== null && r !== undefined;

export const isObject = val => {
  if (val === null) {
    return false;
  }
  return typeof val === 'function' || typeof val === 'object';
};

export const emptyObj = obj => {
  for (let key in obj) {
    if (obj[key]) {
      return false;
    }
  }
  return true;
};
