import React from 'react';
import Label from 'components/form/Label/Label';
import { Button } from 'components/form/Button';
import { FlexContainer } from 'components/Containers/FlexContainer/FlexContainer';
import OText from 'components/CText/OText';
import { Tag } from 'components/Tag';
import { Row } from 'components/Containers/Row/Row';

const PremiumReactivatePendingCancellationMenuListView = props => {
  const {
    collapsed,
    openPremiumReactivatePendingCancellationModal,
    isPremiumReactivatePendingCancellationUser,
    premiumReactivatePendingCancellationConfig,
    daysLeft,
    seenPixel,
  } = props;

  if (collapsed || !isPremiumReactivatePendingCancellationUser) {
    return '';
  } else {
    return (
      <>
        {seenPixel}
        <Row className="pos-rel" fullWidth>
          <Label className="padLR10">
            <Tag
              className="pos-abs"
              backgroundClass="bgYellow075"
              colorClass="fontNeutral500"
              style={{
                top: -35,
                right: 0,
              }}
            >
              {daysLeft} Days Left
            </Tag>

            <FlexContainer alignItemsCenter className="margTB10">
              <Label className="Subtitle-3">
                <OText
                  text={
                    premiumReactivatePendingCancellationConfig?.nav_menu?.text
                  }
                />
              </Label>
            </FlexContainer>
            <FlexContainer column alignItemsCenter>
              <Button
                mrphType="primary"
                className="fullWidth"
                onClick={openPremiumReactivatePendingCancellationModal}
              >
                <OText
                  text={
                    premiumReactivatePendingCancellationConfig?.nav_menu?.cta
                  }
                />
              </Button>
            </FlexContainer>
          </Label>
        </Row>
      </>
    );
  }
};
PremiumReactivatePendingCancellationMenuListView.defaultProps = {
  collapsed: false,
};
export default PremiumReactivatePendingCancellationMenuListView;
