import { getGetParam } from 'helpers/domHelper';
import { optimizelyPush } from './optimizely.util';
import { getDaysSince } from 'helpers/dateHelper';
import { unreadAlertsCount } from 'helpers/alerts.helpers';
import { setItem, removeItem, getCStrack } from 'helpers/localstorage';
import {
  hasAutoLoanAccounts,
  hasMortgage,
  getNegativeRemarksCount,
  getTotalCreditCardDebt,
  getDerogatoryAccountsCount,
  getWhatsChanged,
} from 'helpers/credit_info';
import {
  isPremium,
  isCROACompliant,
  isInvisibleUserForSignup,
  isPremiumPaymentPending,
  isPremiumFromOcf,
  isPreviouslyPremium,
  isPremiumCancelPending,
  isPlus1BFreeTrial,
  isPlus3BFreeTrial,
  isPlus,
  isPlus3B,
  isPlus1B,
  isEnrolledToCreditLock,
  isTUEnrollementFailed,
  isConvertedToPaidUser,
  isFreemium,
  isEnrolledToday,
} from 'helpers/userHelper';
import { get } from 'helpers/objectHelpers';
import './optimizely.public';
import { canUserEnrollInCash } from 'helpers/sesameCash.helpers';
import {
  getInitialReferrer,
  getMarketingCookieAttr,
} from 'helpers/marketing.helper';
import { optimizelyClient } from './optFlags';
import { isMobileByOS } from 'externals/_tracking/dom/navigator';
import { getHavePreApprovedOffers } from 'helpers/localstorage';
import { isUserFromEnrolledPremiumCampaign } from '../../../helpers/login.helpers';
import {
  BUILD_SCORE_OVER_TIME,
  CARD_LIMITS,
  CC_APPROVAL_ODDS,
  CREDIT_FOUNDATION,
  HOLIDAY_SPENDING,
  SIMULATION_NEW_CARD_OR_DECLINE,
  WHATS_CHANGED,
} from 'types/mrph.pages.constants';
import { getCardModule } from 'helpers/offer.helpers';
import { GRADE_TREND_DOWN, GRADE_TREND_UP } from 'types/creditInfo.constants';
import {
  qualifyToCreditBuilder,
  qualifyToRentReporting,
} from 'helpers/goals.helpers';
import { REACT_APP_BRAND } from 'helpers/env';

export const OCF_PAGES_WITH_LOGIN_CTA = [
  CARD_LIMITS,
  CC_APPROVAL_ODDS,
  HOLIDAY_SPENDING,
  SIMULATION_NEW_CARD_OR_DECLINE,
];

export const OCF_PAGES_WITH_HIDE_MENU = [
  BUILD_SCORE_OVER_TIME,
  CREDIT_FOUNDATION,
  WHATS_CHANGED,
];

export const reportOptimizelyFS = eventName => {
  optimizelyClient?.track(eventName);
};

export const reportOptimizely = (eventName, eventType) => {
  let reportingObj = {};
  if (typeof eventType !== 'undefined' && eventType === 'page') {
    reportingObj.type = 'page';
    reportingObj.pageName = eventName;
  } else {
    reportingObj.type = 'event';
    reportingObj.eventName = eventName;
  }
  optimizelyPush(reportingObj);
  reportOptimizelyFS(eventName);
};

/**
 * setCSContext
 * Setting up window object to evaluate custom javascript for opt audiences
 */

export const setCSContext = state => {
  let { userInfo, creditInfo } = state;

  userInfo = userInfo && userInfo.userInfo ? userInfo.userInfo : null;
  creditInfo =
    creditInfo && creditInfo.creditInfo ? creditInfo.creditInfo : null;
  setCSContentByDataPoints({ userInfo, creditInfo });
};

export const setCSContentByDataPoints = state => {
  const CSContext = buildOptimizelyDatapoints(state);
  setItem('CSContext', JSON.stringify(CSContext));
};

const substituteUndefinedForNulls = o => {
  Object.keys(o).forEach(k => {
    if (o[k] === undefined) {
      o[k] = null;
    }
  });
};

const getPreApprovedInfo = creditCardCategoriesContainer => {
  const preApprovedOffersLength = getCardModule(
    creditCardCategoriesContainer,
    'PRE_APPROVED_CARDS',
  )?.offers?.length;
  return {
    isPreApproved:
      preApprovedOffersLength?.length > 0 ||
      JSON.parse(getHavePreApprovedOffers() ?? '{}'),
    preApprovedOffersLength,
  };
};

const getGradeChange = (grade1, grade2) => {
  if (grade1 < grade2) {
    return GRADE_TREND_UP;
  } else if (grade1 > grade2) {
    return GRADE_TREND_DOWN;
  } else {
    return '';
  }
};

export const buildOptimizelyDatapoints = state => {
  const {
    creditInfo,
    sesameCashAccount,
    userInfo,
    ocfInfo,
    page,
    alertSettings,
    subscriptionInfo,
    creditCardCategoriesContainer,
    app,
    todayContainer,
    oneClickSignup,
  } = state;
  // please ensure that no key-value pair returns a undefined as a the value

  const isOcfLoggedIn = app?.ocfLoggedIn;
  const isPremiumUser = ocfInfo
    ? isPremiumFromOcf(ocfInfo)
    : isPremium(userInfo);
  const userToken = ocfInfo ? getGetParam('fobs') : userInfo?.userId;
  const { isPreApproved, preApprovedOffersLength } = getPreApprovedInfo(
    creditCardCategoriesContainer,
  );
  const hasEngagementOfferModule =
    getCardModule(todayContainer, 'PROFILE_PAGE_EVENT_OFFER')?.offers?.length >
    0;

  const isValidOCFLogInCTA =
    ocfInfo && OCF_PAGES_WITH_LOGIN_CTA.includes(page.pageName);
  const isValidOCFWithHideMenu =
    ocfInfo && OCF_PAGES_WITH_HIDE_MENU.includes(page.pageName);

  const whatsChanged = creditInfo ? getWhatsChanged(creditInfo) : '';

  const grades = creditInfo ? creditInfo?.trends?.grades : null;

  const currentGrades = grades ? grades[grades?.length - 1] : null;

  const optimizelyDatapoints = {
    creditScore: creditInfo ? creditInfo.creditScore : null,
    daysSinceRegistration: userInfo ? getDaysSince(userInfo.createdDate) : null,
    signupUser:
      (userInfo &&
        (userInfo.signUpStatus !== 'registeredUser' ||
          !userInfo.previousLoginDate)) ??
      false,
    hasAutoLoanDebt: hasAutoLoanAccounts(creditInfo),
    isPremiumUser: isPremiumUser,
    isPremiumPaymentPending: isPremiumPaymentPending(userInfo),
    isFreeTrial: userInfo?.premiumPlanInfo?.freeTrialPeriod,
    is1BFreeTrial: isPlus1BFreeTrial(userInfo),
    is3BFreeTrial: isPlus3BFreeTrial(userInfo),
    isConvertedToPaid: isConvertedToPaidUser(userInfo),
    isFreeUser: isFreemium(userInfo),
    isPlusUser: isPlus(userInfo),
    isPlus3B: isPlus3B(userInfo),
    isPlus1B: isPlus1B(userInfo),
    isEnrolledToday: isEnrolledToday(userInfo),
    enrolledToCreditLock: isEnrolledToCreditLock(userInfo),
    tuEnrollmentFailed: isTUEnrollementFailed(userInfo),
    qualify_rr: qualifyToRentReporting({ userInfo, creditInfo }),
    qualify_cb: qualifyToCreditBuilder({
      userInfo,
      creditInfo,
      sesameCashAccount,
    }),
    isCROACompliant: isCROACompliant(userInfo),
    isValidOCFLogInCTA,
    isValidOCFWithHideMenu,
    trialPeriodUsed: userInfo?.trialPeriodUsed ?? null,
    hasMortgage: hasMortgage(creditInfo),
    isCashEnrolled:
      get(userInfo, ['banking', 'enrollStatus'], null) === 'REGISTERED',

    negativeRemarksCount: getNegativeRemarksCount(creditInfo),
    derogatoryAccountsCount: getDerogatoryAccountsCount(creditInfo),
    userSegment: get(userInfo, ['userSegmentationNumber'], null),
    canEnrollInCash: canUserEnrollInCash(userInfo),
    hasCashEnrolled:
      get(userInfo, ['banking', 'enrollStatus'], null) === 'REGISTERED',
    hasCashFunded: sesameCashAccount?.receivedFirstFund ?? false,
    hasUnreadAlertsThreeMonth: unreadAlertsCount(state, 90) > 0,
    hasCashBooster: sesameCashAccount?.securedCard?.hasCard ?? false,
    totalCreditCardDebt: getTotalCreditCardDebt(creditInfo),
    isInvisibleUser: isInvisibleUserForSignup(userInfo),
    initialReferrer: getInitialReferrer() ?? '',
    mktOfferName: getMarketingCookieAttr('OfferName') ?? '',
    rentReportingStatus: userInfo?.rentReporting?.status,
    rentReportingSubStatus: userInfo?.rentReporting?.subStatus,
    isPreApprovedModuleEligible: isPreApproved,
    preApprovedOffersLength,
    hasEngagementOfferModule,
    isHomeOwner: userInfo?.homeOwnerStatus === 'HOMEOWNER',
    isRenterWithCreditScoreUnder651:
      creditInfo?.creditScore < 651 && userInfo?.homeOwnerStatus === 'RENTER',
    isRenterWithCreditScoreOver650:
      creditInfo?.creditScore > 650 && userInfo?.homeOwnerStatus === 'RENTER',
    sessionIdentifier: getCStrack(),
    userToken,
    isMobileByOS: isMobileByOS(),
    isEnrolledPremiumCampaign: isUserFromEnrolledPremiumCampaign(),
    preQualProgramReminder:
      alertSettings?.preQualification?.optOut === 0 ? true : false,
    previouslyPremiumAccount: isPreviouslyPremium(userInfo),
    isPremiumPendingCancellation: isPremiumCancelPending(userInfo),
    billingEntity:
      subscriptionInfo?.billingEntity ??
      userInfo?.premiumPlanInfo?.billingEntity,
    ocfOffersLength: isOcfLoggedIn && ocfInfo?.offers?.length,
    isOcfLoggedIn,

    score_change: whatsChanged?.creditScore?.difference ?? '',
    number_late_payments:
      creditInfo?.creditScoreAnalysis?.paymentHistory?.latePaymentAccounts ?? 0,
    credit_utilization_ratio: whatsChanged?.creditUsage?.current ?? '',
    credit_utilization_ratio_change:
      whatsChanged?.creditUsage?.difference ?? '',
    number_negative_marks: whatsChanged?.negativeMarks?.current ?? '',
    number_negative_marks_change: whatsChanged?.negativeMarks?.difference ?? '',
    number_collections: whatsChanged?.collections?.current ?? '',
    number_inquiries: whatsChanged?.creditInquiries?.current ?? '',
    number_inquiries_change: whatsChanged?.creditInquiries?.difference,
    payment_history_grade: currentGrades?.paymentHistoryGrade ?? '',
    payment_history_grade_change: getGradeChange(
      whatsChanged?.paymentHistoryGrade?.current,
      whatsChanged?.paymentHistoryGrade?.previous,
    ),
    credit_utilization_grade: whatsChanged?.creditUsageGrade?.current ?? '',
    credit_utilization_grade_change: getGradeChange(
      whatsChanged?.creditUsageGrade?.current,
      whatsChanged?.creditUsageGrade?.previous,
    ),
    isProveElegible: oneClickSignup?.proveElegibleAudience,
    env: REACT_APP_BRAND,
  };
  substituteUndefinedForNulls(optimizelyDatapoints);

  return optimizelyDatapoints;
};

export const clearCSContext = () => {
  removeItem('CSContext');
};
