import { formatIncome, unformatIncome } from 'helpers/money.helper';

export const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
  let angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
};

export const drawCircle = (x, y, radius, startAngle, endAngle) => {
  var start = polarToCartesian(x, y, radius, endAngle);
  var end = polarToCartesian(x, y, radius, startAngle);

  var arcSweep = endAngle - startAngle <= 180 ? '0' : '1';

  var d = [
    'M',
    start.x,
    start.y,
    'A',
    radius,
    radius,
    0,
    arcSweep,
    0,
    end.x,
    end.y,
    'L',
    x,
    y,
    'L',
    start.x,
    start.y,
  ].join(' ');

  return d;
};

export const allZero = arr => {
  return arr.reduce((acc, curr) => {
    return acc + curr.score;
  }, 0);
};

export const countNotNulls = data => {
  let nullCount = 0;
  for (let i = 0; i < data.length; i++) {
    if (data[i].score !== null) {
      nullCount++;
    }
  }
  return nullCount;
};

export const grabNonNullCreditScore = data => {
  let creditscore = 0;
  for (let i = 0; i < data.length; i++) {
    if (data[i].score !== null) {
      creditscore = data[i].score;
      return creditscore;
    }
  }
  return creditscore;
};

export const isLetter = str => {
  return str.length === 1 && str.match(/[a-z]/i);
};

export const validateSSN = (ssn, validateEmpty) => {
  if (!validateEmpty && !ssn) {
    return '';
  }
  const regex = /^[0-9]+$/;
  if (regex.test(ssn) && ssn.length === 4) {
    return true;
  } else {
    return 'Please enter a valid 4 digit number';
  }
};

export const validateIncome = (income, notEmpty) => {
  let unformatted = unformatIncome(income);
  if (notEmpty && unformatted === '') {
    return 'Please enter a valid number';
  }
  if (!unformatted) {
    return '';
  }
  const regex = /^[0-9]+$/;
  if (regex.test(unformatted)) {
    return true;
  } else {
    return 'Please enter a valid number';
  }
};

export const validateMarcusIncome = (income, notEmpty) => {
  let unformatted = unformatIncome(income);
  if (notEmpty && unformatted === '') {
    return 'Please enter a valid number';
  }
  if (!unformatted) {
    return '';
  }
  const regex = /^[0-9]+$/;
  if (regex.test(unformatted) && unformatted >= 40000) {
    return true;
  } else {
    return 'Sorry income must be at least $40,000 a year to qualify for a Marcus loan';
  }
};

export const debounce = (func, wait, immediate) => {
  let timeout;
  return function () {
    const context = this,
      args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

export const formatIncomeWithNegatives = value => {
  let tempValue = formatIncome(Math.abs(value));
  if (value < 0) {
    tempValue = '-' + tempValue;
  }
  return tempValue;
};

export const filterBusinessTitle = (creditInfo, titles) => {
  const showBusinessTitle = creditInfo?.creditScore >= 530;
  const titleOffersFiltered = titles.filter(item =>
    item?.text ? item?.text !== 'Business' : item?.title !== 'Business',
  );
  return showBusinessTitle ? titles : titleOffersFiltered;
};

export const getKeyByValue = (obj, value) => {
  return Object.keys(obj).find(key => obj[key] === value);
};

export const urlSearchParams = (search, get) => {
  const urlSearch = new URLSearchParams(search)?.get(get);
  return urlSearch;
};

export const getIovationBlackbox = () => {
  if (window.IGLOO && typeof window.IGLOO.getBlackbox === 'function') {
    const { blackbox } = window.IGLOO.getBlackbox();
    return blackbox;
  } else {
    return null;
  }
};
