import {
  PLAID_LINK_TOKEN_REQUEST,
  PLAID_LINK_TOKEN_ERROR,
  PLAID_LINK_TOKEN_SUCCESS,
  PLAID_LINKED_ACCOUNTS_REQUEST,
  PLAID_LINKED_ACCOUNTS_ERROR,
  PLAID_LINKED_ACCOUNTS_SUCCESS,
  PLAID_LINK_ACCOUNT_REQUEST,
  PLAID_LINK_ACCOUNT_ERROR,
  PLAID_LINK_ACCOUNT_SUCCESS,
  PLAID_FUND_TRANSFER_REQUEST,
  PLAID_FUND_TRANSFER_ERROR,
  PLAID_FUND_TRANSFER_SUCCESS,
  PLAID_REMOVE_ACCOUNT_REQUEST,
  PLAID_REMOVE_ACCOUNT_ERROR,
  PLAID_REMOVE_ACCOUNT_SUCCESS,
  PLAID_LINKED_ACCOUNTS_LOGO_REQUEST,
  PLAID_LINKED_ACCOUNTS_LOGO_ERROR,
  PLAID_LINKED_ACCOUNTS_LOGO_SUCCESS,
  PLAID_CLEAN,
  PLAID_LINK_TOKEN_REMOVE,
} from '../types/plaid.constants';

const initialState = {
  plaidLinkTokenLoading: null,
  plaidLinkTokenError: null,
  plaidLinkToken: null,
  plaidLinkedAccountsLoading: null,
  plaidLinkedAccountsError: null,
  plaidLinkedAccounts: null,
  plaidLinkedAccountsWithLogoLoading: null,
  plaidLinkedAccountsWithLogoError: null,
  plaidLinkedAccountsWithLogo: null,
  plaidLinkAccountLoading: null,
  plaidLinkAccountError: null,
  plaidLinkAccountSuccess: null,
  plaidFundTransferLoading: null,
  plaidFundTransferError: null,
  plaidFundTransferSuccess: null,
  plaidRemoveAccountLoading: null,
  plaidRemoveAccountError: null,
  plaidRemoveAccountSuccess: null,
};

export default function plaidReducer(state = initialState, action) {
  switch (action.type) {
    case PLAID_LINK_TOKEN_REQUEST:
      return Object.assign({}, state, {
        plaidLinkTokenLoading: true,
      });
    case PLAID_LINK_TOKEN_ERROR:
      return Object.assign({}, state, {
        plaidLinkTokenLoading: false,
        plaidLinkTokenError: action.error,
      });
    case PLAID_LINK_TOKEN_SUCCESS:
      return Object.assign({}, state, {
        plaidLinkTokenLoading: false,
        plaidLinkToken: action.linkToken,
      });
    case PLAID_LINK_TOKEN_REMOVE:
      return Object.assign({}, state, {
        plaidLinkTokenLoading: false,
        plaidLinkToken: null,
      });

    // Linked accounts
    case PLAID_LINKED_ACCOUNTS_REQUEST:
      return Object.assign({}, state, {
        plaidLinkedAccountsWithLogoLoading: true,
        plaidLinkedAccountsLoading: true,
        plaidLinkedAccountsError: false,
      });
    case PLAID_LINKED_ACCOUNTS_ERROR:
      return Object.assign({}, state, {
        plaidLinkedAccountsLoading: false,
        plaidLinkedAccountsError: action.error,
      });
    case PLAID_LINKED_ACCOUNTS_SUCCESS:
      return Object.assign({}, state, {
        plaidLinkedAccountsLoading: false,
        plaidLinkedAccountsError: false,
        plaidLinkedAccounts: action.linkedAccounts,
      });

    // Linked accounts with logos
    case PLAID_LINKED_ACCOUNTS_LOGO_REQUEST:
      return Object.assign({}, state, {
        plaidLinkedAccountsWithLogoLoading: true,
        plaidLinkedAccountsWithLogoError: false,
      });
    case PLAID_LINKED_ACCOUNTS_LOGO_ERROR:
      return Object.assign({}, state, {
        plaidLinkedAccountsWithLogoLoading: false,
        plaidLinkedAccountsWithLogoError: action.error,
      });
    case PLAID_LINKED_ACCOUNTS_LOGO_SUCCESS:
      return Object.assign({}, state, {
        plaidLinkedAccountsWithLogoLoading: false,
        plaidLinkedAccountsWithLogoError: false,
        plaidLinkedAccountsWithLogo: action.linkedAccountsWithLogo,
      });

    //Link Account
    case PLAID_LINK_ACCOUNT_REQUEST:
      return Object.assign({}, state, {
        plaidLinkAccountLoading: true,
        plaidLinkAccountSuccess: null,
        plaidLinkAccountError: null,
      });
    case PLAID_LINK_ACCOUNT_ERROR:
      return Object.assign({}, state, {
        plaidLinkAccountLoading: false,
        plaidLinkAccountSuccess: false,
        plaidLinkAccountError: action.error,
      });
    case PLAID_LINK_ACCOUNT_SUCCESS:
      return Object.assign({}, state, {
        plaidLinkAccountLoading: false,
        plaidLinkAccountSuccess: true,
        plaidLinkAccountError: false,
      });

    //Fund Transfer
    case PLAID_FUND_TRANSFER_REQUEST:
      return Object.assign({}, state, {
        plaidFundTransferLoading: true,
        plaidFundTransferSuccess: null,
        plaidFundTransferError: null,
      });
    case PLAID_FUND_TRANSFER_ERROR:
      return Object.assign({}, state, {
        plaidFundTransferLoading: false,
        plaidFundTransferSuccess: false,
        plaidFundTransferError: action.error,
      });
    case PLAID_FUND_TRANSFER_SUCCESS:
      return Object.assign({}, state, {
        plaidFundTransferLoading: false,
        plaidFundTransferSuccess: true,
        plaidFundTransferError: false,
      });

    // Remove Account
    case PLAID_REMOVE_ACCOUNT_REQUEST:
      return Object.assign({}, state, {
        plaidRemoveAccountLoading: true,
        plaidRemoveAccountSuccess: null,
        plaidRemoveAccountError: null,
      });
    case PLAID_REMOVE_ACCOUNT_ERROR:
      return Object.assign({}, state, {
        plaidRemoveAccountLoading: false,
        plaidRemoveAccountSuccess: false,
        plaidRemoveAccountError: action.error,
      });
    case PLAID_REMOVE_ACCOUNT_SUCCESS:
      return Object.assign({}, state, {
        plaidRemoveAccountLoading: false,
        plaidRemoveAccountSuccess: true,
        plaidRemoveAccountError: false,
      });
    case PLAID_CLEAN:
      return initialState;
    default:
      return state;
  }
}
