//Experiment constants
export const SCORE_INCREASE_IN_CCMODULE = 'SCORE_INCREASE_IN_CCMODULE';
export const REDUCE_TILE_SIZE_MY_ACTIONS = 'REDUCE_TILE_SIZE_MY_ACTIONS';
export const ACTIONS_FLOW_OPT = 'ACTIONS_FLOW_OPT';
export const PREFILLED_SIGNUP = 'PREFILLED_SIGNUP';
export const SIGNUP_SKIP_CASH_ENROLL = 'SIGNUP_SKIP_CASH_ENROLL';
export const SIGNUP_DUP_SSN_KBA_VERIFY_PHONE =
  'SIGNUP_DUP_SSN_KBA_VERIFY_PHONE';
export const TWO_STEP_LOGIN = 'TWO_STEP_LOGIN';
export const AUTO_LOAN_REFINANCE_OVERVIEW = 'AUTO_LOAN_REFINANCE_OVERVIEW';
export const CASH_ENROLL_FIRST_OVERVIEW = 'CASH_ENROLL_FIRST_OVERVIEW';
export const SHOW_CC_MODULE_HIGHER_IN_RANKING =
  'SHOW_CC_MODULE_HIGHER_IN_RANKING';
export const SHOW_ACTIONS_HIGHER_ON_DASHBOARD =
  'SHOW_ACTIONS_HIGHER_ON_DASHBOARD';
export const CASH_DIRECT_DEPOSIT_COPY = 'CASH_DIRECT_DEPOSIT_COPY';
export const NEW_AOOP_PREMIUM_BANNERS = 'NEW_AOOP_PREMIUM_BANNERS';
export const REDESIGN_NOTIFICATIONS_CENTER = 'REDESIGN_NOTIFICATIONS_CENTER';
export const PLAID_FUNNEL_TEST = 'PLAID_FUNNEL_TEST';
export const PREMIUM_LANDING_PAGE_V4 = 'PREMIUM_LANDING_PAGE_V4';
export const PREMIUM_DELIGHT_MESSAGE = 'PREMIUM_DELIGHT_MESSAGE';
export const TURBO_RENT_REPORTING = 'TURBO_RENT_REPORTING';
export const TURBO_1_WEEK_FREE_DISCOUNT = 'PREMIUMTURBONODISCOUNT';
export const SESAME_TURBO_PREMIUM_LANDING_PAGE =
  'SESAME_TURBO_PREMIUM_LANDING_PAGE';
export const OCF_ALL_PRODUCTS = 'OCF_ALL_PRODUCTS';
export const OCF_WHATS_CHANGE_REPORT_CARD = 'OCF_WHATS_CHANGE_REPORT_CARD';
export const WEB_BOOSTER_SIGNUP_TEST = 'WEB_BOOSTER_SIGNUP_TEST';
export const SCORE_OVER_TIME_SIMULATION_IN_CCMODULE =
  'SCORE_OVER_TIME_SIMULATION_IN_CCMODULE';
export const SCORE_INCREASE_SIMULATION_IN_CCMODULE =
  'SCORE_INCREASE_SIMULATION_IN_CCMODULE';
export const RANDOMIZE_OFFER_TILES = 'RANDOMIZE_OFFER_TILES';
export const NU_BUILDER_RELAUNCH = 'NU_BUILDER_RELAUNCH';
export const RU_BUILDER_RELAUNCH = 'RU_BUILDER_RELAUNCH';

/** Experiment identifier using actual experiment name from optimizely (without the environment prefix) */
export const EXP_INSURANCE_ON_OVERVIEW = 'Insurance On Overview';
export const TWO_STEP_LOGIN_ID =
  'Login page (Email and Email password) POD2-31';
export const OCF_REBALANCE_CREDIT_UTILIZATION =
  'OCF_REBALANCE_CREDIT_UTILIZATION';
export const AUTO_INSURANCE_INTEGRATION = 'AUTO_INSURANCE_INTEGRATION';
export const LIFE_INSURANCE_INTEGRATION = 'LIFE_INSURANCE_INTEGRATION';
export const EXP_SECURED_PERSONAL_LOAN = 'SECURE_PERSONAL_LOAN'; // POD3-1504
export const PREMIUM_TURBO_CHECKOUT_V2 = 'PREMIUM_TURBO_CHECKOUT_V2';
export const OVERVIEW_BAR_NOTIFICATION_V1 = 'OVERVIEW_BAR_NOTIFICATION_V1';
export const OCF_NEW_CARD_OR_DECLINE_SCORE_SIMULATION =
  'OCF_NEW_CARD_OR_DECLINE_SCORE_SIMULATION';
export const APPLEPAY_CHECKOUT = 'APPLEPAYCHECKOUT';
export const CROA_COMPLIANT = 'CROA_COMPLIANT';
export const PERSONAL_LOAN_HIGH_CC_DEBT = 'PERSONAL_LOAN_HIGH_CC_DEBT';
export const PROFILE_PREMIUM_BANNERS = 'PROFILE_PREMIUM_BANNERS';
export const PUBLIC_PREMIUM_LANDING_PAGE = 'PUBLIC_PREMIUM_LANDING_PAGE';
export const CARVANA_AUTO_PURCHASE = 'CARVANA_AUTO_PURCHASE';
export const OVERVIEW_3_BUREAU_DIAL = '3_BUREAU_DIAL';
export const LOGIN_LOGOUT_LIVE_CHANGES = 'login_logout_live_changes';
export const BUILD_OVERVIEW_QUESTIONNAIRE_PHASE_1 =
  'build_overview_questionnaire_phase_1';
export const STABILIZE_OVERVIEW_QUESTIONNAIRE_PHASE_1 =
  'stabilize_overview_questionnaire_phase_1';
export const ACCUMULATE_OVERVIEW_QUESTIONNAIRE_PHASE_1 =
  'accumulate_overview_questionnaire_phase_1';
export const ALERT_CREDIT_MONITORING_UPSELL = 'ALERT_CREDIT_MONITORING_UPSELL'; //PREM-2441
export const CRM_CAMPAIGN_ENROLL_PREMIUM = 'crm_campaign_enroll_premium';
export const COPY_ON_ACCOUNT_CREATION_PAGE = 'copy_on_account_creation_page';
export const ONE_TIME_PASSWORD_ON_LOGIN = 'one_time_password_on_login';
export const DSR_ON_SIGN_UP = 'dsr_on_sign_up';
export const DSR_ON_LOGIN = 'dsr_on_login';
export const SELF_LOAN_FOR_TU_ERRORS = 'self_loan_for_tu_errors';
export const NEW_ONE_CLICK_SIGNUP = 'new_one_click_signup';

export const SIMPLISTIC_ACCOUNT_CREATION_SUB_HEADING =
  'simplistic_account_creation_sub_heading';
export const PASSWORDLESS_LOGIN_V2 = 'passwordless_login_v2';
export const NEXTGEN_3B_FREEMIUM_FREETRIAL_ACTIVE =
  'nextgen3b_freemium_freetrial_active';
export const PREMIUM_MONITORING_BANNER_WEB = 'premiummonitoringbanner_web';
export const CREDIT_ACCOUNT_TAB = 'credit_account_tab';
