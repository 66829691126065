export const DISABLE = 'DISABLE';
export const ENROLL_CTA = 'ENROLL_CTA';
export const ENROLL_SUCCESS = 'ENROLL_SUCCESS';
export const RESEND_OPTIONS = 'RESEND_OPTIONS';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const UPDATE_PHONE = 'UPDATE_PHONE';
export const VERIFY_IDENTITY = 'VERIFY_IDENTITY';
export const LOADING = 'LOADING';
export const CONFIRM_SSN = 'CONFIRM_SSN';
export const CONFIRM_SSN_ERROR = 'CONFIRM_SSN_ERROR';
export const WE_ARE_SORRY_ATTEMPTS = 'WE_ARE_SORRY_ATTEMPTS';
export const REQUEST_SECURITY_CODE = 'REQUEST_SECURITY_CODE';
export const ENTER_SECURITY_CODE_MFA = 'ENTER_SECURITY_CODE_MFA';
export const TRY_ANOTHER_METHOD_MFA = 'TRY_ANOTHER_METHOD_MFA';

export const SET_DISABLE_VERIFY_BUTTON = 'SET_DISABLE_VERIFY_BUTTON';
export const SET_DISABLE_UPDATE_PHONENUMBER_BUTTON =
  'SET_DISABLE_UPDATE_PHONENUMBER_BUTTON';
