import { DisclaimerContext } from 'hoc/DisclaimerContext.hoc';
import { useContext } from 'react';
import { ReactComponent as TuLogo } from 'style/_assets/icons/tu_logo.svg';
import { CSTooltip } from 'components/Tooltip/CSToolTip';
import './Footer.scss';
import { APP_FOOTER_MESSAGE } from 'helpers/env';
import Label from 'components/form/Label/Label';

const FooterView = props => {
  const { datatestid } = props;
  const { config, hideFooterLine } = props;
  const currentYear = new Date().getFullYear();
  let disclaimers = {};
  const disclaimerContext = useContext(DisclaimerContext);
  if (config) {
    disclaimers = config.disclaimers;
  }
  const disclaimerKeys = Object.keys(disclaimerContext?.disclaimers ?? {});

  return (
    <>
      {disclaimerKeys?.length > 0 && (
        <div>
          {disclaimerKeys?.map(k => disclaimerContext?.disclaimers[k] ?? '')}
        </div>
      )}
      <div className="footer" data-testid={datatestid ? `${datatestid}` : null}>
        {!hideFooterLine && (
          <div
            data-testid={datatestid ? `${datatestid}-line` : null}
            className="footer-line"
          ></div>
        )}
        <div
          data-testid={datatestid ? `${datatestid}-link-container` : null}
          className="footer-link-container margT8"
        >
          <div className="footer-copyright">
            <Label
              type="caption"
              color="fontGray300"
              datatestid={datatestid ? `${datatestid}-copyright` : null}
            >
              {`© 2010–${currentYear} ${APP_FOOTER_MESSAGE} | All Rights Reserved`}
            </Label>
          </div>
          {!props.hideLinks && (
            <div
              data-testid={datatestid ? `${datatestid}-links` : null}
              className="footer-links"
            >
              <CSTooltip
                placement="topLeft"
                isInline={true}
                tooltipName="Provider's Terms"
                pagePosition="Footer"
                tooltipContentClassName={'footer-popover'}
                tooltipContent={disclaimers?.providerTermsDisclosureCopy}
              >
                <Label
                  type="caption"
                  color="secondary_text_light"
                  inline
                  datatestid={datatestid ? `${datatestid}-popover` : null}
                  className="otherLink padtpbt10"
                >
                  {`Provider's Terms  `}
                </Label>
              </CSTooltip>
              <CSTooltip
                placement="top"
                tooltipName="Editorial Disclosure"
                pagePosition="Footer"
                isInline={true}
                tooltipContentClassName={'footer-popover'}
                tooltipContent={disclaimers?.editorialDisclosure}
              >
                &nbsp;
                <Label type="caption" color="fontBlue100" inline>
                  -
                </Label>
                &nbsp;
                <Label
                  type="caption"
                  color="secondary_text_light"
                  inline
                  data-testid={datatestid ? `${datatestid}-popover` : null}
                  className="otherLink padtpbt10"
                >
                  Editorial disclosure
                </Label>
              </CSTooltip>
            </div>
          )}
        </div>
        <div
          data-testid={datatestid ? `${datatestid}-tu` : null}
          className="footer-tu"
        >
          <div className="footer-tu-text">
            <Label
              type="caption"
              color="fontGray300"
              datatestid={datatestid ? `${datatestid}-popover` : null}
            >
              Credit data provided by
            </Label>
          </div>
          <TuLogo />
        </div>
      </div>
    </>
  );
};
export default FooterView;
