import { signupConstants, stepOrder } from '../types/signup.constants';
import * as appConstants from '../../../types/app.constants';

const initialState = {
  loading: false,
  status: null,
  alertError: false,
  signUpStatus: null,
  step: null,
  stepCounter: null,
  questionRetrievalFailure: false,
  ssnRequire9: false,
  showCreditProfileError: false,
  ssnExists: false,
  ssn: null,
  ssnVerified: false,
  dupeSSNOTP: null,
  questions: null,
  questionsNotReceived: false,
  ssnRetriesCount: 0,
  userAgent: null,
  signUpOrder: stepOrder,
  invisibleUserData: null,
  dupKBAPhoneVerification: null,
  addressValidated: false,
  showAddressSuggestion: false,
  tuSessionId: null,
  showOTP: false,
  showKBA: false,
  selfLoanForTUErrors: [],
  expOneClickSignup: null,
  tryLoginWelcomeBack: false,
};
const logoutState = {
  loading: false,
  status: false,
  error: false,
  signUpStatus: null,
  stepCounter: null,
  step: null,
  items: false,
  ssnRequire9: false,
  questionsSubmitted: false,
  addressValidated: false,
};

const signupReducer = (state = initialState, action) => {
  switch (action.type) {
    //loading actions
    case signupConstants.CREATE_USER_REQUEST:
    case signupConstants.UPDATE_USER_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        alertError: false,
        errorPage: false,
      });
    case signupConstants.SUMMARY_UPDATE_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        alertError: false,
        ssnRetriesCount: state.ssnRetriesCount + 1,
      });

    case signupConstants.UPDATE_USER_SUCCESS:
      return Object.assign({}, state, { loading: false, error: false });
    case signupConstants.UPDATE_USER_ADDRESS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        error: false,
        showAddressSuggestion: false,
      });

    //failure and error actions
    case signupConstants.CREATE_USER_FAIL:
    case signupConstants.UPDATE_USER_FAIL:
      return Object.assign({}, state, { loading: false, error: action.error });

    case signupConstants.UPDATE_USER_ADDRESS_FAIL:
      return Object.assign({}, state, {
        loading: false,
        error: action.error,
        addressValidated: action.addressValidated,
        showAddressSuggestion: false,
      });
    case signupConstants.ADDRESS_FUZZY_MATCH:
      return Object.assign({}, state, {
        loading: false,
        error: action.error,
        addressValidated: true,
        showAddressSuggestion: true,
      });

    case signupConstants.UPDATE_USER_SSN_FAIL:
      return Object.assign({}, state, {
        loading: false,
        ssnRequire9: true,
        error: action.error.errorCode,
      });
    case signupConstants.VERIFY_USER_SSN_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });
    case signupConstants.VERIFY_USER_SSN_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        ssnVerified: true,
      });
    case signupConstants.VERIFY_USER_SSN_FAIL:
      return Object.assign({}, state, {
        loading: false,
        ssnVerified: false,
        error: action.error.errorCode,
      });
    case signupConstants.SSN_EXIST_USER:
      return Object.assign({}, state, {
        loading: false,
        ssnExists: true,
        ssn: action.ssnExistData,
        ssnData: action.ssnExistData,
      });
    case signupConstants.INVISIBLE_USER:
      return Object.assign({}, state, {
        loading: action.loading,
        invisibleUserData: action.invisibleUserData,
      });
    case signupConstants.DUP_KBA_PHONE_VERIFICATION:
      return Object.assign({}, state, {
        ...action.data,
      });
    case signupConstants.SSN_EXIST_OTPCODE_SUCCESS:
      return Object.assign({}, state, { loading: false, ...action.data });

    case signupConstants.GET_QUESTIONS_REQUEST:
      return Object.assign({}, state, { loading: true, ssnExists: false });
    case signupConstants.GET_QUESTIONS_SUCCESS:
      return Object.assign({}, state, action.questions, { loading: false });

    case signupConstants.GET_QUESTIONS_FAIL:
      if (state.questionRetrievalFailure) {
        return Object.assign({}, state, {
          loading: false,
          questionsNotReceived: true,
          error: action.error,
        });
      } else {
        return Object.assign({}, state, {
          loading: false,
          questionRetrievalFailure: true,
          ssnRequire9: true,
          error: action.error,
        });
      }

    case signupConstants.SUBMIT_QUESTIONS_SUCCESS:
      return Object.assign(
        {},
        state,
        {
          questionsSubmitted: true,
        },
        action.completeConfig,
      );
    case signupConstants.SUBMIT_QUESTIONS_FAIL:
      return Object.assign({}, state, {
        loading: false,
        submitFailure: true,
        error: action.error,
      });

    case signupConstants.FINALIZE_SIGNUP:
      return Object.assign({}, state, {
        loading: false,
      });
    //Tech debt: Two actions that set step: SET_SIGNUP_STEP, UPDATE_SIGNUP_STEP
    //updates loading
    case signupConstants.SET_SIGNUP_STEP:
      return Object.assign({}, state, {
        loading: false,
        stepCounter: state.signUpOrder.indexOf(action.pending),
        step: action.pending,
      });
    // does not update loading
    case signupConstants.UPDATE_SIGNUP_STEP:
      if (action.action === 'NEXT') {
        return Object.assign({}, state, {
          alertError: false,
          stepCounter: state.stepCounter + 1,
          step: state.signUpOrder[state.stepCounter + 1],
        });
      } else if (action.action === 'PREV') {
        return Object.assign({}, state, {
          alertError: false,
          stepCounter: state.stepCounter - 1,
          step: state.signUpOrder[state.stepCounter - 1],
        });
      } else if (action.action === 'GOTO') {
        const stepIndex = state.signUpOrder.indexOf(action.step);
        return Object.assign({}, state, {
          stepCounter: stepIndex,
          step: state.signUpOrder[stepIndex],
        });
      }
      break;
    case signupConstants.SIGNUP_CREATE_ALERT:
      return Object.assign({}, state, { alertError: action.message });
    case signupConstants.SIGNUP_CLEAR_ALERT:
      return Object.assign({}, state, { alertError: false });
    case signupConstants.SIGNUP_ERROR_LOGOUT:
      return Object.assign({}, state, logoutState, {
        loading: false,
        errorPage: action.errorPage,
        errorMessage: action.errorMessage,
      });
    case appConstants.LOGOUT:
      return Object.assign({}, state, logoutState, { email: state.email });
    case signupConstants.SIGNUP_LOGIN_FAILURE:
      return Object.assign({}, initialState, {
        error: action.error.responseCode,
      });
    case signupConstants.SIGNUP_TRY_LOGIN_ON_WELCOME_BACK:
      return Object.assign({}, state, {
        tryLoginWelcomeBack: action.tryLoginWelcomeBack,
      });
    case signupConstants.STORE_TU_SESSION_ID:
      return Object.assign({}, state, {
        tuSessionId: action.tuSessionId,
      });
    case signupConstants.CLEAR_ERROR_ALERTS:
      return Object.assign({}, state, {
        alertError: false,
        errorPage: false,
      });
    case signupConstants.SHOW_OTP:
      return Object.assign({}, state, {
        showOTP: action.showOTP,
      });
    case signupConstants.SHOW_KBA:
      return Object.assign({}, state, {
        showKBA: action.showKBA,
      });
    case signupConstants.UPDATE_SELF_LOAN_ERRORS:
      return Object.assign({}, state, {
        ...action.selfLoanData,
      });
    case signupConstants.SET_VARIATIONS:
      return Object.assign({}, state, {
        expOneClickSignup: action.expOneClickSignup,
      });
    case signupConstants.GET_USERAGENT_STRING:
      return Object.assign({}, initialState, { userAgent: action.ua });
    default:
      return state;
  }
};

export default signupReducer;
