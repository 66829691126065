import {
  buildSesameCashPinTokenEndpoint,
  buildSesameCashEnrollEndpoint,
  buildThirdPartyBankingApi,
  buildSesameCashEnrollLaterEndpoint,
  buildCashCardDetailsApi,
  buildCashPinApi,
  buildCashCardFreezeApi,
  buildCashCardUnfreezeApi,
  buildStackStory,
  buildStackStoryRecent,
  buildStackBankTransfers,
  buildAllCardDetailsApi,
  buildStackSecuredCardStatements,
  buildStackSecuredCardStatementDetail,
  buildUsersAddressVerificationEndpoint,
  buildStackBankTransfersV2,
} from 'api-urls';
import {
  buildFetchOptionsWithHeaders,
  buildBankingApiFetchOptionsWithHeaders,
  convertObjectToQueryString,
  standardizedFetchHandler,
} from './service_helpers';
import { SESAME_CASH_FAILURE } from 'types/app.constants';

export const fetchSesameCashPinToken = async password => {
  const body = JSON.stringify({ password: password });
  const fetchOpts = buildFetchOptionsWithHeaders({ method: 'POST', body });
  return new Promise((resolve, reject) => {
    standardizedFetchHandler(buildSesameCashPinTokenEndpoint(), fetchOpts)
      .then(response => {
        if (response.token) {
          resolve(response);
        } else if (response.enrollStatus === 'FAILED_KYC') {
          resolve({ cashPinToken: SESAME_CASH_FAILURE });
        } else {
          reject({ cashPinToken: false });
        }
      })
      .catch(error => {
        if (
          error.code === 'BK_1001' ||
          error.code === 'BK_1003' ||
          error.code === 'STK_100.16'
        ) {
          reject({ cashPinToken: false });
        } else {
          reject({ cashPinToken: SESAME_CASH_FAILURE });
        }
      });
  });
};

export const fetchThirdPartyBankingApi = async token => {
  const fetchOpts = buildBankingApiFetchOptionsWithHeaders(token);
  return standardizedFetchHandler(buildThirdPartyBankingApi(), fetchOpts);
};

export const sesameCashEnrollUser = async body => {
  const fetchOpts = buildFetchOptionsWithHeaders({
    method: 'POST',
    body,
  });
  return standardizedFetchHandler(buildSesameCashEnrollEndpoint(), fetchOpts);
};

export const enrollLater = async () => {
  const fetchOpts = buildFetchOptionsWithHeaders({ method: 'POST' });
  return standardizedFetchHandler(
    buildSesameCashEnrollLaterEndpoint(),
    fetchOpts,
  );
};

export const fetchThirdPartyCardDetails = async token => {
  const fetchOptions = buildBankingApiFetchOptionsWithHeaders(token, {
    method: 'POST',
  });

  return standardizedFetchHandler(buildCashCardDetailsApi(), fetchOptions);
};

export const fetchAllCardDetails = async token => {
  const fetchOptions = buildBankingApiFetchOptionsWithHeaders(token);

  return standardizedFetchHandler(
    buildAllCardDetailsApi() + '?version=2',
    fetchOptions,
  );
};

export const fetchPinService = async (token, method) => {
  const fetchOptions = buildBankingApiFetchOptionsWithHeaders(token);

  return standardizedFetchHandler(buildCashPinApi(), fetchOptions);
};

export const setCardPinService = async (token, cardPin) => {
  const body = { cardPin: cardPin };

  const fetchOptions = buildBankingApiFetchOptionsWithHeaders(token, {
    method: 'POST',
    body,
  });
  return standardizedFetchHandler(buildCashPinApi(), fetchOptions);
};
export const postUnfreezeCashCard = async token => {
  const fetchOptions = buildBankingApiFetchOptionsWithHeaders(token, {
    method: 'POST',
  });

  return standardizedFetchHandler(buildCashCardUnfreezeApi(), fetchOptions);
};
export const postFreezeCashCard = async token => {
  const fetchOptions = buildBankingApiFetchOptionsWithHeaders(token, {
    method: 'POST',
  });
  return standardizedFetchHandler(buildCashCardFreezeApi(), fetchOptions);
};

export const getStackStoryByDate = async (token, startDate) => {
  const fetchOptions = buildBankingApiFetchOptionsWithHeaders(token);

  const params = {
    startDate,
  };

  const paramString = convertObjectToQueryString(params);
  return standardizedFetchHandler(
    buildStackStory() + paramString,
    fetchOptions,
  );
};

export const getStackTransactionsRecent = async token => {
  const fetchOptions = buildBankingApiFetchOptionsWithHeaders(token);

  const params = {
    numTransactions: 15,
    numMonths: 3,
  };

  const paramString = convertObjectToQueryString(params);
  return standardizedFetchHandler(
    buildStackStoryRecent() + paramString,
    fetchOptions,
  );
};

export const getPendingBankTransfers = async token => {
  const fetchOptions = buildBankingApiFetchOptionsWithHeaders(token);

  return standardizedFetchHandler(buildStackBankTransfers(), fetchOptions);
};

export const getFinicityPendingBankTransfers = async token => {
  const fetchOptions = buildBankingApiFetchOptionsWithHeaders(token);

  return standardizedFetchHandler(buildStackBankTransfersV2(), fetchOptions);
};

export const getSecuredCardStatementList = async (token, getParams) => {
  const fetchOptions = buildBankingApiFetchOptionsWithHeaders(token);
  const query = convertObjectToQueryString(getParams);

  return standardizedFetchHandler(
    buildStackSecuredCardStatements() + query,
    fetchOptions,
  );
};

export const getSecuredCardStatementDetail = async (token, id) => {
  const fetchOptions = buildBankingApiFetchOptionsWithHeaders(token);

  return standardizedFetchHandler(
    buildStackSecuredCardStatementDetail(id),
    fetchOptions,
  );
};
export const getAddressVerification = async () => {
  const fetchOpts = buildFetchOptionsWithHeaders();
  return standardizedFetchHandler(
    buildUsersAddressVerificationEndpoint(),
    fetchOpts,
  );
};
