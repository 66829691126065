//pages use case 1
export const CHOOSE_METHOD = 'CHOOSE_METHOD';
export const EMAIL_SENT = 'EMAIL_SENT';
export const VERIFY_PHONE = 'VERIFY_PHONE';
export const PHONE_NOT_MATCH = 'PHONE_NOT_MATCH';
export const VERIFY_OTP = 'VERIFY_OTP';
export const VERIFY_SSN = 'VERIFY_SSN';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const WE_ARE_SORRY_EXPIRED = 'WE_ARE_SORRY_EXPIRED';
export const WE_ARE_SORRY_ATTEMPTS = 'WE_ARE_SORRY_ATTEMPTS';
export const LOADING = 'LOADING';

// pages use case 2
export const VERIFY_IDENTITY = 'VERIFY_IDENTITY';
export const EMAIL_HINT = 'EMAIL_HINT';

//pages use case 3.1
export const UPDATE_EMAIL_FORM = 'UPDATE_EMAIL_FORM';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const VERIFY_EMAIL_OTP = 'VERIFY_EMAIL_OTP';
export const EMAIL_UPDATED = 'EMAIL_UPDATED';

//pages use case 3.2
export const UPDATE_PHONE_FORM = 'UPDATE_PHONE_FORM';
export const UPDATE_PHONE = 'UPDATE_PHONE';
export const VERIFY_PHONE_OTP = 'VERIFY_PHONE_OTP';
export const PHONE_UPDATED = 'PHONE_UPDATED';

// pages use case  3.3
export const WE_ARE_SORRY_STEP = 'WE_ARE_SORRY_STEP';

//actions use case 1
export const SET_FORGOT_PASSWORD_STEP = 'SET_FORGOT_PASSWORD_STEP';
export const SUBMIT_EMAIL_SUCCESS = 'SUBMIT_EMAIL_SUCCESS';
export const SUBMIT_EMAIL_ERROR = 'SUBMIT_EMAIL_ERROR';
export const SUBMIT_PHONE_METHOD_SUCCESS = 'SUBMIT_PHONE_METHOD_SUCCESS';
export const VERIFY_PHONE_SUCCESS = 'VERIFY_PHONE_SUCCESS';
export const VERIFY_PHONE_ERROR = 'VERIFY_PHONE_ERROR';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_ERROR = 'VERIFY_OTP_ERROR';
export const SET_CODE_VALUE = 'SET_CODE_VALUE';
export const RESEND_CODE_SUCCESS = 'RESEND_CODE_SUCCESS';
export const RESEND_CODE_ERROR = 'RESEND_CODE_ERROR';
export const VERIFY_SSN_ERROR = 'VERIFY_SSN_ERROR';
export const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR';
export const CLEAR_FORGOT_PASSWORD_STATE = 'CLEAR_FORGOT_PASSWORD_STATE';
export const INIT_FORGOT_PASSWORD_EMAIL_LINK =
  'INIT_FORGOT_PASSWORD_EMAIL_LINK';

// actions use case 2
export const CLEAR_FORGOT_EMAIL_STATE = 'CLEAR_FORGOT_EMAIL_STATE';
export const SET_FORGOT_EMAIL_STEP = 'SET_FORGOT_EMAIL_STEP';

//actions use case 3.1
export const SET_NEED_ACCESS_EMAIL_STEP = 'SET_NEED_ACCESS_EMAIL_STEP';
export const SUBMIT_EMAIL_FORM_ERROR = 'SUBMIT_EMAIL_FORM_ERROR';
export const SUBMIT_EMAIL_FORM_NEED_PHONE = 'SUBMIT_EMAIL_FORM_NEED_PHONE';
export const SUBMIT_EMAIL_FORM_SAVE_EMAIL = 'SUBMIT_EMAIL_FORM_SAVE_EMAIL';
export const UPDATE_EMAIL_ERROR = 'UPDATE_EMAIL_ERROR';
export const CLEAR_NEED_ACCESS_EMAIL_STATE = 'CLEAR_NEED_ACCESS_EMAIL_STATE';

// action user case 3.2
export const SET_NEED_ACCESS_PHONE_STEP = 'SET_NEED_ACCESS_PHONE_STEP';
export const SUBMIT_PHONE_FORM_ERROR = 'SUBMIT_PHONE_FORM_ERROR';
export const SUBMIT_PHONE_FORM_SAVE_PHONE = 'SUBMIT_PHONE_FORM_SAVE_PHONE';
export const UPDATE_PHONE_ERROR = 'UPDATE_PHONE_ERROR';
export const CLEAR_NEED_ACCESS_PHONE_STATE = 'CLEAR_NEED_ACCESS_PHONE_STATE';
