import moment from 'moment';

function getUsage(debt, limit) {
  if (limit === 0) {
    return 0;
  } else {
    // CSWeb uses round for usage, and SLAPI may use ceil
    return Math.round((debt / limit) * 100);
  }
}

// Return list of formatted accounts for Credit Usage and Factor pages
function buildListData(accounts) {
  const listData = accounts
    .filter(account => account.status === 'Open' && account.creditLimit > 0)
    .map(account => {
      const usagePercent = getUsage(account.balance, account.creditLimit);
      return {
        usagePercent: usagePercent,
        name: account.name,
        amount: account.balance,
        limit: account.creditLimit,
        idHash: account.idHash,
      };
    });

  return listData;
}

function getScoreGuagePercent(score) {
  return score !== 0 ? ((score - 299) / 550) * 100 : 0;
}

function getRatingFromScore(score, isSmallString) {
  switch (true) {
    case score < 300:
      return isSmallString ? 'LIMITED HISTORY' : 'LIMITED CREDIT HISTORY';
    case score < 500:
      return 'VERY POOR';
    case score < 550:
      return 'POOR';
    case score < 640:
      return 'FAIR';
    case score < 720:
      return 'GOOD';
    default:
      return 'EXCELLENT';
  }
}

const getRatingRanges = rating => {
  switch (rating) {
    case 'VERY POOR':
      return {
        min: 300,
        max: 499,
      };
    case 'POOR':
      return {
        min: 500,
        max: 549,
      };
    case 'FAIR':
      return {
        min: 550,
        max: 639,
      };
    case 'GOOD':
      return {
        min: 640,
        max: 719,
      };
    case 'EXCELLENT':
      return {
        min: 720,
        max: 850,
      };
    default:
      return {
        min: 0,
        max: 0,
      };
  }
};

function getRatingFontColor(rating) {
  switch (rating) {
    case 'EXCELLENT':
      return 'fontGreen400';
    case 'GOOD':
      return 'fontLgreen400';
    case 'FAIR':
      return 'fontYellow400';
    case 'POOR':
      return 'fontOrange400';
    case 'VERY POOR':
      return 'fontRed400';
    default:
      return 'fontGray300';
  }
}

const calcMonthlyMortgagePayment = (
  homePrice,
  downPayment,
  interestRate,
  terms,
) => {
  const finalPrice = homePrice - downPayment;
  const interestRatePerMonth = interestRate / 1200;
  const numPaymentPeriods = 12 * terms.substring(0, 2);

  let pvif = Math.pow(1 + interestRatePerMonth, numPaymentPeriods);
  let payment = (interestRatePerMonth * finalPrice * pvif) / (pvif - 1);

  return parseInt(payment);
};

function getChartColor(score, sesamePotential) {
  switch (true) {
    case sesamePotential:
      return 'blue050';
    case score < 300:
      return 'gray100';
    case score < 500:
      return 'red100';
    case score < 550:
      return 'orange100';
    case score < 640:
      return 'yellow100';
    case score < 720:
      return 'lgreen100';
    default:
      return 'green100';
  }
}

const maxCountMonths = 6;

const getCreditScoreCalculation = creditInfo => {
  const { trends } = creditInfo;
  const { monthlyScoreTrend } = trends;
  const actualSize = monthlyScoreTrend?.length || 0;
  const chartData = monthlyScoreTrend
    ?.slice(actualSize - maxCountMonths)
    ?.map(({ date, score }) => {
      return {
        year: moment(date, 'YYYY-MM-DD').format('YYYY'),
        month: moment(date, 'YYYY-MM-DD').format('MMM'),
        day: moment(date, 'YYYY-MM-DD').format('DD'),
        score,
      };
    });
  // score difference of last two month is the score change
  const scoreChange =
    actualSize >= 2
      ? chartData[actualSize - 1]?.score - chartData[actualSize - 2]?.score
      : 0;

  return {
    scoreChange,
    chartData,
  };
};

const getChartCalculation = dataArray => {
  const groupBy = (arr, callback) =>
    arr.reduce((acc = {}, ...args) => {
      const key = callback(...args);
      acc[key] ??= [];
      acc[key].push(args[0]);
      return acc;
    }, {});

  const dataByMonth = groupBy(dataArray, ({ date }) => moment(date, 'YYYY-MM'));
  const monthlyData = Object.values(dataByMonth).map(item => item?.pop());

  let scoreTotal = 0;
  const actualSize = monthlyData?.length || 0;
  const chartData = monthlyData
    ?.slice(actualSize - maxCountMonths)
    ?.map(({ date, value: score }) => {
      scoreTotal += score;
      return {
        year: moment(date, 'YYYY-MM-DD').format('YYYY'),
        month: moment(date, 'YYYY-MM-DD').format('MMM'),
        day: moment(date, 'YYYY-MM-DD').format('DD'),
        score,
      };
    });
  // score difference of last two month is the score change
  const scoreChange =
    actualSize >= 2
      ? chartData[actualSize - 1]?.score - chartData[actualSize - 2]?.score
      : 0;

  return {
    scoreChange,
    chartData,
    scoreTotal,
  };
};

export {
  getRatingRanges,
  getUsage,
  getScoreGuagePercent,
  getRatingFromScore,
  getRatingFontColor,
  getChartColor,
  calcMonthlyMortgagePayment,
  buildListData,
  getCreditScoreCalculation,
  getChartCalculation,
};
