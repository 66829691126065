import { captureDashboardPerfMetrics } from 'actions/login_flows/login_perf.actions';
import {
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  INVALID_ROUTE_REDIRECT,
  LOGOUT,
  OCF_LOGIN_REQUEST,
  OCF_LOGIN_SUCCESS,
  PUBLIC_LOGIN_REQUEST,
  PUBLIC_LOGIN_SUCCESS,
} from 'types/app.constants';
import {
  postSession,
  postOcfSession,
  postLogout,
} from 'externals/_services/session.service';
import {
  OCF_LOGIN_REDIRECT,
  removeItem,
  setUserLogoutTime,
} from 'helpers/localstorage';
import {
  setPostLoginPath,
  getPostLoginPath,
  clearPostLoginPath,
} from 'helpers/sessionstorage';
import { getOpenHomeLoans } from 'helpers/credit_info';
import { LOGIN } from 'externals/_tracking/types/eventTypes.constants';
import { pushOptAttribute } from 'externals/_tracking/optimizely';
import { getGetParam, goToUrl } from 'helpers/domHelper';
import { OCF_ROLE } from 'helpers/session';
import { requireTwoFactorAuthentication } from 'actions/twofactor.actions';
import { createLogger } from 'helpers/logger';
import {
  clearOcfLogin,
  clearUserLogin,
  getSessionRole,
  updateApiKeySessionTimeoutInLocal,
  setPublicSessionIdentifier,
  USER_ROLE,
} from 'helpers/session';
import { areCookiesEnabled } from 'externals/_tracking/dom/navigator';
import { buildStandardException } from 'helpers/error.helper';
import { wrapService } from 'actions/service_wrapper.actions';
import { loadAllAPIData } from 'actions/login_flows/login_loader.actions';
import { runSessionResponseDependencies } from 'actions/login_flows/platform_dependencies.actions';
import { getCookieValue } from 'helpers/cookie.helper';
import { getPlatformQueryParam } from 'helpers/login.helpers';
import {
  isInvisibleUserByStatus,
  isSignupStatusFullyRegistered,
} from 'helpers/userHelper';
import { optimizelyClient } from 'externals/_tracking/optimizely/optFlags';
import { CS_ENROLLED, SSN_VERIFIED } from 'types/signupStatus.constants';
import { OVERVIEW_URL } from 'types/mrph.routes.constants';
import { getDecisionForFlag } from 'externals/_tracking/optimizely/optFlags';

const a_postSession = wrapService(postSession, {
  name: 'postSession',
});
const a_postOcfSession = wrapService(postOcfSession, {
  name: 'postOcfSession',
});
const a_postLogout = wrapService(postLogout, {
  name: 'postLogout',
});

const logger = createLogger({
  name: 'login.actions',
});

export function foundUserOnInvalidRoute(
  location,
  history,
  isOcfRole,
  ocfEmail,
) {
  if (!isOcfRole) {
    const platformParam = getPlatformQueryParam(location.search);
    history.push(`/login${platformParam}`);
  } else {
    const emailParam = ocfEmail ? `?email=${encodeURIComponent(ocfEmail)}` : '';
    history.replace(`/login${emailParam}`);
  }
  const route = location.pathname + location.search;

  setPostLoginPath(route);

  return {
    type: INVALID_ROUTE_REDIRECT,
  };
}

export function pushUserProfileOptAttributes(creditInfo) {
  const openHomeLoans = getOpenHomeLoans(creditInfo);
  pushOptAttribute({ hasOpenHomeLoans: openHomeLoans.length > 0 });
}

// ##############################################
// Redux actions
// ##############################################
function startLoginRequest(user) {
  return { type: LOGIN_REQUEST, user, loggingInLevel: SECURE_LEVEL };
}

function startOcfLoginRequest() {
  return { type: OCF_LOGIN_REQUEST, loggingInLevel: OCF_LEVEL };
}

function startPublicLoginRequest() {
  return { type: PUBLIC_LOGIN_REQUEST, loggingInLevel: PUBLIC_LEVEL };
}

export function loginSuccess(loginResponse, userInfo, sessionIdentifier) {
  return {
    type: LOGIN_SUCCESS,
    loginResponse,
    userInfo,
    sessionIdentifier,
    user: loginResponse ? loginResponse.userId : null,
  };
}

function loginFailure() {
  return { type: LOGIN_FAILURE };
}

export const finalizeLogin = (loginResponse, userInfo, sessionIdentifier) =>
  loginSuccess(loginResponse, userInfo, sessionIdentifier);

const SECURE_LEVEL = 3;
const OCF_LEVEL = 2;
const PUBLIC_LEVEL = 1;

// ##############################################
// -------------------------------->Redux actions
// ##############################################
const preventRaceConditions = (loginDataCollector, level, startLoginAction) => {
  return new Promise((resolve, reject) => {
    let total = 0;
    const initState = loginDataCollector.getState();
    const loggingInLevelInitially = initState.app.loggingInLevel;

    const check = () => {
      const newState = loginDataCollector.getState();
      const loggingInLevel = newState.app.loggingInLevel;
      if (loggingInLevel !== null) {
        setTimeout(check, 50);
        total += 50;
      } else if (total > 200000) {
        reject({
          message: 'Max time out reached for attempt',
          code: 'AUTH_01',
        });
      } else {
        if (!loginDataCollector.config.dupeSsnLogin) {
          loginDataCollector.dispatch(startLoginAction);
        }
        resolve(loginDataCollector);
      }
    };

    if (loggingInLevelInitially >= level) {
      reject({
        code: 'LOGIN_55',
        message: 'Login already happening',
      });
    } else {
      setTimeout(check, 50);
    }
  });
};

/**
 * Step #1 - pre-req for slapi calls
 * @param loginDataCollector
 * @param loginLevel
 * @returns {Promise<unknown>}
 */
const preAuthenticationSteps = (
  loginDataCollector,
  loginLevel,
  startLoginAction,
) => {
  return new Promise((resolve, reject) => {
    preventRaceConditions(loginDataCollector, loginLevel, startLoginAction);
    if (!loginDataCollector.isPublicLogin) {
      loginDataCollector.csApiSessionId = getCookieValue('cs-api-sessid');
      loginDataCollector.refCode = getGetParam('ref');
    }

    resolve(loginDataCollector);
  });
};

/**
 * Step #2 - Perform SLAPI call for authentication
 *
 * @param loginDataCollector
 * @returns {Promise<unknown>}
 */
const callServiceForAuthentication = (loginDataCollector, isPreScreen) => {
  return new Promise((resolve, reject) => {
    const { email, password, rememberMe } = loginDataCollector.arguments;
    const { csApiSessionId } = loginDataCollector;
    const { jwt2FAToken, newEmail } = loginDataCollector.getState().changeEmail;
    const finalJwt2FAToken = email === newEmail ? jwt2FAToken : null;

    loginDataCollector
      .dispatch(
        a_postSession(
          email,
          password,
          null,
          csApiSessionId,
          finalJwt2FAToken,
          isPreScreen,
          rememberMe,
        ),
      )
      .then(loginResponse => {
        // this is a hack to redirect to transunion page based on slapi response
        // 307 , 302 https code response was tried , issue is that login is a POST endpoint getting executed with FETCH(ajax)
        // browser does not allow us to read the response when a redirection status code is returned by the BE
        // this can be avoided by either doing a document type request, html form , but that has limitations for adding headers
        //just ajax can modifiy headers , also it cant be a GET endpoint for me to use window.location.href = loginResponse?.redirectUrl hack
        if (loginResponse?.redirectUrl) {
          goToUrl(loginResponse.redirectUrl);
          return;
        }
        loginDataCollector.loginResponse = loginResponse;
        resolve(loginResponse);
      })
      .catch(err => {
        // A fail through will go to the last step
        reject(err);
      });
  });
};

const callServiceForOcfAuthentication = loginDataCollector => {
  return new Promise((resolve, reject) => {
    const { userId, ocfName, ocfRef, signature, prescreen } =
      loginDataCollector?.arguments;
    const { csApiSessionId } = loginDataCollector;
    loginDataCollector
      .dispatch(
        a_postOcfSession(
          userId,
          ocfName,
          ocfRef,
          signature,
          prescreen,
          csApiSessionId,
        ),
      )
      .then(loginResponse => {
        loginDataCollector.loginResponse = loginResponse;
        resolve(loginResponse);
      })
      .catch(err => {
        // A fail through will go to the last step
        reject(err);
      });
  });
};

const callServiceForPublicAuthentication = loginDataCollector => {
  return new Promise((resolve, reject) => {
    loginDataCollector
      .dispatch(a_postSession(null, null))
      .then(loginResponse => {
        loginDataCollector.loginResponse = loginResponse;
        resolve(loginResponse);
      })
      .catch(err => {
        // A fail through will go to the last step
        reject(err);
      });
  });
};

/**
 * Step #3 - Check for 2FA Verification
 */
export const checkFor2FAVerification = loginDataCollector => {
  return new Promise((resolve, reject) => {
    if (loginDataCollector.loginResponse.role === 'ROLE_USER_LIMITED') {
      loginDataCollector
        .dispatch(
          requireTwoFactorAuthentication(
            loginDataCollector.config.history,
            loginDataCollector.config.location,
            {
              requireEnrollment: false,
              requirePromote: true,
              accountInfo: loginDataCollector.loginResponse.accountInfo,
              sessionIdentifier:
                loginDataCollector.loginResponse.sessionIdentifier,
            },
          ),
        )
        .then(resolve)
        .catch(reject);
    } else {
      resolve();
    }
  });
};

/**
 * Step #3 - Fetch User Profile and do platform specific steps concurrently
 *
 * @param loginDataCollector
 * @returns {Promise<unknown>}
 */
export const loadUserProfile = loginDataCollector => {
  return new Promise((resolve, reject) => {
    if (
      !loginDataCollector.config.dupeSsnLogin &&
      !loginDataCollector.config.isPasswordRecovery
    ) {
      loginDataCollector.config.history.push(
        '/login/post' + loginDataCollector.config.location.search,
      );
    }

    loadAllAPIData(loginDataCollector)
      .then(data => {
        const signUpStatus = loginDataCollector?.loginResponse?.userStatus;
        const isUserInSignup = !isSignupStatusFullyRegistered(signUpStatus);

        const unexpectedError = false;
        if (isUserInSignup) {
          loginDataCollector.finalAction = {
            type: LOGIN_SUCCESS,
          };
          resolve(loginDataCollector);
        } else if (unexpectedError) {
          logger.errorException(
            'Unexpected error while loading required metadata',
            unexpectedError,
          );
          setUserLogoutTime(null);
          reject(unexpectedError);
        } else {
          resolve(loginDataCollector);
        }
      })
      .catch(err => {
        logger.error('Loading dependencies failed');
        reject(err);
      });
  });
};

const handleOcfPlatformSpecificSteps = loginDataCollector => {
  return new Promise((resolve, reject) => {
    runSessionResponseDependencies(loginDataCollector, true)
      .then(resolve)
      .catch(reject);
  });
};

/**
 * Step #5 - Successful PostLogin Steps
 *
 * @param loginDataCollector
 * @returns {Promise<unknown>}
 */
export const handlePostLoginSteps = loginDataCollector => {
  return new Promise((resolve, reject) => {
    try {
      const { loginResponse, config, dispatch } = loginDataCollector;
      const { isOcfLogin } = loginDataCollector.arguments;
      const { eventHandler, redirectUrl } = loginDataCollector.config;
      const sessionIdentifier = loginResponse.sessionIdentifier;
      const { history } = config;
      const signUpStatus = loginResponse?.userStatus;

      let loginAttributes = {
        'Session Identifier': sessionIdentifier,
        'Logged In': 'Yes',
        'Total Time': loginDataCollector.totalTime,
        'Profile Refresh': loginResponse.isProfileRefresh,
      };

      const invisUserTest = optimizelyClient.decide('invisible_user');

      if (
        (signUpStatus === SSN_VERIFIED || signUpStatus === CS_ENROLLED) &&
        invisUserTest.variables.send_to_landing_on_login
      ) {
        dispatch(loginDataCollector.finalAction);
        if (!isOcfLogin) {
          eventHandler(LOGIN, loginAttributes);
        }
        history.push('/signup2?invis=true');
      } else if (
        !isSignupStatusFullyRegistered(signUpStatus) &&
        !isInvisibleUserByStatus(signUpStatus)
      ) {
        dispatch(loginDataCollector.finalAction);
        if (!isOcfLogin) {
          eventHandler(LOGIN, loginAttributes);
        }
        history.push('/signup2');
      } else {
        // Forward to dashboard
        dispatch(loginDataCollector.finalAction);
        const storedPath = getPostLoginPath();

        let loginAttr = {
          'Session Identifier': sessionIdentifier,
          'Logged In': 'Yes',
          'Total Time': loginDataCollector.totalTime,
          'Profile Refresh': loginResponse.isProfileRefresh,
        };

        if (loginDataCollector.ocfLoginAttr) {
          loginAttr = {
            ...loginAttr,
            'OCF Logged In': loginDataCollector.ocfLoginAttr,
          };
          removeItem(OCF_LOGIN_REDIRECT);
        }

        if (storedPath) {
          if (!isOcfLogin) {
            eventHandler(LOGIN, {
              ...loginAttr,
              'Redirect URL': storedPath,
            });
          }
          history.push(storedPath);
        } else if (redirectUrl) {
          if (!isOcfLogin) {
            eventHandler(LOGIN, {
              ...loginAttr,
              'Redirect URL': redirectUrl,
            });
          }
          history.push(redirectUrl);
        } else {
          dispatch(captureDashboardPerfMetrics(loginDataCollector, loginAttr));
          history.push(OVERVIEW_URL);
        }

        if (getSessionRole() !== USER_ROLE) {
          logger.error(
            'Immediately after login, the user is already logged out.....',
          );
        }
        setTimeout(() => {
          if (getSessionRole() !== USER_ROLE) {
            logger.error(
              '15 seconds after login, the user is already logged out.....',
            );
          }
        }, 15000);

        clearPostLoginPath();
      }
      resolve(loginDataCollector);
    } catch (err) {
      reject(err);
    }
  });
};

const handleOcfPostLoginSteps = loginDataCollector => {
  return new Promise((resolve, reject) => {
    try {
      const { dispatch } = loginDataCollector;

      dispatch(loginDataCollector.finalAction);
      clearPostLoginPath();
      resolve(loginDataCollector);
    } catch (err) {
      reject(err);
    }
  });
};

const handlePostPublicLoginSteps = loginDataCollector => {
  return new Promise(resolve => {
    updateApiKeySessionTimeoutInLocal();
    clearUserLogin();
    clearOcfLogin();
    setPublicSessionIdentifier(loginDataCollector);
    resolve();
    loginDataCollector.dispatch(publicFinalizeLogin());
  });
};

/**
 *
 * Login function that performs all steps needed to login
 *
 * @param email
 * @param password
 * @param rememberMe
 * @param keyPassword
 * @param config
 * @returns {function(*=, *): Promise<unknown>}
 */
export const login =
  (email, password, rememberMe, keyPassword, config) =>
  (dispatch, getState) => {
    const finalConfig = config ? config : {};

    if (!(finalConfig.history && finalConfig.eventHandler)) {
      throw new Error('login requires an eventHandler and history reference');
    }

    const capitalOnePreScreenFlag = getDecisionForFlag(
      'capital_one_prescreen',
      {
        defaultConfig: {
          prescreen: false,
        },
      },
    );
    const isPreScreen = capitalOnePreScreenFlag?.config?.prescreen;

    const oldPageMeta = getState().page?.oldPageNameMeta;

    return new Promise((resolve, reject) => {
      if (!areCookiesEnabled()) {
        reject(
          buildStandardException({
            code: 'NO_COOKIE',
          }),
        );
        return;
      }

      const loginDataCollector = {
        arguments: {
          email,
          password,
          rememberMe,
          keyPassword,
          config: finalConfig,
        },
        dispatch,
        getState,
        config: finalConfig,
        finalAction: {
          type: LOGIN_SUCCESS,
        },
        startTime: performance && performance.now && performance.now(),
        ocfLoginAttr: oldPageMeta ?? '',
      };

      preAuthenticationSteps(
        loginDataCollector,
        SECURE_LEVEL,
        startLoginRequest({ email }),
      )
        .then(_ =>
          callServiceForAuthentication(loginDataCollector, isPreScreen),
        )
        .then(_ => checkFor2FAVerification(loginDataCollector))
        .then(_ => loadUserProfile(loginDataCollector))
        .then(_ => handlePostLoginSteps(loginDataCollector))
        .then(_ => {
          resolve(loginDataCollector);
        })
        .catch(err => {
          logger.debugException('Unable to complete login', err);
          if (!finalConfig.dupeSsnLogin) {
            dispatch(loginFailure());
          }
          reject(err);
        });
    });
  };

export const logout = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    try {
      setUserLogoutTime(null);
      dispatch({ type: LOGOUT });

      dispatch(a_postLogout())
        .then(res => {
          removeItem('isTimeToRecertifyIncomeUpdated');
          resolve(res);
        })
        .catch(err => reject(err));
    } catch (e) {
      resolve(e);
    }
  });
};

export const ocfLogin =
  (userId, ocfName, ocfRef, signature, config, prescreen) =>
  (dispatch, getState) => {
    const finalConfig = config ? config : {};

    if (!(finalConfig.history && finalConfig.eventHandler)) {
      throw new Error('login requires an eventHandler and history reference');
    }

    return new Promise((resolve, reject) => {
      const loginDataCollector = {
        arguments: {
          isOcfLogin: true,
          userId,
          ocfName,
          ocfRef,
          signature,
          config: finalConfig,
          prescreen,
        },
        dispatch,
        getState,
        config: finalConfig,
        finalAction: {
          type: OCF_LOGIN_SUCCESS,
          loginResponse: null,
          sessionIdentifier: null,
          user: null,
        },
      };
      preAuthenticationSteps(
        loginDataCollector,
        OCF_LEVEL,
        startOcfLoginRequest(),
      )
        .then(_ => callServiceForOcfAuthentication(loginDataCollector, userId))
        .then(_ => handleOcfPlatformSpecificSteps(loginDataCollector))
        .then(_ => handleOcfPostLoginSteps(loginDataCollector))
        .then(_ => {
          resolve(loginDataCollector);
        })
        .catch(err => {
          dispatch(loginFailure());
          reject(err);
        });
    });
  };

const publicFinalizeLogin = () => ({ type: PUBLIC_LOGIN_SUCCESS });

export const publicLogin =
  (config, skipLogginVerificationStep = false) =>
  (dispatch, getState) => {
    const finalConfig = config ? config : {};

    if (!skipLogginVerificationStep) {
      const state = getState();
      const loggedIn = state.app.loggedIn;
      const loggingIn = state.app.loggingIn;
      const localStorageRole = getSessionRole();
      const isLoggedIn =
        loggedIn === true ||
        loggingIn === true ||
        (localStorageRole !== null && localStorageRole !== OCF_ROLE);
      if (isLoggedIn) {
        return new Promise(resolve => resolve());
      }
    }

    return new Promise((resolve, reject) => {
      const loginDataCollector = {
        isPublicLogin: true,
        arguments: {
          config: finalConfig,
        },
        dispatch,
        getState,
        config: finalConfig,
      };
      preAuthenticationSteps(
        loginDataCollector,
        PUBLIC_LEVEL,
        startPublicLoginRequest(),
      )
        .then(_ => callServiceForPublicAuthentication(loginDataCollector))
        .then(_ => handlePostPublicLoginSteps(loginDataCollector))
        .then(_ => {
          resolve(loginDataCollector);
        })
        .catch(err => {
          dispatch(loginFailure());
          reject(err);
        });
    });
  };
