import { completeAutoLoanFormForOffers } from 'actions/product.actions';
import { CLICK } from 'externals/_tracking/types/eventTypes.constants';
import { formatAsFull } from 'helpers/dateHelper';
import { AUTO_LOAN_VERTICAL } from 'types/marketplaceTiles.constants';

export const getRefinanceAutoLoanConfig = (
  userAutoLoans,
  creditInfo,
  userInfo,
  setCurrentLoanDetails,
) => {
  const currentAutoLoanAccounts = userAutoLoans.filter(loan => {
    return loan.status === 'Open' && loan.balance > 0;
  });

  const hasUserAutoLoans =
    currentAutoLoanAccounts && currentAutoLoanAccounts.length > 0;

  let autoLoanFormConfig = null;
  let config = null;

  if (hasUserAutoLoans) {
    const refinanceAccount = currentAutoLoanAccounts[0];
    refinanceAccount.term = Math.round(
      refinanceAccount.balance / refinanceAccount.monthlyPayment,
    );
    if (setCurrentLoanDetails) {
      setCurrentLoanDetails(refinanceAccount);
    }
    config = {};
    config.autoLoanPurpose = 'REFINANCE';
    if (userInfo.primaryAddress) {
      config.state = userInfo.primaryAddress.state.toLowerCase();
    }
    config.income = userInfo.annualIncome;
    config.creditScore = creditInfo.creditScore;
    config.loanAmount = refinanceAccount.balance;
    config.rate = refinanceAccount.apr;
    config.monthlyPayment = refinanceAccount.monthlyPayment;
    config.itemsPerPage = 1000;

    autoLoanFormConfig = {
      queryObject: config,
      currentLoanDetails: refinanceAccount,
      refinanceAccount: true,
      refinanceReason: true,
      zeroOffersState: false,
      autoLoanType: 'REFINACE',
      currentAutoLoanAmount: config.loanAmount,
    };
  }
  return { refinanceAccountConfig: config, autoLoanFormConfig };
};

export const getPurchaseAutoLoanConfig = (
  loanAmount,
  creditInfo,
  userInfo,
  dispatch,
  currentSortType,
) => {
  const config = {
    autoLoanPurpose: 'PURCHASE_NEW',
    income: userInfo.annualIncome,
    creditScore: creditInfo.creditScore,
    ...(userInfo.primaryAddress && {
      state: userInfo.primaryAddress.state.toLowerCase(),
    }),
    loanAmount: loanAmount,
    itemsPerPage: 1000,
  };

  dispatch(
    completeAutoLoanFormForOffers({
      queryObject: config,
      refinanceAccount: false,
      zeroOffersState: false,
      autoLoanType: 'PURCHASE_NEW',
      newAutoLoanAmount: loanAmount,
      currentSortType,
    }),
  );

  return config;
};

export const goToAutoLoanRefinanceMarketplace = (
  currentAutoLoan,
  userInfo,
  creditInfo,
  eventHandler,
  dispatch,
  history,
) => {
  eventHandler(CLICK, {
    'Click Type': 'See more options',
    Vertical: AUTO_LOAN_VERTICAL,
  });
  let queryObject = {
    autoLoanPurpose: null,
    state: null,
    creditScore: null,
    income: null,
    loanAmount: null,
    itemsPerPage: 1000,
  };
  if (userInfo && creditInfo) {
    if (userInfo.primaryAddress) {
      queryObject.state = userInfo.primaryAddress.state.toLowerCase();
    }
    queryObject.income = userInfo.annualIncome;
    queryObject.creditScore = creditInfo.creditScore;
    currentAutoLoan.lastRefreshDate = formatAsFull(creditInfo.lastRefreshDate);
  }

  currentAutoLoan.term = Math.round(
    currentAutoLoan.balance / currentAutoLoan.monthlyPayment,
  );
  const currentLoanDetails = currentAutoLoan;
  queryObject.loanAmount = currentAutoLoan.balance;
  queryObject.rate = currentAutoLoan.apr;
  queryObject.monthlyPayment = currentAutoLoan.monthlyPayment;
  queryObject.autoLoanPurpose = 'REFINANCE';

  dispatch(
    completeAutoLoanFormForOffers({
      queryObject,
      currentLoanDetails,
      refinanceAccount: currentAutoLoan,
      refinanceReason: 'lowestMonthlyPayment',
      zeroOffersState: false,
      autoLoanType: 'refinanceLoan',
      currentAutoLoanAmount: currentAutoLoan.balance,
    }),
  );

  history.push('/offers/autoLoan');
};

export const getSavingsAmounts = (currentLoanDetails, offerMetadata) => {
  let savingsAmount =
    currentLoanDetails?.monthlyPayment - offerMetadata['monthlyPayment'];
  if (!Number.isInteger(savingsAmount)) {
    savingsAmount = savingsAmount.toFixed(2);
  }
  return savingsAmount;
};

export const getCurrentLoanDetails = creditInfo => {
  let currentLoanDetails = null;
  const currentAutoLoanAccounts =
    creditInfo?.debtAnalysis?.autoLoanDebt?.accounts.filter(loan => {
      return loan.status === 'Open' && loan.balance > 0;
    });
  currentLoanDetails = currentAutoLoanAccounts[0];
  return currentLoanDetails;
};

export const getAutoRefinance = (autoRefinance, creditInfo) => {
  let autoRefinanceOffer = null;
  let savingsAmount = 0;
  let currentLoanDetails = null;

  if (autoRefinance?.items?.length > 0) {
    const autoLoanOffers = autoRefinance?.items
      .filter(item => item?.apr && item?.monthlyPayment)
      .sort((a, b) => {
        return a?.monthlyPayment - b?.monthlyPayment;
      })
      .slice(0, 1);

    if (autoLoanOffers?.length > 0) {
      autoRefinanceOffer = autoLoanOffers[0];
      const currentAutoLoanAccounts =
        creditInfo?.debtAnalysis?.autoLoanDebt?.accounts.filter(loan => {
          return loan?.status === 'Open' && loan?.balance > 0;
        });
      if (currentAutoLoanAccounts && currentAutoLoanAccounts?.length > 0) {
        currentLoanDetails = currentAutoLoanAccounts[0];
        currentLoanDetails.term = Math.round(
          currentLoanDetails?.balance / currentLoanDetails?.monthlyPayment,
        );
        savingsAmount =
          currentLoanDetails?.monthlyPayment -
          autoRefinanceOffer['monthlyPayment'];
        if (!Number.isInteger(savingsAmount)) {
          savingsAmount = savingsAmount.toFixed(2);
        }
      }
    }
  }
  return {
    savingsAmount,
    currentLoanDetails,
  };
};
