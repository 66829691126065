import { useComplexState } from 'hook/helper.hook';
import {
  TABS,
  TAB_CALENDAR,
  TAB_GOALS,
  SHOW_CALENDAR_WELCOME_MODAL,
  GRADE_FACTOR_MAP,
  SHOW_CONFFETI,
  SHOW_RENT_REPORTING_CARD,
  THREE_BUREAUS,
  FIRST_VISIT_DONE,
} from '../helpers/CreditManager.constants';
import {
  hasFreeTrialBeenUsedBefore,
  isFreeTrial,
  isFreemium,
  isPlus3B,
} from 'helpers/userHelper';
import { useEffect } from 'react';
import { getItem, setItem } from 'helpers/localstorage';
import { getContainerData } from 'actions/offers.actions';
import { useLogger } from 'hook/error.hook';
import {
  formatCreditManagerResponse,
  getAccountDetail,
} from '../helpers/CreditManager.helper';
import {
  CLICK,
  VIEW_PAGE,
  VIEW_POPUP,
} from 'externals/_tracking/types/eventTypes.constants';
import { CREDIT_MANAGER, GOALS } from 'types/mrph.pages.constants';
import { setPageMeta } from 'actions/tracking.actions';
import { isEnrolledInCash } from 'helpers/sesameCash.helpers';
import {
  getGeneralStorage,
  setGeneralStorage,
} from 'actions/generalStorage.actions';
import { useSubscriptionLandingUrl } from 'hook/subscriptionLandingUrl.hook';

export const useCreditManagerVisited = props => {
  const { userInfo, firstLoginOfTheMonth } = props;
  const [state, setState] = useComplexState({
    visited: true,
  });
  const checkAsVisited = () => {
    if (!state.visited) {
      setState({ visited: true });
    }
  };
  useEffect(() => {
    if (userInfo) {
      setState({ visited: !firstLoginOfTheMonth });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);
  return { ...state, checkAsVisited };
};

const useCreditManager = props => {
  const logger = useLogger({
    name: 'useCreditManager.hook',
  });
  const {
    eventHandler,
    userInfo,
    history,
    dispatch,
    tabKey,
    creditManagerContainer,
  } = props;

  const { subscriptionLandingUrl } = useSubscriptionLandingUrl();

  const [state, setState] = useComplexState({
    defaultTab: TAB_CALENDAR,
    tabSelected: TAB_CALENDAR,
    showToolTipGoals: false,
    moduleCards: null,
    tradelineMostRecent: null,
    mostRecentPastEvent: null,
    mostRecentUpcommingEvent: null,
    showWelcomeModal: false,
    showConffeti: false,
    accountDetail: null,
    generalStorageCalled: false,
    firstVisitDone: null,
  });

  const isFreeUser = isFreemium(userInfo);
  const isPlus3BUser = isPlus3B(userInfo);
  const isFreeTrialUser = isFreeTrial(userInfo);
  const isEnrolledInCashUser = isEnrolledInCash(userInfo);
  const notEligibleForFreeTrial = hasFreeTrialBeenUsedBefore(userInfo);

  const onTabClick = tabKey => {
    if (tabKey) {
      history?.push(`/creditManager/${tabKey}`);
    }
  };

  const redirectToPlusLandingPage = clickType => {
    eventHandler?.(CLICK, {
      'Click Type': clickType,
    });
    history?.push(subscriptionLandingUrl);
  };

  const viewMyCreditGoals = () => {
    eventHandler?.(CLICK, {
      'Click Type': 'View My Goals',
    });
    history?.push(`/creditManager/goals`);
  };

  const openAccountDetail = ({ card, modulePosition, tracking, prefix }) => {
    eventHandler?.(CLICK, {
      'Click Type': 'View Account Details',
      ...tracking,
    });

    setState({
      accountDetail: {
        ...getAccountDetail({ card, modulePosition, prefix }),
        userInfo,
        history,
      },
    });
  };

  const openCreditReports = ({ card, tracking, is3B, isFuture }) => {
    eventHandler?.(CLICK, {
      'Click Type':
        card?.type === THREE_BUREAUS
          ? 'Get Credit Reports Earlier'
          : 'View Credit Reports',
      ...tracking,
    });
    if (isFuture) {
      const location = history?.location;
      const previousPage = location?.pathname + location?.search;
      history.push(
        `/creditReports/view?nav=premiumCreditReports&modal=PREMIUM&previousPage=${previousPage}`,
      );
    } else {
      let searchParam = '';
      if (is3B) {
        searchParam = '?search=3B_Report_recent';
      }
      history?.push(`/creditReports/tabs${searchParam}`);
    }
  };

  const openCreditFactor = ({ card, tracking }) => {
    const { creditFactor } = card || {};
    const gradeFactor = GRADE_FACTOR_MAP[creditFactor];
    eventHandler?.(CLICK, {
      'Click Type': `View ${gradeFactor?.label}`,
      ...tracking,
    });
    history?.push(`/dashboard?factor=${creditFactor}`);
  };

  const viewMyCreditInquiries = ({ tracking }) => {
    eventHandler?.(CLICK, {
      'Click Type': `View Credit Inquiries`,
      ...tracking,
    });
    history?.push(`/dashboard?factor=CREDIT_INQUIRIES`);
  };

  const handleActivateCreditBuilder = ({ tracking }) => {
    eventHandler?.(CLICK, {
      'Click Type': 'Activate Credit Builder',
      ...tracking,
    });
    if (isEnrolledInCashUser) {
      history?.push('/cb20Enrollment');
    } else {
      history?.push('/cash');
    }
  };

  const handleActivateRentReporting = ({ tracking }) => {
    eventHandler?.(CLICK, {
      'Click Type': 'Activate Rent Reporting',
      ...tracking,
    });
    history?.push('/rentPaymentReportingHome');
  };

  const handleIdontRent = ({ tracking }) => {
    eventHandler?.(CLICK, {
      'Click Type': "I don't rent",
      ...tracking,
    });
    setState({
      moduleCards: state?.moduleCards.map(m => {
        const modules = m?.modules;
        if (modules) {
          const newModules = {};
          Object.keys(modules)?.forEach(key => {
            if (key !== 'RENT_REPORTING') {
              newModules[key] = m?.modules[key];
            }
          });
          m.modules = newModules;
        }
        return m;
      }),
    });
    const strData = JSON.stringify({
      [SHOW_RENT_REPORTING_CARD]: false,
    });
    dispatch(setGeneralStorage(SHOW_RENT_REPORTING_CARD, strData));
  };

  const handleExpandTracking = props => {
    const { expand, tracking } = props;
    eventHandler?.(CLICK, {
      'Click Type': expand ? 'Show More' : 'Close',
      ...tracking,
    });
  };

  const openCreditCardOffers = () => {
    history?.push('/offers');
  };

  const openDebtReliefActionPage = () => {
    history?.push('/goals/detail/DEBT_RELIEF');
  };

  const closeAccountDetail = () => {
    setState({
      accountDetail: {
        visible: false,
      },
    });
  };

  const handleActivateCreditCalendar = () => {
    eventHandler?.(CLICK, {
      'Click Type': 'Activate Credit Calendar',
    });
    history?.push('/creditManager/calendar');
  };

  const onModalClose = () => {
    setState({ showWelcomeModal: false, showToolTipGoals: true });
  };

  const onModalViewMyCalendar = () => {
    eventHandler?.(CLICK, {
      'Click Type': 'View my Calendar',
    });
    setState({ showWelcomeModal: false, showToolTipGoals: true });
  };

  const setPageName = tabKey => {
    let pageName = CREDIT_MANAGER;
    if (tabKey === 'goals') {
      pageName = GOALS;
    }
    dispatch(setPageMeta(pageName));
  };

  const loadCreditManager = ({ modules, showRentReporting }) => {
    let tabSelected = TABS[tabKey] ?? TAB_CALENDAR;
    setPageName(tabSelected?.key);
    eventHandler(VIEW_PAGE);
    const showWelcomeModal = getItem(SHOW_CALENDAR_WELCOME_MODAL);
    if (!showWelcomeModal || showWelcomeModal === 'true') {
      eventHandler(VIEW_POPUP, {
        'Pop Up Name': 'Paid Demo',
      });
      setItem(SHOW_CALENDAR_WELCOME_MODAL, false);
    }
    const showConffeti = getItem(SHOW_CONFFETI);
    setState({
      tabSelected,
      showToolTipGoals: showWelcomeModal === 'false',
      showWelcomeModal:
        showWelcomeModal === undefined ||
        showWelcomeModal === null ||
        showWelcomeModal === 'true',
      showConffeti: !showConffeti || showConffeti === 'true',
      generalStorageCalled: true,
      firstVisitDone: true,
    });
    if (!modules) {
      dispatch(getContainerData('CREDIT_MANAGER'))
        .then(response => {
          setState({
            ...formatCreditManagerResponse(response, showRentReporting),
          });
        })
        .catch(error => {
          logger.reportAPIError(error);
        });
    } else {
      setState({
        ...formatCreditManagerResponse(modules, showRentReporting),
      });
    }
  };

  const reviewFirstLoginInstanceAction = () => {
    dispatch(getGeneralStorage(FIRST_VISIT_DONE, true))
      .then(data => {
        const firstVisitDone = data[FIRST_VISIT_DONE];
        setState({
          generalStorageCalled: true,
          firstVisitDone,
        });
      })
      .catch(() => {
        setState({
          generalStorageCalled: true,
          firstVisitDone: false,
        });
        const strData = JSON.stringify({
          [FIRST_VISIT_DONE]: true,
        });
        dispatch(setGeneralStorage(FIRST_VISIT_DONE, strData));
      });
  };

  const manageTabs = () => {
    let tabSelected = TABS[tabKey];
    let showToolTipGoals = state?.showToolTipGoals;
    if (isFreeUser) {
      if (!tabSelected) tabSelected = TAB_GOALS;
      if (!state?.firstVisitDone) {
        tabSelected = TAB_CALENDAR;
        showToolTipGoals = true;
        history?.replace(`/creditManager/${TAB_CALENDAR?.key}`);
      }
    } else {
      if (!tabSelected) tabSelected = TAB_CALENDAR;
      showToolTipGoals = true;
    }
    setState({ showToolTipGoals, tabSelected, firstVisitDone: true });
    setPageName(tabSelected?.key);
    eventHandler(VIEW_PAGE);
  };

  useEffect(() => {
    if (userInfo) {
      if (!isFreeUser && !state?.moduleCards) {
        dispatch(getGeneralStorage(SHOW_RENT_REPORTING_CARD, true))
          .then(data => {
            loadCreditManager({
              modules: creditManagerContainer?.creditManagerContainer,
              showRentReporting: data[SHOW_RENT_REPORTING_CARD],
            });
          })
          .catch(() => {
            loadCreditManager({
              modules: creditManagerContainer?.creditManagerContainer,
              showRentReporting: true,
            });
          });
      } else {
        if (!state?.generalStorageCalled) {
          reviewFirstLoginInstanceAction();
        } else {
          manageTabs();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, tabKey, state?.generalStorageCalled]);

  return {
    ...state,
    onTabClick,
    redirectToPlusLandingPage,
    viewMyCreditGoals,
    loadCreditManager,
    isFreeUser,
    isPlus3BUser,
    isFreeTrialUser,
    notEligibleForFreeTrial,
    openAccountDetail,
    onModalClose,
    onModalViewMyCalendar,
    handleActivateCreditCalendar,
    closeAccountDetail,
    openCreditReports,
    openCreditFactor,
    handleActivateCreditBuilder,
    viewMyCreditInquiries,
    handleActivateRentReporting,
    handleIdontRent,
    handleExpandTracking,
    openCreditCardOffers,
    openDebtReliefActionPage,
  };
};

export default useCreditManager;
