import {
  GET_REQUEST,
  GET_SUCCESS,
  GET_FAILURE,
} from 'types/userInfo.constants';
import { LOGIN_SUCCESS, LOGIN_FAILURE } from 'types/app.constants';
import { userService } from 'externals/_services/user.service';
import { updateSessionTimeoutInLocal } from 'helpers/session';
import { logout } from 'actions/login.actions';
import { wrapService } from 'actions/service_wrapper.actions';

export const userActions = {
  get,
  authorizeSession,
};
const a_userServiceGet = wrapService(userService.get, {
  name: 'userServiceGet',
});

function get() {
  return dispatch => {
    dispatch(request());

    dispatch(a_userServiceGet()).then(
      user => {
        dispatch(success(user));
        updateSessionTimeoutInLocal();
      },
      error => {
        dispatch(failure(error.toString()));
        dispatch(error(error.toString()));
      },
    );
  };

  function request() {
    return { type: GET_REQUEST };
  }
  function success(user) {
    return { type: GET_SUCCESS, user };
  }
  function failure(error) {
    return { type: GET_FAILURE, error };
  }
}

function authorizeSession() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(a_userServiceGet())
        .then(userInfo => {
          dispatch({ type: GET_SUCCESS, userInfo });
          dispatch({ type: LOGIN_SUCCESS, userInfo });
        })
        .catch(error => {
          dispatch(logout());
          dispatch({ type: LOGIN_FAILURE, error });
          return reject('failure');
        });
    });
  };
}
