/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { NavLink } from 'components/navigation/NavLink/NavLink';
import './MobileMenu.scss';
import { ReactComponent as HomeButton } from 'style_assets/icons/home-outline.svg';
import { ReactComponent as CreditManagerButton } from 'style/_assets/icons/credit-manager-outline.svg';
import { ReactComponent as MarketplaceButton } from 'style/_assets/icons/marketplace-outline.svg';

import { ReactComponent as HomeButtonFilled } from 'style_assets/icons/home-filled.svg';
import { ReactComponent as CreditManagerFilled } from 'style/_assets/icons/credit-manager-filled.svg';
import { ReactComponent as MarketplaceButtonFilled } from 'style/_assets/icons/marketplace-filled.svg';
import { ReactComponent as CreditCard } from 'style/icons/credit-card.svg';
import Badge from 'components/Badge/Badge';
import { ReactComponent as HamburgerButton } from 'style/_assets/icons/menu.svg';
import HighlightFeatureLabel from 'partial/HighlightFeatureLabel';

import {
  FEATURE_NAME_MARKETPLACE,
  FEATURE_NAME_CREDIT_CARDS,
  FEATURE_NAME_CREDIT_REPORT,
  FEATURE_NAME_CREDIT_MANAGER,
} from 'types/highlightFeatures.constants';
import { containsString } from 'helpers/string.helper';
import { isInvisibleUser } from 'helpers/userHelper';
import Icon from '@ant-design/icons';
import { ReactComponent as SesameCashIconOutline } from 'style/_assets/icons/icon-sesame-cash-outline.svg';
import { ReactComponent as SesameCashIconFilled } from 'style/_assets/icons/icon-sesame-cash-filled.svg';
import { ReactComponent as ProfileButton } from 'style/_assets/icons/profile-outline.svg';
import { ReactComponent as ProfileButtonFilled } from 'style/_assets/icons/profile-filled.svg';
import { CREDIT_CARD_VERTICAL } from 'types/marketplaceTiles.constants';
import { BANKING_VERTICAL } from 'types/mrph.vertical.constants';
import { ConditionalRendering } from 'helpers/conditionalRedering.helper';
import { isFree } from 'helpers/userHelper';
import classnames from 'classnames';
import { ReactComponent as CreditReportFilled } from 'style/_assets/icons/credit-report-filled.svg';
import { ReactComponent as CreditReportOutline } from 'style/_assets/icons/credit-report-outline.svg';
import { OVERVIEW_URL } from 'types/mrph.routes.constants';
import { useFlag } from 'hook/flag.hook';
import { ReactComponent as CSLogo } from 'style_assets/logos/CS_Logo_Blue.svg';
import { ReactComponent as CSLogoPremium } from 'style_assets/logos/CS_Logo_Premium.svg';
import { ReactComponent as CSLogoPlus } from 'style_assets/logos/CSPlusLogo_White.svg';
import { ReactComponent as CreditReportsOutline } from 'style/_assets/icons/credit-report-outline.svg';
import { ReactComponent as CreditReportsFilled } from 'style/_assets/icons/credit-report-filled.svg';
import { LOGO_MSG } from '../constants/Menu.constants';

function MobileMenu(props) {
  const {
    datatestid,
    eventHandler,
    onMenuToggle,
    notifications,
    currentPath,
    userInfo,
    isOcfRole,
    isPlusUser,
    isPremiumUser,
    creditManagerVisited,
  } = props;

  const getOffersActive = () => {
    if (currentPath.includes('creditCardCategories')) {
      return false;
    } else if (currentPath.includes('offers')) {
      return true;
    }
  };

  const menuButtonOutlineClass = classnames('menuButton', 'outline', {
    plusIcon: isPlusUser,
  });
  const mobileIconContainerClass = classnames('mobileIconContainer', {
    menuDotPlusIconSections: isPlusUser,
  });
  const menuItemTextClass = classnames({
    mobileMenuItemPlusText: isPlusUser,
    mobileMenuItemText: !isPlusUser,
  });

  const isFreeUser = isFree(userInfo);
  const getDashboardActive = () =>
    containsString(currentPath, 'dashboard') ||
    containsString(currentPath, 'factors');

  const unifiedHeaderFlag = useFlag('header_unified');
  const onlyShowLogoAndOffer = Boolean(
    unifiedHeaderFlag?.config?.only_show_logo_and_offer,
  );

  if (onlyShowLogoAndOffer) {
    return (
      <MobileNavItemsForOCF
        datatestid={datatestid}
        eventHandler={eventHandler}
        mobileIconContainerClass={mobileIconContainerClass}
        menuItemTextClass={menuItemTextClass}
        isPremiumUser={isPremiumUser}
        isPlusUser={isPlusUser}
        getOffersActive={getOffersActive}
      />
    );
  }

  if (!userInfo && !isOcfRole) {
    return (
      <ul
        className={`mobileMenu flex-container ${
          isPlusUser ? 'plusMobileNavbar' : ''
        }`}
      ></ul>
    );
  } else if (isInvisibleUser(userInfo)) {
    return (
      <ul
        className={`mobileMenu flex-container ${
          isPlusUser ? 'plusMobileNavbar' : ''
        }`}
      >
        <li>
          <a
            href="#"
            onClick={onMenuToggle}
            data-testid={datatestid ? `${datatestid}-home-link` : null}
          >
            <div
              className={`menuDotIconSection ${
                isPlusUser ? 'menuDotPlusIconSection' : ''
              }`}
            >
              <div className="menuDotIconSection">
                {notifications && (
                  <Badge
                    type="dot"
                    className="pos-abs"
                    style={{ right: '14px', top: '-4px' }}
                  />
                )}
                <HamburgerButton />
              </div>
              <div
                className={`${
                  isPlusUser ? 'mobileMenuItemPlusText' : 'mobileMenuItemText'
                }`}
                data-testid={datatestid ? `${datatestid}-home-text` : null}
              >
                Menu
              </div>
            </div>
          </a>
        </li>
        <li>
          <NavLink
            to={OVERVIEW_URL}
            navItem="Overview"
            activeClassName="selected"
            isActive={getDashboardActive}
            data-testid={datatestid ? `${datatestid}-overview-link` : null}
            eventHandler={eventHandler}
          >
            <div
              className={`mobileMenuItemWrapper ${
                isPlusUser ? 'menuDotPlusIconSections' : ''
              }`}
            >
              <HomeButton className="outline" />
              <HomeButtonFilled className="filled" />
              <div
                className={`${
                  isPlusUser ? 'mobileMenuItemPlusText' : 'mobileMenuItemText'
                }`}
                data-testid={datatestid ? `${datatestid}-overview-text` : null}
              >
                Overview
              </div>
            </div>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/cash"
            navItem="Cash"
            activeClassName="selected"
            vertical={BANKING_VERTICAL}
            data-testid={datatestid ? `${datatestid}-myRecs-link` : null}
            eventHandler={eventHandler}
          >
            <div className="mobileMenuItemWrapper">
              <div className="mobileIconContainer">
                <Icon
                  className={`menuButton outline ${
                    isPlusUser ? 'menuDotPlusIconSection' : ''
                  }`}
                  component={SesameCashIconOutline}
                />
                <Icon
                  className={`menuButton filled ${
                    isPlusUser ? 'menuDotPlusIconSection' : ''
                  }`}
                  component={SesameCashIconFilled}
                />
              </div>
              <div
                className={`${
                  isPlusUser ? 'mobileMenuItemPlusText' : 'mobileMenuItemText'
                }`}
                data-testid={datatestid ? `${datatestid}-myRecs-text` : null}
              >
                Cash
              </div>
            </div>
          </NavLink>
        </li>
        <li>
          <NavLink
            exact
            to="/profile"
            navItem="Profile"
            activeClassName="selected"
            data-testid={datatestid ? `${datatestid}-profile-link` : null}
            eventHandler={eventHandler}
          >
            <div className="mobileMenuItemWrapper">
              <div className="mobileIconContainer">
                <Icon
                  className="menuButton outline"
                  component={ProfileButton}
                />
                <Icon
                  className="menuButton filled"
                  component={ProfileButtonFilled}
                />
              </div>
              <div
                className={`${
                  isPlusUser ? 'mobileMenuItemPlusText' : 'mobileMenuItemText'
                }`}
                data-testid={datatestid ? `${datatestid}-profile-text` : null}
              >
                Profile
              </div>
            </div>
          </NavLink>
        </li>
      </ul>
    );
  } else {
    return (
      <ul
        className={`mobileMenu flex-container ${
          isPlusUser ? 'plusMobileNavbar' : ''
        }`}
      >
        <li>
          <NavLink
            to={OVERVIEW_URL}
            navItem="Overview"
            activeClassName="selected"
            isActive={getDashboardActive}
            data-testid={datatestid ? `${datatestid}-overview-link` : null}
            eventHandler={eventHandler}
          >
            <div
              className={`mobileMenuItemWrapper ${
                isPlusUser ? 'menuDotPlusIconSections' : ''
              }`}
            >
              <HomeButton className="outline" />
              <HomeButtonFilled className="filled" />
              <div
                className={`${
                  isPlusUser ? 'mobileMenuItemPlusText' : 'mobileMenuItemText'
                }`}
                data-testid={datatestid ? `${datatestid}-overview-text` : null}
              >
                Home
              </div>
            </div>
          </NavLink>
        </li>

        <li>
          <NavLink
            to="/creditManager"
            navItem="CreditManager"
            activeClassName="selected"
            data-testid={
              datatestid ? `${datatestid}-credit-manager-link` : null
            }
            eventHandler={eventHandler}
            onClick={creditManagerVisited?.checkAsVisited}
          >
            <div className="mobileMenuItemWrapper">
              <div
                className={`mobileIconContainer ${
                  isPlusUser ? 'menuDotPlusIconSections' : ''
                }`}
              >
                <CreditManagerButton className="outline" />
                <CreditManagerFilled className="filled" />
                <HighlightFeatureLabel
                  featureToHighlight={FEATURE_NAME_CREDIT_MANAGER}
                />
              </div>
              <div
                className={`${
                  isPlusUser ? 'mobileMenuItemPlusText' : 'mobileMenuItemText'
                }`}
                data-testid={
                  datatestid ? `${datatestid}-credit-manager-text` : null
                }
              >
                Goals
              </div>
            </div>
            <ConditionalRendering isTrue={!creditManagerVisited?.visited}>
              <div className="redDotCircle width-5px height-5px pos-rel"></div>
            </ConditionalRendering>
          </NavLink>
        </li>
        <MobileNavItemsMenu
          datatestid={datatestid}
          eventHandler={eventHandler}
          mobileIconContainerClass={mobileIconContainerClass}
          menuButtonOutlineClass={menuButtonOutlineClass}
          menuItemTextClass={menuItemTextClass}
          isFreeUser={isFreeUser}
          getOffersActive={getOffersActive}
        />
      </ul>
    );
  }
}

const MobileNavItemsMenu = props => {
  const {
    isFreeUser,
    datatestid,
    eventHandler,
    mobileIconContainerClass,
    menuButtonOutlineClass,
    menuItemTextClass,
    getOffersActive,
  } = props;
  if (isFreeUser) {
    return (
      <FreeUserNavItems
        datatestid={datatestid}
        eventHandler={eventHandler}
        mobileIconContainerClass={mobileIconContainerClass}
        menuButtonOutlineClass={menuButtonOutlineClass}
        menuItemTextClass={menuItemTextClass}
        getOffersActive={getOffersActive}
      />
    );
  } else {
    return (
      <NonFreeUserNavItems
        datatestid={datatestid}
        eventHandler={eventHandler}
        mobileIconContainerClass={mobileIconContainerClass}
        menuItemTextClass={menuItemTextClass}
        getOffersActive={getOffersActive}
        isPlusUser={true}
      />
    );
  }
};

const FreeUserNavItems = props => {
  const {
    datatestid,
    eventHandler,
    mobileIconContainerClass,
    menuItemTextClass,
    getOffersActive,
  } = props;
  return (
    <>
      <li>
        <NavLink
          exact
          to="/creditReports/tabs"
          navItem="Reports"
          activeClassName="selected"
          data-testid={datatestid ? `${datatestid}-overview-link` : null}
          eventHandler={eventHandler}
        >
          <div className="mobileMenuItemWrapper">
            <div className={mobileIconContainerClass}>
              <CreditReportsOutline className="outline" />
              <CreditReportsFilled className="filled" />
              <HighlightFeatureLabel
                featureToHighlight={FEATURE_NAME_CREDIT_REPORT}
              />
            </div>
            <div
              className={menuItemTextClass}
              data-testid={datatestid ? `${datatestid}-overview-text` : null}
            >
              Reports
            </div>
          </div>
        </NavLink>
      </li>
      <li>
        <NavLink
          exact
          to="/offers/creditCardCategories"
          navItem="Cards"
          vertical={CREDIT_CARD_VERTICAL}
          activeClassName="selected"
          data-testid={datatestid ? `${datatestid}-overview-link` : null}
          eventHandler={eventHandler}
        >
          <div className="mobileMenuItemWrapper">
            <div className={mobileIconContainerClass}>
              <CreditCard className="outline" />
              <CreditCard className="filled" />
              <HighlightFeatureLabel
                featureToHighlight={FEATURE_NAME_CREDIT_CARDS}
              />
            </div>
            <div
              className={menuItemTextClass}
              data-testid={datatestid ? `${datatestid}-overview-text` : null}
            >
              Cards
            </div>
          </div>
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/offers"
          isActive={getOffersActive}
          navItem="Credit"
          vertical={CREDIT_CARD_VERTICAL}
          activeClassName="selected"
          data-testid={datatestid ? `${datatestid}-marketPlace-link` : null}
          eventHandler={eventHandler}
        >
          <div className="mobileMenuItemWrapper">
            <div className={mobileIconContainerClass}>
              <MarketplaceButton className="outline" />
              <MarketplaceButtonFilled className="filled" />
              <HighlightFeatureLabel
                featureToHighlight={FEATURE_NAME_MARKETPLACE}
              />
            </div>
            <div
              className={menuItemTextClass}
              data-testid={datatestid ? `${datatestid}-marketPlace-text` : null}
            >
              Offers
            </div>
          </div>
        </NavLink>
      </li>
    </>
  );
};

const NonFreeUserNavItems = props => {
  const {
    datatestid,
    eventHandler,
    mobileIconContainerClass,
    menuItemTextClass,
    getOffersActive,
    isPlusUser,
  } = props;
  return (
    <>
      <li>
        <NavLink
          exact
          to="/creditReports/tabs"
          navItem="Reports"
          activeClassName="selected"
          data-testid={datatestid ? `${datatestid}-overview-link` : null}
          eventHandler={eventHandler}
        >
          <div className="mobileMenuItemWrapper">
            <div className={mobileIconContainerClass}>
              <Icon
                className={`menuButton outline ${isPlusUser ? 'plusIcon' : ''}`}
                component={CreditReportOutline}
              />
              <Icon
                className="menuButton filled"
                component={CreditReportFilled}
              />
              <HighlightFeatureLabel
                featureToHighlight={FEATURE_NAME_CREDIT_REPORT}
              />
            </div>
            <div
              className={menuItemTextClass}
              data-testid={datatestid ? `${datatestid}-overview-text` : null}
            >
              Reports
            </div>
          </div>
        </NavLink>
      </li>
      <li>
        <NavLink
          exact
          to="/offers/creditCardCategories"
          navItem="Cards"
          vertical={CREDIT_CARD_VERTICAL}
          activeClassName="selected"
          data-testid={datatestid ? `${datatestid}-creditCards-link` : null}
          eventHandler={eventHandler}
        >
          <div className="mobileMenuItemWrapper">
            <div className={mobileIconContainerClass}>
              <CreditCard className="outline" />
              <CreditCard className="filled" />
              <HighlightFeatureLabel
                featureToHighlight={FEATURE_NAME_CREDIT_CARDS}
              />
            </div>
            <div
              className={menuItemTextClass}
              data-testid={datatestid ? `${datatestid}-creditCards-text` : null}
            >
              Cards
            </div>
          </div>
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/offers"
          isActive={getOffersActive}
          navItem="Marketplace"
          vertical={CREDIT_CARD_VERTICAL}
          activeClassName="selected"
          data-testid={datatestid ? `${datatestid}-marketPlace-link` : null}
          eventHandler={eventHandler}
        >
          <div className="mobileMenuItemWrapper">
            <div className={mobileIconContainerClass}>
              <MarketplaceButton className="outline" />
              <MarketplaceButtonFilled className="filled" />
              <HighlightFeatureLabel
                featureToHighlight={FEATURE_NAME_MARKETPLACE}
              />
            </div>
            <div
              className={menuItemTextClass}
              data-testid={datatestid ? `${datatestid}-marketPlace-text` : null}
            >
              Offers
            </div>
          </div>
        </NavLink>
      </li>
    </>
  );
};

const MobileNavItemsForOCF = props => {
  const {
    isPremiumUser,
    isPlusUser,
    datatestid,
    eventHandler,
    mobileIconContainerClass,
    menuItemTextClass,
    getOffersActive,
  } = props;

  return (
    <ul
      className={`mobileMenu gapForOCF flex-container ${
        isPlusUser ? 'plusMobileNavbar' : ''
      }`}
    >
      <NavLink to={OVERVIEW_URL}>
        <div
          className={classnames('navbar-logo-mobile marg14', {
            padL12: isPremiumUser,
          })}
        >
          {isPlusUser ? (
            <CSLogoPlus />
          ) : isPremiumUser ? (
            <CSLogoPremium />
          ) : (
            <CSLogo className="navbar-logo" aria-label={LOGO_MSG} role="img" />
          )}
        </div>
      </NavLink>

      <li>
        <NavLink
          to="/offers"
          isActive={getOffersActive}
          navItem="Marketplace"
          vertical={CREDIT_CARD_VERTICAL}
          activeClassName="selected"
          data-testid={datatestid ? `${datatestid}-marketPlace-link` : null}
          eventHandler={eventHandler}
        >
          <div className="mobileMenuItemWrapper">
            <div className={mobileIconContainerClass}>
              <MarketplaceButton className="outline" />
              <MarketplaceButtonFilled className="filled" />
              <HighlightFeatureLabel
                featureToHighlight={FEATURE_NAME_MARKETPLACE}
              />
            </div>
            <div
              className={menuItemTextClass}
              data-testid={datatestid ? `${datatestid}-marketPlace-text` : null}
            >
              Offers
            </div>
          </div>
        </NavLink>
      </li>
    </ul>
  );
};

export { MobileMenu };
