import React from 'react';
import { getImageBasePath } from 'helpers/env';
import Label from 'components/form/Label/Label';
import { Button } from 'components/form/Button/index';
import { ErrorTemplate } from './ErrorTemplate';

export const CreditProfileError = ({
  errorType,
  showButtonToExploreOffers,
  ...props
}) => {
  return (
    <ErrorTemplate
      errorType={errorType}
      title="Credit file not found"
      imgSrc={`${getImageBasePath()}images/zero_state_error.png`}
    >
      <Label className="padTB8 margB16">
        This can happen for many reasons, such as if a credit bureau cannot
        locate your account or if you are new to credit.
      </Label>

      <Label className="padTB8 margB16">
        Consider building your credit by checking out some of the best offers
        from our partners.
      </Label>
      {showButtonToExploreOffers && (
        <Button mrphType="primary" onClick={props.onButtonClick}>
          Explore Offers
        </Button>
      )}
    </ErrorTemplate>
  );
};
