import { completeUrl, getGetParam } from './domHelper';

export const getCreditReportTitleBasedOnTypeOfBureau = (
  groupType,
  isSesamePulse,
) => {
  let reportTitle = '';
  if (
    (groupType === 'THREE_BUREAUS' || !isSesamePulse) &&
    groupType !== 'ONE_BUREAU'
  ) {
    reportTitle = 'TransUnion, Equifax, Experian report';
  } else {
    reportTitle = 'TransUnion report';
  }

  return reportTitle;
};

export const hasRecurlyHybridUser = props => {
  if (!props?.location?.state) {
    return false;
  }
  return (
    props?.location?.state?.indexOf('/precheckoutMobile') !== -1 ||
    props?.location?.state?.indexOf('/creditreporthybridcheckout') !== -1 ||
    props?.location?.state?.indexOf('/creditreporthybrid') !== -1 ||
    props?.location?.state?.indexOf('/creditreporthybridwriteletter') !== -1 ||
    props?.location?.state?.indexOf('/hybridConvertTrial') !== -1 ||
    props?.location?.state?.indexOf('/hybridPurchaseReport') !== -1 ||
    props?.location?.state?.indexOf('/creditReportTabsHybrid') !== -1 ||
    props?.location?.state?.indexOf('/creditReportTUHybrid') !== -1 ||
    props?.location?.state?.indexOf('/disputeManagementHybrid') !== -1
  );
};

export const hasRecurlyCreditReportTUHybridUser = props =>
  props?.location?.state === '/creditReportTUHybrid' ||
  props?.location?.state === '/creditReportTabsHybrid' ||
  props?.location?.state === '/disputeManagementHybrid';

export const isCheckoutFromMobile = props => {
  if (props?.location?.pathname) {
    return (
      props?.location?.pathname?.indexOf('/precheckoutMobile') > -1 ||
      (props?.location?.pathname?.indexOf('/checkout') > -1 &&
        props?.location?.search?.indexOf('sessionId=') > -1 &&
        props?.location?.search?.indexOf('sessionIdentifier=') > -1 &&
        props?.location?.search?.indexOf('serviceId=') > -1)
    );
  } else {
    return false;
  }
};

export const isCreditReportHybridPage = props => {
  if (!props?.location?.pathname) {
    return false;
  }
  return (
    props?.location?.pathname?.indexOf('/paymentPendingSubscription/login') >
      -1 ||
    isCheckoutFromMobile(props) ||
    props?.location?.pathname?.indexOf('/creditreporthybridcheckout') > -1 ||
    props?.location?.pathname?.indexOf('/creditreporthybrid') > -1 ||
    props?.location?.pathname?.indexOf('/creditreporthybridwriteletter') > -1 ||
    props?.location?.pathname?.indexOf('/hybridConvertTrial') > -1 ||
    props?.location?.pathname?.indexOf('/hybridPurchaseReport') > -1 ||
    props?.location?.pathname?.indexOf('/creditReportTUHybrid') > -1 ||
    props?.location?.pathname?.indexOf('/creditReportTabsHybrid') > -1 ||
    props?.location?.pathname?.indexOf('/disputeManagementHybrid') > -1 ||
    props?.location?.state === '/paymentPendingSubscription/login' ||
    hasRecurlyHybridUser(props)
  );
};

export const evaluateHybridState = props => {
  const { history } = props;
  const stateofLocation = history?.location?.state;
  const isTUHybrid =
    stateofLocation?.indexOf('creditReportTUHybrid') !== -1 ||
    stateofLocation?.indexOf('creditReportTabsHybrid') !== -1 ||
    stateofLocation?.indexOf('disputeManagementHybrid') !== -1;
  const calledFromTUHybridCtrl = !stateofLocation ? false : isTUHybrid;
  const sessionId = stateofLocation
    ? getGetParam('sessionId', completeUrl(stateofLocation))
    : null;
  const sessionIdentifier = stateofLocation
    ? getGetParam('sessionIdentifier', completeUrl(stateofLocation))
    : null;
  const plusLaunch = stateofLocation
    ? getGetParam('plusLaunch', completeUrl(stateofLocation))
    : null;
  const useScrollLink = stateofLocation
    ? getGetParam('useScrollLink', completeUrl(stateofLocation))
    : null;
  const hideFooter = stateofLocation
    ? getGetParam('hideFooter', completeUrl(stateofLocation))
    : null;
  return {
    calledFromTUHybridCtrl,
    stateofLocation,
    sessionId,
    sessionIdentifier,
    plusLaunch: plusLaunch === 'true',
    useScrollLink: useScrollLink === 'true',
    hideFooter: hideFooter === 'true',
  };
};

export const ableToRenderPremiumPage = props => {
  const { history, config } = props;
  const premiumPathAllowed = config?.premiumPathAllowed;
  const searchPath = history?.location?.search?.replace('?', '');
  return premiumPathAllowed?.includes(searchPath);
};
