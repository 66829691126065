import { useEffect } from 'react';
import { loadGoals } from 'actions/goals.actions';
import { loadAutoRefinance } from 'actions/actions.actions';
import { getItem, setItem } from 'helpers/localstorage';
import { getAutoRefinance } from 'helpers/autoOffer.helpers';

import {
  addMonth,
  dateIsBetweenTwoDates,
  formatAsMonthDayYear,
  getMoment,
  getMonthLastDayDate,
  isDateAfterTarget,
  isDateBeforeTarget,
} from '../helpers/dateHelper';
import { useFlag } from 'hook/flag.hook';
import { emptyObj, isDefined } from 'helpers/objectHelpers';
// @config
import CardsConfig from 'pages/Goals/components/GoalsView/components/GoalsCard/config';
import {
  getPathForCreditBuilder2,
  getPathForRentReporting,
  getRuleForExperiment,
} from 'helpers/goals.helpers';
import { useSelector } from 'react-redux';

export const useGoalsManager = (props, shouldloadGoals = true) => {
  const { dispatch, userInfo, creditInfo, cashAccount } = props;

  const {
    goals,
    goalsLoaded,
    goalsLoading,
    autoRefinance,
    autoRefinanceError,
  } = useSelector(state => ({
    goals: state.goals?.goals,
    goalsLoaded: state.goals?.goalsLoaded,
    goalsLoading: state.goals?.goalsLoading,
    autoRefinance: state.actions?.autoRefinance,
    autoRefinanceError: state.actions?.autoRefinanceError,
  }));

  useEffect(() => {
    if (
      shouldloadGoals &&
      !goals &&
      !goalsLoading &&
      !goalsLoaded &&
      userInfo
    ) {
      dispatch(loadGoals());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  useEffect(() => {
    if (!autoRefinance && !autoRefinanceError && userInfo && creditInfo) {
      dispatch(loadAutoRefinance(creditInfo, userInfo));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, creditInfo]);

  const getAutoRefinanceInfo = () => {
    return getAutoRefinance(autoRefinance, creditInfo);
  };

  return {
    getAutoRefinanceInfo,
    goalsManager: {
      goals,
      goalsLoading,
      autoRefinance,
      autoRefinanceError,
    },
  };
};

export const useMyGoalsCounter = () => {
  let pendingGoals = JSON.parse(getItem('pendingGoals'));
  const today = formatAsMonthDayYear(getMoment());

  if (!pendingGoals) {
    pendingGoals = {
      nextDisplay: {
        startDate: formatAsMonthDayYear(getMoment()),
        endDate: formatAsMonthDayYear(getMonthLastDayDate()),
      },
      lastSeen: formatAsMonthDayYear(getMoment()),
    };
    setItem('pendingGoals', JSON.stringify(pendingGoals));
  } else {
    if (isDateAfterTarget(today, pendingGoals.nextDisplay.endDate)) {
      setNextDisplayGoalsCounter();
    }
  }

  const showGoalsCounter = () =>
    pendingGoals &&
    dateIsBetweenTwoDates(
      today,
      pendingGoals.nextDisplay.startDate,
      pendingGoals.nextDisplay.endDate,
      'MM-DD-YYYY',
    );

  function setNextDisplayGoalsCounter() {
    if (!isDateBeforeTarget(getMoment(), pendingGoals.nextDisplay.startDate)) {
      setItem(
        'pendingGoals',
        JSON.stringify({
          nextDisplay: {
            startDate: formatAsMonthDayYear(
              getMoment(addMonth(getMoment(), 1)).date(1),
            ),
            endDate: formatAsMonthDayYear(
              getMoment(addMonth(getMoment(), 1)).date(8),
            ),
          },
          lastSeen: formatAsMonthDayYear(getMoment()),
        }),
      );
    }
  }

  return {
    showGoalsCounter,
    setNextDisplayGoalsCounter,
  };
};

const findActions = (goals, actionIds) => {
  const actionsFinal = {};
  goals?.forEach(goal => {
    goal?.actions?.forEach(action => {
      if (actionIds?.includes(action?.name)) {
        actionsFinal[action?.name] = action;
      }
    });
  });
  return actionsFinal;
};
