import { getFeaturedAdOffer } from 'partial/FeaturedAd/hooks/FeaturedAd.hook';
import { PERSONAL_LOAN } from 'types/marketplaceTiles.constants';

const defaultModulesSortPriority = {
  CREDIT_CARD_CATEGORIES: 100,
  FEATURED_AD: 200,
  GOALS_CURATED: 300,
};

const sortModules = (
  moduleList,
  customSortPriorities = {},
  defaultSortPriority,
) => {
  const sortPriority = Object.assign(defaultSortPriority, customSortPriorities);

  moduleList.sort((a, b) => {
    const b_priority = sortPriority[b.module];
    const a_priority = sortPriority[a.module];
    return a_priority - b_priority;
  });
};

const evaluateFeaturedAd = (moduleList, deps) => {
  const featureAd = getFeaturedAdOffer('OFFERS', 'TOP', deps.featuredAds);

  if (featureAd) {
    moduleList.push({
      module: 'FEATURED_AD',
      showDisclaimer: false,
      vertical: 'Credit Card',
      offers: [
        {
          offerType: 'FEATURED_AD',
          featuredAd: featureAd,
        },
      ],
    });
  }
};

const evaluateCreditCardCategories = moduleList => {
  moduleList.push({
    module: 'CREDIT_CARD_CATEGORIES',
  });
};

const evaluateGoalsCurated = moduleList => {
  moduleList.push({
    module: 'GOALS_CURATED',
  });
};

export const createModulesContainer = deps => {
  const moduleList = [];
  evaluateFeaturedAd(moduleList, deps);
  evaluateCreditCardCategories(moduleList);
  evaluateGoalsCurated(moduleList);
  sortModules(
    moduleList,
    deps.customSortPriorities,
    defaultModulesSortPriority,
  );
  return { modules: moduleList };
};

export const createModulesContainerToPersonalLoan = (
  selectedVertical,
  staticModules,
) => {
  let moduleList = [];
  if (
    staticModules?.modules?.length > 0 &&
    selectedVertical === PERSONAL_LOAN
  ) {
    moduleList = staticModules?.modules;
  } else {
    moduleList.push({ module: 'PERSONAL_LOAN_OFFERS' });
  }
  return { modules: moduleList };
};
