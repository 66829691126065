import React from 'react';
import {
  AUTO_INSURANCE_VERTICAL,
  AUTO_LOAN_VERTICAL,
  CREDIT_CARD_VERTICAL,
  CREDIT_REPAIR_EXPERT_VERTICAL,
  HOME_INSURANCE_VERTICAL,
  PERSONAL_LOAN_VERTICAL,
} from 'types/marketplaceTiles.constants';

// @images
import CREDIT_SCORE_IMAGE from 'style_assets/goals/card-score-increase.svg';
import SCORE_OVER_TIME from 'style_assets/goals/score-over-time.png';
import SELF_LENDER_IMAGE from 'style_assets/goals/self-lender.png';
import CREDIT_REPAIR_EXPERT_IMAGE from 'style_assets/goals/reduce-negative-marks.png';
import HIGHER_CREDIT_LIMIT_IMAGE from 'style_assets/goals/higher-credit-limit.png';
import AUTO_REFINANCE_IMAGE from 'style_assets/goals/auto-refinance.png';
import AUTO_PURCHASE_IMAGE from 'style_assets/goals/auto-purchase.png';
import PERSONAL_LOAN_IMAGE from 'style_assets/goals/personal-loan.png';
import CREDIT_FOUNDATION_IMAGE from 'style_assets/goals/credit-foundation.png';
import CREDIT_USAGE_IMAGE from 'style_assets/goals/credit-usage.svg';
import NEGATIVE_MARKS_IMAGE from 'style_assets/goals/bad-payment-history.png';
import HOME_INSURANCE_NEW_HOME_IMAGE from 'style_assets/goals/home-insurance-new-home.png';
import AUTO_INSURANCE_FOR_NEW_AUTO_LOAN_IMAGE from 'style_assets/goals/auto-insurance-for-new-auto-loan.png';
import AUTO_INSURANCE_FOR_CREDIT_SCORE_INCREASE_IMAGE from 'style_assets/goals/auto-insurance-credit-score-increase.png';
import FIND_REWARDS_IMAGE from 'style_assets/goals/find-rewards.png';
import DEBT_RELIEF_IMAGE from 'style_assets/goals/debt-relief.png';
import DEBT_PAYOFF_CALCULATOR_IMAGE from 'style_assets/goals/cs_3d_creditcard.png';
import { DEBT_RELIEF_VERTICAL } from 'types/mrph.vertical.constants';
import { autoRefinanceOfferHowIsCalculatedTooltip } from 'helpers/offer.helpers';
import {
  AUTO_INSURANCE_FOR_CREDIT_SCORE_INCREASE,
  AUTO_INSURANCE_FOR_NEW_AUTO_LOAN,
  AUTO_LOAN_REFINANCE_OFFERS,
  AUTO_PURCHASE_ACTION,
  CC_REWARDS_ACTION,
  CREDIT_FOUNDATION_ACTION,
  CREDIT_LIMIT_ACTION,
  CREDIT_USAGE_ACTION,
  DEBT_RELIEF_ACTION,
  HOME_INSURANCE,
  NEGATIVE_MARKS_ACTION,
  NEW_CARD_SCORE_INCREASE_ACTION,
  NEW_CARD_SCORE_OVER_TIME,
  NEW_CREDIT_REPAIR_ACTION,
  NEW_PREQUAL_PERSONAL_LOAN,
  NEW_REPORT_RENT_PAYMENTS_ACTION,
  NEW_PAYMENT_HISTORY_ACTION,
  SELF_ACTION,
  DEBT_PAY_OFF_CALCULATOR_ACTION,
} from 'types/morph.pagePosition.constants';

const getSimulation6MonthsPointsIncrease = simulationsResponse => {
  if (simulationsResponse?.successful) {
    const { scoreOverTimeData, creditScore: currentCreditScore } =
      simulationsResponse?.successful[0];
    const creditScoreSimulation = scoreOverTimeData[6]?.[0]?.score;
    if (creditScoreSimulation) {
      return creditScoreSimulation - currentCreditScore;
    }

    return 0;
  }
};

export const CardsConfig = {
  NEW_CC_SCORE_OVER_TIME: {
    moduleName: 'New Card Score Increase Overtime',
    clickType: 'New Card Score Increase Overtime',
    pagePosition: NEW_CARD_SCORE_OVER_TIME,
    linkPath: '/goals/detail/NEW_CC_SCORE_OVER_TIME',
    currentModule: 'GOAL_NEW_CARD_SCORE_INCREASE_OVERTIME',
    actionName: 'New Card Score Increase Overtime',
    vertical: CREDIT_CARD_VERTICAL,
    image: {
      src: SCORE_OVER_TIME,
      alt: 'reduce credit utilization',
    },
    cardTitle: 'potential score BOOST',
    hasScoreIncreaseSimulation: true,
    scoreIncreaseSimulation: simulationsResponse =>
      getSimulation6MonthsPointsIncrease(simulationsResponse),
    hideTooltip: false,
    tooltip: `This simulation is based on analysis of the credit profiles of Credit
        Sesame members, and your actual results may vary significantly. It is
        only a rough estimate and relies on the assumption that you are also
        paying your bills on time, keeping your credit balances low, and
        avoiding multiple inquiries. However, Credit Sesame does not guarantee
        you will experience these results, and you may even see a decrease in
        your credit score. Any credit score improvement is the result of many
        factors, including paying bills on time, keeping credit balances low,
        avoiding unnecessary inquiries, appropriate financial planning, and
        developing better credit habits.`,
    partner: 'N/A',
    productKey: 'N/A',
  },
  NEW_CARD_SCORE_INCREASE: {
    moduleName: 'New Card Score Increase',
    clickType: 'New Card Score Increase',
    pagePosition: NEW_CARD_SCORE_INCREASE_ACTION,
    linkPath: '/goals/detail/NEW_CARD_SCORE_INCREASE',
    currentModule: 'GOAL_NEW_CARD_SCORE_INCREASE',
    vertical: CREDIT_CARD_VERTICAL,
    actionName: 'New Card Score Increase',
    image: {
      src: CREDIT_SCORE_IMAGE,
      alt: 'reduce credit utilization',
    },
    cardTitle: 'potential score BOOST',
    hasScoreIncreaseSimulation: true,
    scoreIncreaseSimulation: simulationsResponse =>
      getSimulation6MonthsPointsIncrease(simulationsResponse),
    hideTooltip: false,
    tooltip: `This simulation is based on analysis of the credit profiles of Credit
        Sesame members, and your actual results may vary significantly. It is
        only a rough estimate and relies on the assumption that you are also
        paying your bills on time, keeping your credit balances low, and
        avoiding multiple inquiries. However, Credit Sesame does not guarantee
        you will experience these results, and you may even see a decrease in
        your credit score. Any credit score improvement is the result of many
        factors, including paying bills on time, keeping credit balances low,
        avoiding unnecessary inquiries, appropriate financial planning, and
        developing better credit habits.`,
    partner: 'N/A',
    productKey: 'N/A',
  },
  CREDIT_REPAIR_EXPERT: {
    moduleName: 'Credit Repair Expert',
    clickType: 'Credit Repair Expert',
    pagePosition: NEW_CREDIT_REPAIR_ACTION,
    linkPath: '/goals/detail/CREDIT_REPAIR_EXPERT',
    currentModule: 'GOAL_CREDIT_REPAIR_EXPERT',
    actionName: 'Credit Repair Expert',
    hasScoreIncreaseSimulation: false,
    image: {
      src: CREDIT_REPAIR_EXPERT_IMAGE,
      alt: 'Credit Repair Expert',
    },
    cardTitle: 'potential score BOOST',
    vertical: CREDIT_REPAIR_EXPERT_VERTICAL,
    tooltip: null,
    hideTooltip: true,
    partner: 'Lexlaw',
    productKey: 'N/A',
  },
  NEGATIVE_MARKS: {
    moduleName: 'Bad Payment History',
    clickType: 'Bad Payment History',
    pagePosition: NEGATIVE_MARKS_ACTION,
    linkPath: '/goals/detail/NEGATIVE_MARKS',
    currentModule: 'GOAL_NEGATIVE_MARKS',
    actionName: 'Bad Payment History',
    vertical: CREDIT_REPAIR_EXPERT_VERTICAL,
    tooltip: null,
    hideTooltip: true,
    cardTitle: 'potential score BOOST',
    image: {
      src: NEGATIVE_MARKS_IMAGE,
      alt: 'reduce negative marks',
    },
    partner: 'Lexlaw',
    productKey: 'N/A',
  },
  SELF_LENDER_SCORE_OVER_TIME: {
    moduleName: 'Self Lender - Score Overtime',
    clickType: 'Self Lender - Score Overtime',
    pagePosition: SELF_ACTION,
    linkPath: '/goals/detail/SELF_LENDER_SCORE_OVER_TIME',
    currentModule: 'SELF',
    actionName: 'Self Lender - Score Overtime',
    vertical: PERSONAL_LOAN_VERTICAL,
    hideTooltip: false,
    cardTitle: 'potential score BOOST',
    image: {
      src: SELF_LENDER_IMAGE,
      alt: 'New to credit? Try a credit builder loan from Self',
    },
    hasScoreIncreaseSimulation: true,
    scoreIncreaseSimulation: simulationsResponse =>
      getSimulation6MonthsPointsIncrease(simulationsResponse),
    tooltip: `This simulation is based on analysis of the credit profiles of Credit
        Sesame members, and your actual results may vary significantly. It is
        only a rough estimate and relies on the assumption that you are also
        paying your bills on time, keeping your credit balances low, and
        avoiding multiple inquiries. However, Credit Sesame does not guarantee
        you will experience these results, and you may even see a decrease in
        your credit score. Any credit score improvement is the result of many
        factors, including paying bills on time, keeping credit balances low,
        avoiding unnecessary inquiries, appropriate financial planning, and
        developing better credit habits.`,
    partner: 'N/A',
    productKey: 'N/A',
  },
  DEBT_PAY_OFF_CALCULATOR: {
    moduleName: 'Credit Debt PayOff Calculator',
    clickType: 'Credit Debt PayOff Calculator',
    pagePosition: DEBT_PAY_OFF_CALCULATOR_ACTION,
    linkPath: '/debtPayOffCalculator',
    currentModule: 'GOAL_DEBT_PAY_OFF_CALCULATOR',
    actionName: 'Credit Debt PayOff Calculator',
    vertical: CREDIT_CARD_VERTICAL,
    hideTooltip: false,
    cardTitle: 'potential score BOOST',
    image: {
      src: DEBT_PAYOFF_CALCULATOR_IMAGE,
      alt: 'New to credit? Try a credit builder loan from Self',
    },
    hasScoreIncreaseSimulation: true,
    scoreIncreaseSimulation: simulationsResponse =>
      getSimulation6MonthsPointsIncrease(simulationsResponse),
    tooltip: `This simulation is based on analysis of the credit profiles of Credit
        Sesame members, and your actual results may vary significantly. It is
        only a rough estimate and relies on the assumption that you are also
        paying your bills on time, keeping your credit balances low, and
        avoiding multiple inquiries. However, Credit Sesame does not guarantee
        you will experience these results, and you may even see a decrease in
        your credit score. Any credit score improvement is the result of many
        factors, including paying bills on time, keeping credit balances low,
        avoiding unnecessary inquiries, appropriate financial planning, and
        developing better credit habits.`,
    partner: 'N/A',
    productKey: 'N/A',
  },
  HIGHER_CREDIT_LIMIT: {
    moduleName: 'Higher Credit Limit',
    clickType: 'Higher Credit Limit',
    pagePosition: CREDIT_LIMIT_ACTION,
    linkPath: '/goals/detail/HIGHER_CREDIT_LIMIT',
    currentModule: 'CREDIT_LIMIT',
    hasScoreIncreaseSimulation: false,
    actionName: 'Higher Credit Limit',
    hideTooltip: true,
    cardTitle: 'risk reduction',
    tooltip: null,
    vertical: CREDIT_CARD_VERTICAL,
    image: {
      src: HIGHER_CREDIT_LIMIT_IMAGE,
      alt: 'risk reduction',
    },
    partner: 'N/A',
    productKey: 'N/A',
  },
  CREDIT_FOUNDATION: {
    moduleName: 'Credit Foundation',
    clickType: 'Credit Foundation',
    pagePosition: CREDIT_FOUNDATION_ACTION,
    linkPath: '/goals/detail/CREDIT_FOUNDATION',
    currentModule: 'CREDIT_FOUNDATION',
    actionName: 'Credit Foundation',
    hideTooltip: true,
    cardTitle: 'CREDIT RECOMMENDATION',
    vertical: CREDIT_CARD_VERTICAL,
    image: {
      src: CREDIT_FOUNDATION_IMAGE,
      alt: 'Credit Recommendation',
    },
    partner: 'N/A',
    productKey: 'N/A',
  },
  CREDIT_USAGE: {
    moduleName: 'Credit Usage',
    clickType: 'Credit Usage',
    pagePosition: CREDIT_USAGE_ACTION,
    linkPath: '/goals/detail/CREDIT_USAGE',
    currentModule: 'REDUCE_CREDIT_USAGE_WITH_LIMIT_INCREASE',
    actionName: 'Credit Usage',
    vertical: CREDIT_CARD_VERTICAL,
    cardTitle: 'IMPROVE CREDIT STABILITY',
    image: {
      src: CREDIT_USAGE_IMAGE,
      alt: 'improve credit stability',
    },
    hideTooltip: true,
    partner: 'N/A',
    productKey: 'N/A',
  },
  AUTO_REFI: {
    moduleName: 'Auto Refinance',
    clickType: 'Auto Refinance',
    pagePosition: AUTO_LOAN_REFINANCE_OFFERS,
    linkPath: '/goals/detail/AUTO_REFI',
    currentModule: 'AUTO_REFINANCE_GOAL',
    actionName: 'Auto Loan - Refinance',
    vertical: AUTO_LOAN_VERTICAL,
    hideTooltip: false,
    cardTitle: 'POTENTIAL SAVING OPPORTUNITY',
    hasScoreIncreaseSimulation: false,
    hasSavingsSimulation: true,
    tooltip: ({ config, autoRefinanceCurrentLoadDetails }) => {
      const overViewAutoLoanHowCalculated =
        config?.disclaimers?.autoLoans?.overviewAutoLoanHowIsThisCalculated;
      return autoRefinanceOfferHowIsCalculatedTooltip(
        overViewAutoLoanHowCalculated,
        autoRefinanceCurrentLoadDetails,
      );
    },
    image: {
      src: AUTO_REFINANCE_IMAGE,
      alt: 'refinance auto loan',
    },
    partner: 'N/A',
    productKey: 'N/A',
  },
  DEBT_RELIEF: {
    moduleName: 'Debt Relief',
    clickType: 'Debt Relief',
    pagePosition: DEBT_RELIEF_ACTION,
    linkPath: '/goals/detail/DEBT_RELIEF',
    currentModule: 'DEBT_RELIEF',
    hideTooltip: true,
    actionName: 'Debt Relief',
    cardTitle: 'DEBT MANAGEMENT RECOMMENDATION',
    vertical: DEBT_RELIEF_VERTICAL,
    image: {
      src: DEBT_RELIEF_IMAGE,
      alt: 'debt relief expert"',
    },
    partner: 'N/A',
    productKey: 'N/A',
  },
  REWARDS: {
    moduleName: 'Rewards',
    clickType: 'Rewards',
    pagePosition: CC_REWARDS_ACTION,
    linkPath: '/goals/detail/REWARDS',
    currentModule: 'CC_REWARDS',
    hideTooltip: true,
    cardTitle: 'REWARD OPPORTUNITY',
    vertical: CREDIT_CARD_VERTICAL,
    actionName: 'Rewards',
    image: {
      src: FIND_REWARDS_IMAGE,
      alt: 'reward opportunity',
    },
    partner: 'N/A',
    productKey: 'N/A',
  },
  PL_PQ_OFFERS: {
    moduleName: 'PL Prequal',
    clickType: 'PL Prequal',
    pagePosition: NEW_PREQUAL_PERSONAL_LOAN,
    linkPath: '/goals/detail/PL_PQ_OFFERS',
    currentModule: 'PL_PREQUAL_OFFER_MODULE_GOAL',
    vertical: PERSONAL_LOAN_VERTICAL,
    actionName: 'Personal Loan',
    hideTooltip: true,
    cardTitle: 'POTENTIAL SAVING OPPORTUNITY',
    tooltip: null,
    image: {
      src: PERSONAL_LOAN_IMAGE,
      alt: 'Pay off debt',
    },
    hasScoreIncreaseSimulation: false,
    partner: 'N/A',
    productKey: 'N/A',
  },
  AUTO_INSURANCE_FOR_CREDIT_SCORE_INCREASE: {
    moduleName: 'Auto Insurance for Credit Score Increase',
    clickType: 'Auto Insurance for Credit Score Increase',
    pagePosition: AUTO_INSURANCE_FOR_CREDIT_SCORE_INCREASE,
    linkPath: '/goals/detail/AUTO_INSURANCE_FOR_CREDIT_SCORE_INCREASE',
    currentModule: 'AUTO_INSURANCE_FOR_CREDIT_SCORE_INCREASE',
    actionName: 'Auto Insurance For Credit Score Increase',
    vertical: AUTO_INSURANCE_VERTICAL,
    hideTooltip: true,
    cardTitle: 'MONEY SAVINGS OPPORTUNITY',
    image: {
      src: AUTO_INSURANCE_FOR_CREDIT_SCORE_INCREASE_IMAGE,
      alt: 'car insurance',
    },
    partner: 'N/A',
    productKey: 'N/A',
  },
  HOME_INSURANCE_FOR_NEW_HOME: {
    moduleName: 'Home Insurance For New Home',
    clickType: 'Home Insurance For New Home',
    pagePosition: HOME_INSURANCE,
    linkPath: '/goals/detail/HOME_INSURANCE_FOR_NEW_HOME',
    currentModule: 'HOME_INSURANCE',
    actionName: 'Home Insurance For New Home',
    vertical: HOME_INSURANCE_VERTICAL,
    hideTooltip: true,
    cardTitle: 'PROTECTION RECOMMENDATION',
    image: {
      src: HOME_INSURANCE_NEW_HOME_IMAGE,
      alt: 'home insurance',
    },
    partner: 'N/A',
    productKey: 'N/A',
  },
  AUTO_INSURANCE_FOR_NEW_AUTO_LOAN: {
    moduleName: 'Auto Insurance For New Auto Loan',
    clickType: 'Auto Insurance For New Auto Loan',
    pagePosition: AUTO_INSURANCE_FOR_NEW_AUTO_LOAN,
    linkPath: '/goals/detail/AUTO_INSURANCE_FOR_NEW_AUTO_LOAN',
    currentModule: 'AUTO_INSURANCE_FOR_NEW_AUTO_LOAN',
    actionName: 'Auto Insurance For New Auto Loan',
    vertical: AUTO_INSURANCE_VERTICAL,
    hideTooltip: true,
    cardTitle: 'MONEY SAVINGS OPPORTUNITY',
    image: {
      src: AUTO_INSURANCE_FOR_NEW_AUTO_LOAN_IMAGE,
      alt: 'save on car insurance',
    },
    partner: 'N/A',
    productKey: 'N/A',
  },
  AUTO_PURCHASE: {
    moduleName: 'Auto Loan',
    clickType: 'Auto Loan',
    pagePosition: AUTO_PURCHASE_ACTION,
    linkPath: '/offers/autoLoan',
    currentModule: 'AUTO_REFINANCE',
    actionName: 'Auto Loan - Purchase',
    vertical: AUTO_LOAN_VERTICAL,
    hideTooltip: true,
    cardTitle: 'POTENTIAL SAVING OPPORTUNITY',
    hasScoreIncreaseSimulation: false,
    hasSavingsSimulation: false,
    tooltip: null,
    image: {
      src: AUTO_PURCHASE_IMAGE,
      alt: 'refinance auto loan',
    },
    partner: 'N/A',
    productKey: 'N/A',
  },
  RENT_REPORTING: {
    moduleName: 'Report Your Rent Payments',
    clickType: 'Navigation',
    pagePosition: NEW_REPORT_RENT_PAYMENTS_ACTION,
    linkPath: '/rentPaymentReportingHome',
    currentModule: 'GOAL_RENT_REPORTING',
    actionName: 'Rent Reporting',
    hasScoreIncreaseSimulation: false,
    image: {
      src: 'https://csesamesecure.s3.amazonaws.com/s3adminportalmanager/product_manager///RentReportingAsset@3x.png',
      alt: 'Report Your Rent Payments',
    },
    cardTitle: 'REPORT YOUR RENT PAYMENTS',
    vertical: 'Rent Reporting',
    tooltip: null,
    hideTooltip: true,
    partner: 'N/A',
    productKey: 'N/A',
  },
  SESAME_CREDIT_BUILDER: {
    moduleName: 'Build Payment History',
    clickType: 'Navigation',
    pagePosition: NEW_PAYMENT_HISTORY_ACTION,
    linkPath: '/cash',
    currentModule: 'GOAL_SESAME_CREDIT_BUILDER',
    actionName: 'Credit Builder 2',
    hasScoreIncreaseSimulation: false,
    image: {
      src: 'https://csesamesecure.s3.amazonaws.com/s3adminportalmanager/product_manager///CreditBuilder@3x.png',
      alt: 'Build Payment History',
    },
    cardTitle: 'BUILD PAYMENT HISTORY',
    vertical: 'Credit Builder',
    tooltip: null,
    hideTooltip: true,
    partner: 'N/A',
    productKey: 'N/A',
  },
  // Action commented only for web -> I will be uncomented in the future
  // POTENTIAL_GRADE_INCREASE: {
  //   moduleName: 'Potential Grade Increase',
  //   clickType: 'Navigation',
  //   pagePosition: POTENTIAL_GRADE_INCREASE_ACTION,
  //   linkPath: '/dashboard',
  //   currentModule: 'POTENTIAL_GRADE_INCREASE',
  //   actionName: 'Potential Grade Increase',
  //   hasScoreIncreaseSimulation: false,
  //   image: {
  //     src: CS_3D_USAGE_DIAL,
  //     alt: 'Potential Grade Increase',
  //   },
  //   cardTitle: 'POTENTIAL GRADE INCREASE',
  //   vertical: '',
  //   tooltip: null,
  //   hideTooltip: true,
  //   partner: 'N/A',
  //   productKey: 'N/A',
  //   hide: true,
  // },
};

export default CardsConfig;
