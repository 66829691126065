import { NotificationCenter } from 'components/Notification/Notification';
import PageLoadingMask from 'components/masks/PageLoadingMask';
import { initOptVisitorId } from 'externals/_tracking/optimizely/optimizely.util';
import { isInvisibleUser, isPendingUser } from 'helpers/userHelper';
import { DisclaimerContextHoc } from 'hoc/DisclaimerContext.hoc';
import { OptimizelyHoc } from 'hoc/optimizely.hoc';
import { GoogleMapsEl } from 'partial/GoogleAutocomplete/GoogleAutocomplete';
import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import SessionManager from 'partial/SessionManager';
import LearnMore from 'partial/LearnMore/index';
import PreQualTerms from 'partial/PreQualTerms';
import { SignupError } from 'pages/SignupPage';
import {
  AffiliateSignup,
  AlertRoutes,
  AlexaPinView,
  AtmLocators,
  CashRoutes,
  ChangeEmail,
  Checkout,
  ClaimOfferRoutes,
  CreditCardPrequalificationFlow,
  CreditReportHybrid,
  CreditReportHybridAction,
  CreditReportHybridCheckout,
  CreditReportPrintLetter,
  CreditReportTU,
  CreditReportTUHybrid,
  CreditReportTULetter,
  CreditReportTUPrint,
  CreditLockLearnMore,
  CreditReportTabs,
  CreditReportTabsHybrid,
  CreditReports,
  CreditReports3B,
  DMCampaign,
  DisputeResolution,
  DisputeResolutionHybrid,
  DisputeResolutionPrintLetter,
  FactorsMultiRoute,
  GoalDetail,
  Goals,
  CreditManager,
  Home,
  HybridAtmLocators,
  LiveExperts,
  MarketplaceMultiRoute,
  MobileOptimizedGoalDetail,
  NeedHelpLoginRoutes,
  OcfMultiRoute,
  OneClickSignup1,
  OneClickSignup1Pixel,
  OneClickSignup1Verification,
  OneClickSignup2,
  PaymentPendingSubscriptionRoutes,
  PersonalLoanPrequalificationFlow,
  PreCheckout,
  PreCheckoutMobile,
  PreQualRentReporting,
  PrefilledSignup1,
  PrefilledSignup2,
  PremiumRoutes,
  PremiumStarterPack,
  Profile,
  PublicPremiumRoutes,
  QuarterlyReport,
  QuarterlyReportWebView,
  RentPaymentReportingHome,
  RentReportingDocumentsEmailLanding,
  RentReportingEmailLanding,
  Signup1,
  Signup2,
  SignupCashTC,
  PlusRoutes,
  ResetPassword,
  CFSBPrivacyDisclaimer,
  LegalDocumentModal,
  PreScreen,
  PrivacyDisclaimer,
  TermsDisclaimer,
  PremiumDiscountTerms,
  PremiumPlusTerms,
  LogoutPage,
  PlusCheckoutMobile,
  PlusUpgradeMobile,
  PlusDirectCheckout,
  PlusDirectCheckoutMobile,
  PlusPurchaseConfirmationLanding,
  PlusCreditToolsUnlocklandingController,
  PlusUpgradeAndConvert,
  PlusUpgradeAndConvertMobile,
  LoginPage,
  FatalError,
  JumioRouteHandler,
  UnsubscribeEmail,
  RentPaymentReporting,
  SesameCash2Enrollment,
  SesameCashEnrollment,
  Cb20Enrollment,
  MobileRouteHandler,
  MobileTransition,
  PublicTransferToPartner,
  ReactivatePremium,
  TransferToPartner,
  OffersDetailsModal,
  PremiumModal,
  PremiumPaymentPendingModal,
  PremiumReactivateCancelModal,
  GradeDialController,
  PostPremiumLandingModalController,
  PostPlusConvertModal,
  CashAccountModalController,
  PremiumReactivatePendingCancellationModal,
  TwoFactorModal,
  OfferDetails,
  SesameCash2EnrollmentSuccessRoutes,
  GlobalReviewController,
  DebtPayOffCalculator,
  OrderHistory,
  ScoreSimulator,
  OneClickSignupFingerPrintVerification,
} from 'routes/app.routes';
import { PrivateRoute } from 'PrivateRoute';
import { handleTopLevelError, initApp } from 'actions/app.actions';
import 'externals/_misc/window_helpers';
import { history } from 'helpers/history';
import { getOcfBaseUrl } from 'helpers/ocf.helpers';
import { _404 } from 'pages/404';
import { Header } from 'partial/Header/HeaderController';
import { OVERVIEW_URL } from 'types/mrph.routes.constants';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    const { dispatch } = this.props;
    initOptVisitorId();
    dispatch(initApp());
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(err) {
    const { dispatch } = this.props;
    this.setState({ hasError: true });
    dispatch(handleTopLevelError(err));
  }

  render() {
    if (
      this.props.fatalError !== null ||
      this.state.hasError ||
      (this.props.creditInfoError &&
        !isInvisibleUser(this.props.userInfo) &&
        !isPendingUser(this.props.userInfo) &&
        this.props.userInfo)
    ) {
      return (
        <div className="app-wrapper">
          <OptimizelyHoc>
            <BrowserRouter history={history}>
              <Header loggedIn={false} isFatalError={true}>
                <FatalError
                  history={history}
                  fatalError={this.props.fatalError}
                  creditInfoError={this.props.creditInfoError}
                />
              </Header>
            </BrowserRouter>
          </OptimizelyHoc>
        </div>
      );
    }
    return (
      <div className="app-wrapper">
        <OptimizelyHoc>
          <DisclaimerContextHoc>
            <BrowserRouter>
              <NotificationCenter coordinate={'top-right'}>
                {/*<DevTools/>*/}
                <Header>
                  <Suspense fallback={<PageLoadingMask />}>
                    <Switch>
                      <Route path={'/'} exact>
                        <Redirect to={'/login'} />
                      </Route>
                      <Route path={OVERVIEW_URL} component={Home} />
                      <Route path={'/login'} component={LoginPage} />
                      <Route path={'/logout'} component={LogoutPage} />
                      <Route path="/changeEmail" component={ChangeEmail} />
                      <Route path="/alexaPin" component={AlexaPinView} />
                      <Route
                        path={['/sesameCashEnrollment/:pageNumber']}
                        component={SesameCashEnrollment}
                      />
                      <Route
                        path="/gradeDial"
                        component={GradeDialController}
                      />
                      <Route
                        path="/cash2Enrollment"
                        component={SesameCash2Enrollment}
                      />

                      <Route
                        path="/cash2EnrollmentSuccess"
                        component={SesameCash2EnrollmentSuccessRoutes}
                      />
                      <Route
                        path="/cb20Enrollment"
                        component={Cb20Enrollment}
                      />
                      <Route path="/mobile" component={MobileRouteHandler} />
                      <Route
                        path="/jumio-submitted"
                        component={JumioRouteHandler}
                      />
                      <Route path="/download" component={MobileRouteHandler} />
                      <Route path="/needHelp" component={NeedHelpLoginRoutes} />
                      <Route
                        path="/forgotPassword/reset"
                        component={ResetPassword}
                      />
                      <Route
                        path="/actions/terms"
                        component={TermsDisclaimer}
                      />
                      <Route
                        path="/actions/privacy"
                        component={PrivacyDisclaimer}
                      />
                      <Route
                        path="/goals/detail/:selectedModule"
                        component={GoalDetail}
                      />
                      <Route path="/goals" component={Goals} />
                      <Route
                        path="/creditManager/:tabKey"
                        component={CreditManager}
                      />
                      <Route path="/creditManager" component={CreditManager} />
                      <Route path="/profile/:defaultTab" component={Profile} />
                      <Route path="/profile" component={Profile} />
                      <Route path="/cash" component={CashRoutes} />
                      <Route path="/alerts" component={AlertRoutes} />
                      <Route path="/premium" component={PremiumRoutes} />
                      <Route
                        path="/publicpremium"
                        component={PublicPremiumRoutes}
                      />
                      <Route path="/transfer" component={TransferToPartner} />
                      <Route
                        path="/creditReports/view/:reportId"
                        component={CreditReportTU}
                      />
                      <Route
                        path="/creditlock-learnmore"
                        component={CreditLockLearnMore}
                      />
                      <Route
                        path="/disputeManagement/printletter/:letterId"
                        component={DisputeResolutionPrintLetter}
                      />
                      <Route
                        path="/disputeManagement/:reportId/:discrepancyId/:letterId"
                        component={DisputeResolution}
                      />
                      <Route
                        path="/disputeManagement/:reportId/:discrepancyId"
                        component={DisputeResolution}
                      />
                      <Route
                        path="/disputeManagement"
                        component={DisputeResolution}
                      />
                      <Route
                        path="/disputeManagementHybrid"
                        component={DisputeResolutionHybrid}
                      />
                      <Route
                        path="/mobileGoalDetail"
                        component={MobileOptimizedGoalDetail}
                      />

                      <Route
                        path="/creditReports/view"
                        component={CreditReportTU}
                      />
                      <Route
                        path="/creditReports/print/:reportId"
                        component={CreditReportTUPrint}
                      />
                      <Route
                        path="/creditReports/letter/:letterId"
                        component={CreditReportTULetter}
                      />
                      <Route
                        path={'/creditReports/tabs/:reportId'}
                        component={CreditReportTabs}
                      />
                      <Route
                        path={'/creditReports/tabs'}
                        component={CreditReportTabs}
                      />
                      <Route
                        path="/creditreporthybridwriteletter/:reportId"
                        component={CreditReportPrintLetter}
                      />
                      <Route path="/3breport" component={CreditReports3B} />
                      <Route path="/creditReports" component={CreditReports} />
                      <Route path="/factors" component={FactorsMultiRoute} />
                      <Route
                        path="/public/transfer"
                        component={PublicTransferToPartner}
                      />
                      <Route
                        path="/rentReportingEmailLanding"
                        component={RentReportingEmailLanding}
                      />
                      <Route
                        path="/rentReportingDocumentsEmailLanding"
                        component={RentReportingDocumentsEmailLanding}
                      />
                      <Route path="/offers" component={MarketplaceMultiRoute} />
                      <Route
                        path="/preQualRentReporting"
                        component={PreQualRentReporting}
                      />
                      <Route path={'/checkout'} component={Checkout} />
                      <Route
                        path={[`/signup`, `/signup/legalDocs`]}
                        exact
                        render={() => <Signup1 {...this.props} />}
                      />
                      <Route
                        path={`/atm-locators`}
                        exact
                        render={() => <AtmLocators {...this.props} />}
                      />
                      <Route
                        path={'/signupError'}
                        render={() => <SignupError {...this.props} />}
                      />
                      <PrivateRoute
                        path={`/signup2`}
                        component={Signup2}
                        app={this.props.app}
                      />
                      <Route
                        path={`/signupCashTC`}
                        render={() => <SignupCashTC {...this.props} />}
                      />
                      <Route
                        path={`/oneClickSignup`}
                        exact
                        render={() => <OneClickSignup1 {...this.props} />}
                      />
                      <Route
                        path={`/oneClickSignup/verify`}
                        exact
                        render={() => (
                          <OneClickSignup1Verification {...this.props} />
                        )}
                      />
                      <Route
                        path={`/oneClickSignup/pixel`}
                        exact
                        render={() => <OneClickSignup1Pixel {...this.props} />}
                      />
                      <Route
                        path={`/oneClickSignup/cont`}
                        exact
                        render={() => (
                          <OneClickSignupFingerPrintVerification
                            {...this.props}
                          />
                        )}
                      />
                      <Route
                        path={`/oneClickSignup2`}
                        render={() => <OneClickSignup2 {...this.props} />}
                      />
                      <Route
                        path={`/prefilledSignup`}
                        exact
                        render={() => <PrefilledSignup1 {...this.props} />}
                      />
                      <PrivateRoute
                        path={`/prefilledSignup2`}
                        component={PrefilledSignup2}
                        app={this.props.app}
                      />
                      <Route
                        path={`/affiliateSignUp`}
                        render={() => <AffiliateSignup {...this.props} />}
                      />
                      <Route
                        path="/precheckoutmobile"
                        component={PreCheckoutMobile}
                      />
                      <Route
                        path="/creditreporthybrid"
                        component={CreditReportHybrid}
                      />
                      <Route
                        path="/creditreporthybridcheckout"
                        component={CreditReportHybridCheckout}
                      />

                      {/*visual for report*/}
                      <Route
                        path="/creditReportTUHybrid"
                        component={CreditReportTUHybrid}
                      />
                      <Route
                        path="/creditReportTabsHybrid"
                        component={CreditReportTabsHybrid}
                      />
                      <Route
                        path="/hybridConvertTrial"
                        component={CreditReportHybridAction}
                      />
                      <Route
                        path="/hybridPurchaseReport"
                        component={CreditReportHybridAction}
                      />
                      <Route
                        path="/hybridAtmLocators"
                        component={HybridAtmLocators}
                      />
                      <Route
                        path="/paymentPendingSubscription"
                        component={PaymentPendingSubscriptionRoutes}
                      />
                      <Route path="/claimOffer" component={ClaimOfferRoutes} />
                      <Route
                        path="/precheckout/:planName"
                        component={PreCheckout}
                      />
                      <Route path={'/precheckout'} component={PreCheckout} />
                      <Route
                        path={'/rentPaymentReporting'}
                        component={RentPaymentReporting}
                      />
                      <Route
                        path={'/rentPaymentReportingHome'}
                        component={RentPaymentReportingHome}
                      />
                      <Route path={'/liveexperts'} component={LiveExperts} />
                      {/* OCF */}
                      <Route
                        path={`${getOcfBaseUrl()}`}
                        component={OcfMultiRoute}
                      />
                      <Route
                        path={'/public/unsubscribe'}
                        component={UnsubscribeEmail}
                      />
                      <Route
                        path={`/dmCampaign`}
                        render={() => <DMCampaign {...this.props} />}
                      />
                      <Route
                        path="/premiumStarterPack"
                        component={PremiumStarterPack}
                      />
                      <Route
                        path="/actionPlan"
                        component={PremiumStarterPack}
                      />
                      <Route
                        path="/mobileTransition"
                        component={MobileTransition}
                      />
                      <Route
                        path="/reactivatePremium"
                        component={ReactivatePremium}
                      />
                      <Route
                        path="/QuarterlyReport"
                        component={QuarterlyReport}
                      />
                      <Route
                        path="/QuarterlyReportWebView"
                        component={QuarterlyReportWebView}
                      />
                      <Route
                        path="/debtPayOffCalculator"
                        component={DebtPayOffCalculator}
                      />
                      <Route path="/plus" component={PlusRoutes} />

                      <Route
                        path="/pluscheckout"
                        component={PlusCheckoutMobile}
                      />
                      <Route
                        path="/plusupgrade"
                        component={PlusUpgradeMobile}
                      />
                      <Route
                        path="/plus_directcheckout"
                        component={PlusDirectCheckout}
                      />
                      <Route
                        path="/plusDirectCheckoutMobile"
                        component={PlusDirectCheckoutMobile}
                      />
                      <Route
                        path="/hybridConvertTrialPlus3B"
                        component={PlusUpgradeAndConvertMobile}
                      />
                      <Route
                        path="/globalReview"
                        component={GlobalReviewController}
                      />
                      <Route path="/orderHistory" component={OrderHistory} />
                      <Route
                        path="/scoreSimulator"
                        component={ScoreSimulator}
                      />
                      <Route component={_404} />
                    </Switch>
                  </Suspense>
                </Header>
                <Suspense fallback={''}>
                  <Switch>
                    <Route
                      path="*/offers/details/:offerId"
                      component={OffersDetailsModal}
                    />

                    <Route
                      path="*/offerDetails/:vertical/:offerId"
                      component={OfferDetails}
                    />

                    <Route path="*/offerDetails" component={OfferDetails} />
                    <Route
                      path="*/prequalFlow/:providerId"
                      component={PersonalLoanPrequalificationFlow}
                    />
                    <Route
                      path="*/credit-card-pre-approval/:issuer/:productId?"
                      component={CreditCardPrequalificationFlow}
                    />
                    <Route
                      path="*/cashAccount/:type/:transferMethod?"
                      component={CashAccountModalController}
                    />
                    <Route path="*/prescreen" component={PreScreen} />
                    <Route path="*/privacy" component={PrivacyDisclaimer} />
                    <Route
                      path="*/cfsb-privacy"
                      component={CFSBPrivacyDisclaimer}
                    />
                    <Route path="*/terms" component={TermsDisclaimer} />
                    <Route path="*/legalDocs" component={LegalDocumentModal} />
                    <Route path="*/learnMore" component={LearnMore} />
                    <Route
                      path="*/preQualProgamOptInReminderTerms"
                      component={PreQualTerms}
                    />
                    <Route
                      path="*/premiumdiscountterms"
                      component={PremiumDiscountTerms}
                    />
                    <Route
                      path="*/premiumplustrial"
                      component={PremiumPlusTerms}
                    />
                    <Route
                      path="*/premium/postSignupOnboard"
                      component={PostPremiumLandingModalController}
                    />
                    <Route
                      path="*/postPlusConvertNow"
                      component={PostPlusConvertModal}
                    />
                    <Route path="*/2fa" component={TwoFactorModal} />
                    <Route
                      path="*/premiumPaymentPending"
                      component={PremiumPaymentPendingModal}
                    />
                    <Route
                      path="*/premiumReactivateCancel"
                      component={PremiumReactivateCancelModal}
                    />
                    <Route
                      path="*/premiumReactivatePendingCancellation"
                      component={PremiumReactivatePendingCancellationModal}
                    />
                    <Route
                      path="*/premiumOpts/:pageName"
                      component={PremiumModal}
                    />
                    <Route
                      path="*/purchaseConfirmation"
                      component={PlusPurchaseConfirmationLanding}
                    />
                    <Route
                      path="*/creditToolsUnlocks"
                      component={PlusCreditToolsUnlocklandingController}
                    />
                    <Route
                      path="*/plusUpgradeAndConvert"
                      component={PlusUpgradeAndConvert}
                    />
                  </Switch>
                </Suspense>
                <GoogleMapsEl />
              </NotificationCenter>
              <SessionManager {...this.props} />
            </BrowserRouter>
          </DisclaimerContextHoc>
        </OptimizelyHoc>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    fatalError: state.app.fatalError,
    userInfo: state.userInfo.userInfo,
    userInfoError: state.userInfo.userInfoError,
    creditInfoError: state.creditInfo.creditInfoError,
  };
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App };
