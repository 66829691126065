export const ONEBUREAU = 'ONEBUREAU';
export const THREEBUREAU = 'THREEBUREAU';
export const ONEBUREAU_FREE_TRIAL = 'ONEBUREAU_FREE_TRIAL';

export const ONEBUREAU_FULL = 'ONEBUREAU_FULL';
export const THREEBUREAU_FREE_TRIAL = 'THREEBUREAU_FREE_TRIAL';
export const THREEBUREAU_FULL = 'THREEBUREAU_FULL';
export const ONE_TIME_REPORT = 'ONE_TIME_REPORT';
export const UPGRADE_3B = 'UPGRADE_3B';
export const UPGRADE_LATER = 'UPGRADE_LATER';
export const UPGRADE_NOW = 'UPGRADE_NOW';
export const CHANGE_WINDOW_NOW = 'NOW';
export const CHANGE_WINDOW_LATER = 'BILL_DATE';
export const DOWNGRADE_3B = 'DOWNGRADE_3B';
export const TERM_END = 'TERM_END';
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';

export const UPGRADE_3B_PLAN_TITLE = 'Monthly 3-bureau Report';
export const UPGRADE_ONE_TIME_REPORT_PLAN_TITLE = 'One time 3-bureau Report';

//Plus 3B without trial
export const WEB_PLUS_3B_1999 = 'WEB_PLUS_3B_1999';
export const WEB_PLUS_3B_2499 = 'WEB_PLUS_3B_2499';
export const IOS_PLUS_3B_2499 = 'IOS_PLUS_3B_2499';
export const IOS_PLUS_3B_1999 = 'IOS_PLUS_3B_1999';
export const AND_PLUS_3B_1999 = 'AND_PLUS_3B_1999';
export const AND_PLUS_3B_2499 = 'AND_PLUS_3B_2499';

//Plus 3B with trial
export const WEB_PLUS_3B_FTG_1999 = 'WEB_PLUS_3B_FTG_1999';
export const WEB_PLUS_3B_FTG_2499 = 'WEB_PLUS_3B_FTG_2499';
export const IOS_PLUS_3B_FTG_1999 = 'IOS_PLUS_3B_FTG_1999';
export const IOS_PLUS_3B_FTG_2499 = 'IOS_PLUS_3B_FTG_2499';
export const AND_PLUS_3B_FTG_1999 = 'AND_PLUS_3B_FTG_1999';
export const AND_PLUS_3B_FTG_2499 = 'AND_PLUS_3B_FTG_2499';
//Plus 1B without trial
export const WEB_PLUS_1B_1299 = 'WEB_PLUS_1B_1299';
export const IOS_PLUS_1B_1299 = 'IOS_PLUS_1B_1299';
export const AND_PLUS_1B_1299 = 'AND_PLUS_1B_1299';
export const WEB_PLUS_1B_999 = 'WEB_PLUS_1B_999';
export const IOS_PLUS_1B_999 = 'IOS_PLUS_1B_999';
export const AND_PLUS_1B_999 = 'AND_PLUS_1B_999';
//Plus 1B with trial
export const WEB_PLUS_1B_FTG_1299 = 'WEB_PLUS_1B_FTG_1299';
export const IOS_PLUS_1B_FTG_1299 = 'IOS_PLUS_1B_FTG_1299';
export const AND_PLUS_1B_FTG_1299 = 'AND_PLUS_1B_FTG_1299';
export const WEB_PLUS_1B_FTG_999 = 'WEB_PLUS_1B_FTG_999';
export const IOS_PLUS_1B_FTG_999 = 'IOS_PLUS_1B_FTG_999';
export const AND_PLUS_1B_FTG_999 = 'AND_PLUS_1B_FTG_999';

export const TU_CREDIT_PREMIUM_3B_QTR_995 = 'tu_credit_premium_3b_qtr_995';
export const TU_CREDIT_PREMIUM_3B_QTR_1995 = 'tu_credit_premium_3b_qtr_1995';
export const TU_CREDIT_PREMIUM_3B_QTR_1495 = 'tu_credit_premium_3b_qtr_1495';
export const TU_CREDIT_PREMIUM_3B_QTR_2495 = 'tu_credit_premium_3b_qtr_2495';
export const TU_CREDIT_PREMIUM_3B_QTR_2995 = 'tu_credit_premium_3b_qtr_2995';

export const PLUS_MENU_ITEM_LABEL = 'CreditSesame+';
export const PLUS_2499_NAV_URL = '/plus?opt_var=variation6';
export const PLUS_REACTIVATE_2499_NAV_URL =
  '/plus/reactivate?opt_var=variation6';

export const PLUS_LABEL_MAP = {
  CS: {
    short: { title: 'CS+', width: 'width45px' },
    long: { title: 'CreditSesame+' },
  },
  PREMIUM: {
    short: { title: 'PREMIUM', width: 'width85px' },
    long: { title: 'PREMIUM' },
  },
  PREMIUM_CR: {
    short: { title: 'Premium', width: 'width85px' },
    long: { title: 'Premium' },
  },
};

export const DEFAULT_PLAN_PLUS = {
  planName: 'Plus',
  menuPlanName: 'Plus',
  pricing: '--.--',
  price: null,
  isSubscription: true,
  bullets: [],
};
