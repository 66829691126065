/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import {
  CREDIT_CARD_VERTICAL_CARD,
  PERSONAL_LOAN_VERTICAL_CARD,
  AUTO_LOAN_VERTICAL_CARD,
  HOME_LOAN_VERTICAL_CARD,
  AUTO_INSURANCE_VERTICAL_CARD,
  HOME_INSURANCE_VERTICAL_CARD,
  LIFE_INSURANCE_VERTICAL_CARD,
  BUSINESS_LOANS_VERTICAL_CARD,
} from 'types/marketplaceTiles.constants';
import { getVriRandom, setVriRandom } from 'helpers/localstorage';
import { getOpenHomeLoans } from 'helpers/credit_info';
import { useFlag } from 'hook/flag.hook';
import { isVariationActive } from 'helpers/optimizely.helper';

const tilesDefaultOrder = [
  CREDIT_CARD_VERTICAL_CARD,
  PERSONAL_LOAN_VERTICAL_CARD,
  AUTO_LOAN_VERTICAL_CARD,
  HOME_LOAN_VERTICAL_CARD,
  AUTO_INSURANCE_VERTICAL_CARD,
  HOME_INSURANCE_VERTICAL_CARD,
  LIFE_INSURANCE_VERTICAL_CARD,
  BUSINESS_LOANS_VERTICAL_CARD,
];

export const useOfferPageContainer = () => {
  const modules = [];

  modules.push(
    {
      module: 'FEATURED_AD',
      offers: [
        {
          offerType: 'FEATURED_AD',
          location: 'OFFERS',
          position: 'TOP',
          tagText: 'RECOMMENDED FOR YOU',
          customClass: 'margB48 m-margTB16',
        },
      ],
    },
    {
      module: 'MARKETPLACE_OFFER_TILE_MODULE',
    },
  );

  return { modules };
};

export const useInsurancePageContainer = () => {
  const modules = [];

  modules.push({
    module: 'MARKETPLACE_INSURANCE_TILE_MODULE',
  });

  return { modules };
};

export const useVri = (tileOffers, userInfo) => {
  const [finalOfferTiles, setFinalOfferTiles] = useState([]);

  const vriOrder = userInfo?.vri?.verticals;

  const vri2Exp = useFlag('vri2');
  const vri2ExpVar2 = isVariationActive(vri2Exp, 'var2');

  const loading = vri2Exp.loading || !userInfo || !finalOfferTiles.length > 0;

  useEffect(() => {
    if (userInfo && vri2Exp.loading === false) {
      if (vri2ExpVar2) {
        let savedOrder = getVriRandom();
        let tilesOrderChanged = tilesDefaultOrder.slice();
        if (!savedOrder) {
          var currentIndex = tilesOrderChanged.length,
            randomIndex;

          while (currentIndex !== 0) {
            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [tilesOrderChanged[currentIndex], tilesOrderChanged[randomIndex]] =
              [tilesOrderChanged[randomIndex], tilesOrderChanged[currentIndex]];
          }

          setVriRandom(JSON.stringify(tilesOrderChanged));
        } else {
          tilesOrderChanged = JSON.parse(savedOrder);
        }
        setFinalOfferTiles(copySort(tilesOrderChanged, tileOffers));
      } else if (vriOrder) {
        setFinalOfferTiles(copySort(vriOrder, tileOffers, true));
      } else {
        setFinalOfferTiles(copySort(tilesDefaultOrder, tileOffers));
      }
    }
  }, [vri2Exp.loading, userInfo]);

  return { finalOfferTiles, loadingVri: loading };
};

const copySort = (order, tilesToOrder, vriActive = false) => {
  const itemPositions = {};
  for (const [index, id] of order.entries()) {
    itemPositions[vriActive ? id.verticalName : id] = index;
  }
  return tilesToOrder.sort(
    (a, b) =>
      itemPositions[vriActive ? a.vriName : a.cardName] -
      itemPositions[vriActive ? b.vriName : b.cardName],
  );
};

export const getHomeLoanLandingPageURL = creditInfo => {
  const hasHomeLoanDebt = getOpenHomeLoans(creditInfo).length > 0;

  return hasHomeLoanDebt
    ? '/offers/homeLoan/infoAndTools'
    : '/offers/homeLoan/new';
};
