import 'whatwg-fetch';
import 'core-js';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { store } from 'helpers/store';
import { App } from 'customAppsPath/App';
import 'sanitize.css/sanitize.css';
import 'text-security/dist/text-security-disc.min.css';
import 'antd/dist/antd.min.css';
import './style/main.scss';
import './externals/_tracking/braze/braze.snippet';
import './externals/_tracking/gtm.snippet';
import './externals/_tracking/inspectlet.snippet';
import './externals/_tracking/optimizely/optimizely.snippet';
import './externals/_tracking/tvScientific/pixel_to_tvscientific.snippet';
import './externals/_misc/ada.snippet';
import './externals/_misc/recurly.snippet';
import './externals/_misc/data_dome_listeners';
import './externals/_misc/cognigy.snippet';
import './externals/_tracking/mixpanel/mixpanel.snippet';
import './externals/_misc/dataDome.snippet';
import './externals/_tracking/config-iovation.snippet';

try {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  window.addEventListener('resize', () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });

  render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('root'),
  );
} catch (e) {
  window.appInitializationError = e;
}
