import {
  isMobileByOS,
  isMobileTabletByWidth,
} from 'externals/_tracking/dom/navigator';
import { MAIN_NAV } from 'externals/_tracking/types/clickEventTypes.constants';
import { CLICK } from 'externals/_tracking/types/eventTypes.constants';
import { hasAtmLocatorsHybridUrl } from 'helpers/atmLocatorsHelper';
import {
  hasRecurlyCreditReportTUHybridUser,
  hasRecurlyHybridUser,
  isCreditReportHybridPage,
} from 'helpers/creditReportHelper';
import { featureVisited } from 'helpers/highlightFeatures.helper';
import { isFromNativeApp } from 'helpers/session';
import { getHideHeader } from 'helpers/sessionstorage';
import {
  isPremium,
  isPreviouslyPremium,
  isTrialEligible,
} from 'helpers/userHelper';
import { useMyPendingActions } from 'hook/actions.hook';
import { useCMAlerts } from 'hook/cmAlerts.hook';
import { usePremiumAlerts } from 'hook/csidAlerts.hook';
import { useEventHandler } from 'hook/event.hook';
import { useFlag } from 'hook/flag.hook';
import 'partial/Header/HeaderLoggedIn.scss';
import HeaderView from 'partial/Header/components/HeaderView';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  CREDIT_CARD_VERTICAL,
  PERSONAL_LOAN_VERTICAL,
} from 'types/marketplaceTiles.constants';
import {
  BANKING_VERTICAL,
  MORTGAGE_VERTICAL,
  SUBSCRIPTION_VERTICAL,
} from 'types/mrph.vertical.constants';
import { getPublicSiteUrl } from 'helpers/env';
import { useMyGoalsCounter } from '../../hook/goals.hooks';
import { usePlusLaunch } from 'hook/plusLaunch.hook';
import { useTUCreditLockValidation } from 'pages_CreditLock_hooks/creditLockValidation.hook';
import { useCreditManagerVisited } from 'pages/CreditManager/hooks/CreditManager.hook';
import { useCreditReportModules } from 'pages/CreditReportTabs/hooks/creditReportModules.hook';
import { useSubscriptionLandingUrl } from 'hook/subscriptionLandingUrl.hook';

function HeaderController({ children, ...props }) {
  const isHideHeader = getHideHeader() === 'true';
  const history = useHistory();
  const dispatch = useDispatch();
  const pathname = history?.location?.pathname;
  const {
    /*fullyInitialized,*/ userInfo,
    numberOfActions,
    secureLoggedIn,
    ocfLoggedIn,
    invisibleUserData,
    creditInfo,
    forceHideMenuBar,
    menuType,
    forceMobileHideMenuBar,
    isSignupLoading,
    goals,
    sesameCash,
    headerMenuOpts,
    firstLoginOfTheMonth,
    config,
    creditReportTabContainer,
  } = useSelector(state => {
    return {
      numberOfActions: state.actions.numberOfActions,
      fullyInitialized: state.app.fullyInitialized,
      userInfo: state.userInfo.userInfo,
      secureLoggedIn: state.app.loggedIn,
      ocfLoggedIn: state.app.ocfLoggedIn,
      invisibleUserData: state.signup.invisibleUserData,
      creditInfo: state.creditInfo.creditInfo,
      forceHideMenuBar: state.app.forceHideMenuBar,
      forceMobileHideMenuBar: state.app.forceMobileHideMenuBar,
      menuType: state.app.menuType,
      headerMenuOpts: state.app.headerMenuOpts,
      firstLoginOfTheMonth: state.app.loginResponse?.firstLoginOfTheMonth,
      isSignupLoading: state.signup.loading,
      ocfInfo: state.ocfInfo.ocfInfo,
      page: state.page,
      goals: state.goals.goals,
      sesameCash: state.sesameCash,
      config: state.config?.config,
      creditReportTabContainer: state.creditReportTabContainer,
    };
  });

  const isGoalDetailAction = pathname?.includes('goals/detail');
  const hideMenuGoalDetailAction = isGoalDetailAction && isFromNativeApp();

  const isOcfRole = ocfLoggedIn;
  const loggedIn = secureLoggedIn || ocfLoggedIn;
  const isTabletMode = window.innerWidth < 1279;
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(isTabletMode);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [maskOn, setMaskOn] = useState(isTabletMode);
  const [scrollStatus, setScrollStatus] = useState('initial');
  let location = useLocation();
  const isRecurlyHybridUser = hasRecurlyHybridUser({ location });
  const isRecurlyCreditReportYUHybridUser = hasRecurlyCreditReportTUHybridUser({
    location,
  });
  const eventHandler = useEventHandler();

  const menuTermsPrivacyUrls = {
    termsOfUseUrl: config?.termsandConditionsModalUrls?.termsOfUse,
    privacyPolicyUrl: config?.termsandConditionsModalUrls?.privacyPolicy,
  };

  const isSignup =
    (location.pathname.indexOf('signup') !== -1 ||
      location.pathname.indexOf('oneClickSignup') !== -1 ||
      location.pathname.indexOf('prefilledSignup') !== -1 ||
      location.pathname?.toLowerCase().includes('affiliatesignup') ||
      location.pathname.toLowerCase().indexOf('dmcampaign') !== -1) &&
    !location.pathname?.toLowerCase().includes('signupcashtc');
  const isSignupError = location.pathname.indexOf('signupError') !== -1;
  const isLogin = location.pathname.indexOf('login') !== -1;
  const isSesameCashEnrollment =
    location.pathname.indexOf('sesameCash') !== -1 &&
    !sesameCash?.showMenuBarOnEnrollment;
  const isPublic = location.pathname.indexOf('changeEmail') !== -1;
  const isUnsubscribe = location.pathname.indexOf('unsubscribe') !== -1;
  const isOcfPage = location.pathname.indexOf('ocf') > -1;
  const isPremiumStarterPage =
    location.pathname.indexOf('premiumStarterPack') > -1;
  const isActionPlanPage = location.pathname.indexOf('actionPlan') > -1;
  const creditReportModules =
    useCreditReportModules({
      dispatch,
      history,
      userInfo,
      creditReportTabContainer,
    }) || {};
  const { hasCreditLock } = creditReportModules;
  const {
    config: { enabled: creditLockEnabled },
  } = useTUCreditLockValidation({ userInfo });

  const showMenuBar =
    !(
      isSignup ||
      isLogin ||
      isSesameCashEnrollment ||
      isPremiumStarterPage ||
      hideMenuGoalDetailAction
    ) && !props.isFatalError;

  const showLogo = isSignup || isSesameCashEnrollment || isPremiumStarterPage;
  const isActionsModuleSelected = location.pathname.search(/actions\/\w+/) > -1;

  const navbarShowing =
    loggedIn &&
    !isSignup &&
    !isSesameCashEnrollment &&
    !isPremiumStarterPage &&
    !props.isFatalError;

  const { unreadAlertCount: unreadCMAlertsCount } = useCMAlerts();
  const { getUnreadPremiumAlertCount } = usePremiumAlerts();
  const totalUnreadCount = unreadCMAlertsCount + getUnreadPremiumAlertCount();
  const notifications = totalUnreadCount > 0;
  const isPremiumUser = isPremium(userInfo);
  const isBoosterWaitlistPage =
    invisibleUserData && invisibleUserData.expActive;

  const { showPendingActions } = useMyPendingActions();
  const { showGoalsCounter } = useMyGoalsCounter();

  const premiumDataConfig = useFlag('premium_data');
  const isActionsModuleSelectedPage = isActionsModuleSelected && isMobileByOS();
  const creditManagerVisited = useCreditManagerVisited({
    firstLoginOfTheMonth,
    userInfo,
  });
  const plusLaunch = usePlusLaunch({ userInfo });
  const isPreviouslyPremiumPlusFlow =
    plusLaunch?.plusLaunchEnabled && isPreviouslyPremium(userInfo);
  const { subscriptionLandingUrl, reactivateUrl } = useSubscriptionLandingUrl();
  const hideWalletProtection = config?.menu?.walletProtection?.hide;
  const onClickReactivatePlus = () => {
    let eventData = {
      'Click Type': MAIN_NAV,
      'Nav Item': 'Reactivate',
      Vertical: SUBSCRIPTION_VERTICAL,
    };
    eventHandler(CLICK, eventData);
    closeMobileMenu();
    if (isTrialEligible(userInfo)) {
      history.push(subscriptionLandingUrl);
    } else {
      history.push(reactivateUrl);
    }
  };

  const removeHeader =
    isPremiumStarterPage ||
    isActionsModuleSelectedPage ||
    isCreditReportHybridPage({ location }) ||
    hasAtmLocatorsHybridUrl({ location });

  window.onresize = () => {
    if (window.innerWidth < 1279) {
      setIsMenuCollapsed(true);
      setMaskOn(true);
    } else {
      setIsMenuCollapsed(false);
      setMaskOn(false);
    }
    if (!isMobileTabletByWidth()) {
      setIsMobileMenuOpen(false);
    }
  };

  let logoLink = '/signup';
  if (isSignup) {
    logoLink = getPublicSiteUrl();
  } else if (loggedIn && !isSignup) {
    logoLink = '/dashboard';
  } else if (isSignupError || isPublic) {
    logoLink = '';
  } else if (!loggedIn && !isSignup) {
    logoLink = '/login';
  } else if (isBoosterWaitlistPage) {
    logoLink = '/signup';
  } else {
    logoLink = `${location.pathname}`;
  }

  /* disable main scroll when mobile menu is expanded */
  useEffect(() => {
    if (isMobileTabletByWidth()) {
      if (isMobileMenuOpen && scrollStatus !== 'hide') {
        document.body.style.top = `-${window.scrollY}px`;
        document.body.style.overflowY = 'hidden';
        document.body.style.position = 'fixed';
        setScrollStatus('hide');
      } else if (!isMobileMenuOpen && scrollStatus !== 'show') {
        setNormalBodyScroll();
        setScrollStatus('show');
      }
    } else if (scrollStatus !== 'desktop') {
      setNormalBodyScroll();
      setScrollStatus('desktop');
    }
    //eslint-disable-next-line
  }, [isMobileMenuOpen]);

  function collapseMenu() {
    setIsMenuCollapsed(!isMenuCollapsed);
    let eventData = {
      'Click Type': MAIN_NAV,
      'Nav Item': 'Toggle',
    };
    eventHandler(CLICK, eventData);
  }

  function onMenuToggle(e) {
    e.preventDefault();
    setIsMobileMenuOpen(!isMobileMenuOpen);
    let eventData = {
      'Click Type': MAIN_NAV,
      'Nav Item': 'Toggle',
    };
    eventHandler(CLICK, eventData);
  }

  function closeMobileMenu() {
    if (isMobileMenuOpen) {
      setIsMobileMenuOpen(false);
    }
  }

  function closeDesktopMenu() {
    if (!isMenuCollapsed) {
      setIsMenuCollapsed(true);
    }
  }

  function menuItemClicked(navItem, highlightedFeatureName, vertical) {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    if (maskOn) {
      setIsMenuCollapsed(true);
    }
    if (navItem) {
      let eventData = {
        'Click Type': MAIN_NAV,
        'Nav Item': navItem,
      };
      if (vertical) {
        eventData.Vertical = vertical;
      }
      eventHandler(CLICK, eventData);
    }

    if (highlightedFeatureName) {
      featureVisited(highlightedFeatureName);
    }
  }

  const getVerticalGivenURL = () => {
    const URLS = {
      [subscriptionLandingUrl]: SUBSCRIPTION_VERTICAL,
      '/offers/creditCardCategories': CREDIT_CARD_VERTICAL,
      '/offers/homeLoan/infoAndTools': MORTGAGE_VERTICAL,
      '/cash': BANKING_VERTICAL,
      '/offers/personalLoanPrequal/result': PERSONAL_LOAN_VERTICAL,
    };
    if (pathname.includes('/ocf/credit-cards')) {
      return CREDIT_CARD_VERTICAL;
    }
    return URLS[pathname];
  };

  function disclosureItemClicked(clickType) {
    setIsMobileMenuOpen(false);
    if (maskOn) {
      setIsMenuCollapsed(true);
    }
    getVerticalGivenURL();
    let eventData = {
      'Click Type': clickType,
    };
    const vertical = getVerticalGivenURL();
    if (vertical) {
      eventData.Vertical = vertical;
    }
    eventHandler(CLICK, eventData);
  }

  function setNormalBodyScroll() {
    const scrollY = document.body.style.top;
    document.body.style.top = '';
    document.body.style.overflowY = '';
    document.body.style.position = '';
    if (scrollY) {
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    }
  }

  return (
    <HeaderView
      disclosureItemClicked={disclosureItemClicked}
      menuItemClicked={menuItemClicked}
      collapseMenu={collapseMenu}
      onMenuToggle={onMenuToggle}
      numberOfGoals={goals?.length}
      numberOfActions={numberOfActions}
      eventHandler={eventHandler}
      closeDesktopMenu={closeDesktopMenu}
      closeMobileMenu={closeMobileMenu}
      navbarShowing={navbarShowing && !forceHideMenuBar}
      notifications={notifications}
      showLogo={showLogo}
      isSignup={isSignup}
      isUnsubscribe={isUnsubscribe}
      isPublic={isPublic}
      loggedIn={loggedIn}
      logoLink={logoLink}
      isPremiumUser={isPremiumUser}
      showMenuBar={showMenuBar && !forceHideMenuBar}
      isMenuCollapsed={isMenuCollapsed || props.isFatalError}
      pathname={location.pathname}
      maskOn={maskOn}
      totalUnreadCount={totalUnreadCount}
      userInfo={userInfo}
      creditInfo={creditInfo}
      isOcfRole={isOcfRole}
      isMobileMenuOpen={isMobileMenuOpen}
      isBoosterWaitlistPage={isBoosterWaitlistPage}
      menuType={menuType}
      headerMenuOpts={headerMenuOpts}
      forceHideMenuBar={forceHideMenuBar}
      forceMobileHideMenuBar={forceMobileHideMenuBar}
      isRecurlyHybridUser={isRecurlyHybridUser}
      isRecurlyCreditReportYUHybridUser={isRecurlyCreditReportYUHybridUser}
      extraProps={{ isSignupLoading }}
      isOcfPage={isOcfPage}
      isHideHeader={isHideHeader}
      isHidingHeaderCompletely={
        forceHideMenuBar && menuType === 'HIDE_COMPLETELY'
      }
      removeHeader={removeHeader}
      isPremiumStarterPage={isPremiumStarterPage}
      isActionsModuleSelectedPage={isActionsModuleSelectedPage}
      isActionPlanPage={isActionPlanPage}
      showPendingActions={showPendingActions()}
      showGoalsCounter={showGoalsCounter()}
      history={history}
      premiumDataConfig={premiumDataConfig}
      secureLoggedIn={secureLoggedIn}
      isPreviouslyPremiumPlusFlow={isPreviouslyPremiumPlusFlow}
      onClickReactivatePlus={onClickReactivatePlus}
      plusLaunch={plusLaunch}
      hideWalletProtection={hideWalletProtection}
      creditLockEnabled={creditLockEnabled && hasCreditLock}
      creditManagerVisited={creditManagerVisited}
      menuTermsPrivacyUrls={menuTermsPrivacyUrls}
    >
      {children}
    </HeaderView>
  );
  // }
}

export { HeaderController as Header };
