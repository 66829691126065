export const GO_TO_FORM = 'GO_TO_FORM';
export const SWITCH_TRANSFER_DIRECTION = 'SWITCH_TRANSFER_DIRECTION';
export const SELECT_EXTERNAL_ACCOUNT = 'SELECT_EXTERNAL_ACCOUNT';
export const GO_TO_CONFIRM_TRANSFER = 'GO_TO_CONFIRM_TRANSFER';
export const TOGGLE_REMOVE_EXTERNAL_ACCOUNTS =
  'TOGGLE_REMOVE_EXTERNAL_ACCOUNTS';
export const SELECT_ACCOUNT_TO_REMOVE = 'SELECT_ACCOUNT_TO_REMOVE';
export const CANCEL_REMOVING_ACCOUNT = 'CANCEL_REMOVING_ACCOUNT';
export const GO_TO_VALIDATING = 'GO_TO_VALIDATING';
export const GO_TO_SUCCESS = 'GO_TO_SUCCESS';
export const PLAID_COMPLETE = 'PLAID_COMPLETE';
export const CLEAR_TRANSFER_FORM = 'CLEAR_TRANSFER_FORM';
export const TOGGLE_ZERO_BALANCE_MODAL = 'TOGGLE_ZERO_BALANCE_MODAL';
export const TOGGLE_INSUFFICIENT_BALANCE_MODAL =
  'TOGGLE_INSUFFICIENT_BALANCE_MODAL';

export const TRANSFER_FORM = 'TRANSFER_FORM';
export const CONFIRM_TRANSFER = 'CONFIRM_TRANSFER';
export const SHOW_ACCOUNT_SELECTION = 'SHOW_ACCOUNT_SELECTION';
export const VALIDATING = 'VALIDATING';
export const TRANSFER_SUCCESS = 'TRANSFER_SUCCESS';
