//Link Token Constants
export const PLAID_LINK_TOKEN_REQUEST = 'PLAID_LINK_TOKEN_REQUEST';
export const PLAID_LINK_TOKEN_ERROR = 'PLAID_LINK_TOKEN_ERROR';
export const PLAID_LINK_TOKEN_SUCCESS = 'PLAID_LINK_TOKEN_SUCCESS';
export const PLAID_LINK_TOKEN_REMOVE = 'PLAID_LINK_TOKEN_REMOVE';

//Linked Accounts Constants
export const PLAID_LINKED_ACCOUNTS_REQUEST = 'PLAID_LINKED_ACCOUNTS_REQUEST';
export const PLAID_LINKED_ACCOUNTS_ERROR = 'PLAID_LINKED_ACCOUNTS_ERROR';
export const PLAID_LINKED_ACCOUNTS_SUCCESS = 'PLAID_LINKED_ACCOUNTS_SUCCESS';

//Linked Accounts Logos Constants
export const PLAID_LINKED_ACCOUNTS_LOGO_REQUEST =
  'PLAID_LINKED_ACCOUNTS_LOGO_REQUEST';
export const PLAID_LINKED_ACCOUNTS_LOGO_ERROR =
  'PLAID_LINKED_ACCOUNTS_LOGO_ERROR';
export const PLAID_LINKED_ACCOUNTS_LOGO_SUCCESS =
  'PLAID_LINKED_ACCOUNTS_LOGO_SUCCESS';

//Link Account Constants
export const PLAID_LINK_ACCOUNT_REQUEST = 'PLAID_LINK_ACCOUNT_REQUEST';
export const PLAID_LINK_ACCOUNT_ERROR = 'PLAID_LINK_ACCOUNT_ERROR';
export const PLAID_LINK_ACCOUNT_SUCCESS = 'PLAID_LINK_ACCOUNT_SUCCESS';

//Fund Transfer Constants
export const PLAID_FUND_TRANSFER_REQUEST = 'PLAID_FUND_TRANSFER_REQUEST';
export const PLAID_FUND_TRANSFER_ERROR = 'PLAID_FUND_TRANSFER_ERROR';
export const PLAID_FUND_TRANSFER_SUCCESS = 'PLAID_FUND_TRANSFER_SUCCESS';

//Remove Account Constants
export const PLAID_REMOVE_ACCOUNT_REQUEST = 'PLAID_REMOVE_ACCOUNT_REQUEST';
export const PLAID_REMOVE_ACCOUNT_ERROR = 'PLAID_REMOVE_ACCOUNT_ERROR';
export const PLAID_REMOVE_ACCOUNT_SUCCESS = 'PLAID_REMOVE_ACCOUNT_SUCCESS';

export const PLAID_CLEAN = 'PLAID_CLEAN';
