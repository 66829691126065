import moment from 'moment';
import momentTimeZone from 'moment-timezone';

moment.suppressDeprecationWarnings = true;

const PT_TIMEZONE = 'America/Los_Angeles';

export const getMoment = o => {
  if (o === null || o === undefined) return moment();
  else if (typeof o === 'string') return moment(o);
  else if (Object.prototype.toString.call(o) === '[object Date]')
    return moment(o);
  else return o;
};
export function elapsedTime(previous) {
  let current = new Date();
  previous = new Date(previous);
  let msPerMinute = 60 * 1000;
  let msPerHour = msPerMinute * 60;
  let msPerDay = msPerHour * 24;
  let msPerMonth = msPerDay * 30;
  let msPerYear = msPerDay * 365;
  var timeSince = current - previous;

  if (timeSince < msPerMinute) {
    return Math.round(timeSince / 1000) + ' seconds ago';
  } else if (timeSince < msPerHour) {
    return Math.round(timeSince / msPerMinute) + ' minutes ago';
  } else if (timeSince < msPerDay) {
    return Math.round(timeSince / msPerHour) + ' hours ago';
  } else if (timeSince < msPerMonth) {
    return 'approximately ' + Math.round(timeSince / msPerDay) + ' days ago';
  } else if (timeSince < msPerYear) {
    return Math.round(timeSince / msPerMonth) + ' months ago';
  } else {
    return Math.round(timeSince / msPerYear) + ' years ago';
  }
}

export const convertMonthsToYears = months => {
  if (typeof months !== 'number') {
    return 0;
  } else {
    return Math.ceil(months / 12);
  }
};

export const getFirstOfNextMonth = dateString => {
  const m = moment(dateString);
  m.add(1, 'months');
  m.date(1);
  m.hour(0);
  m.minute(0);
  m.second(0);

  return m.format();
};
export const getLastDayOfThisMonth = dateString => {
  const m = moment(dateString);
  m.add(1, 'months');
  m.date(1);
  m.hour(0);
  m.minute(0);
  m.second(0);
  m.add(-1, 'day');

  return m.format();
};

export const getFirstOfTheMonth = dateString => {
  const m = getMoment(dateString);
  m.date(1);
  m.hour(0);
  m.minute(0);
  m.second(0);

  return m.format();
};

export const addMonth = (dateString, monthsToSubtract = 1) => {
  const m = getMoment(dateString);
  m.add(monthsToSubtract, 'months');
  return m.format();
};

export const formatAsFull = s => {
  const m = getMoment(s);

  return m.format('MMM D, YYYY');
};

export const formatAsFullWithCompleteMonth = s => {
  const m = getMoment(s);

  return m.format('MMMM D, YYYY');
};

export const formatAsCompleteMonthAndYear = s => {
  const m = getMoment(s);

  return m.format('MMM YYYY');
};

export const formatAsYearMonthDay = s => {
  const m = getMoment(s);

  return m.format('YYYY-MM-DD');
};

export const formatAsMonthDayYear = s => {
  const m = getMoment(s);

  return m.format('MM-DD-YYYY');
};

export const formatDate = (s, pattern) => {
  const m = getMoment(s);

  return m.format(pattern);
};

export const formatAsMonthDay = s => {
  const m = getMoment(s);

  return m.format('MMM DD');
};

export const formatAsDayMonth = s => {
  const m = getMoment(s);

  return m.format('Do MMM');
};

export const formatAsFullWithTime = s => {
  const m = getMoment(s);

  return m.format('MMM D, YYYY h:mmA');
};

export const formatAsFullWithTimeWithAt = s => {
  const m = getMoment(s);

  return m.format('MMM D, YYYY [at] h:mmA');
};

export const formatAsFullWithTimeZone = s => {
  return momentTimeZone.tz(s, PT_TIMEZONE).format('MMMM D, YYYY h:mmA z');
};

export const formatWithTimeOnly = s => {
  const m = getMoment(s);

  return m.format('h:mma');
};

export const formatAsYear = s => {
  const m = getMoment(s);

  return m.format('YYYY');
};

export const formatAsMonthName = s => {
  const m = getMoment(s);

  return m.format('MMMM');
};

export const formatAsFullMonthDay = s => {
  const m = getMoment(s);

  return m.format('MMMM D');
};

export const formatAsMonthDayOnly = s => {
  const m = getMoment(s);

  return m.format('M/D');
};

export const formatAsFullMonthDayYear = s => {
  const m = getMoment(s);
  return m.format('MMMM D, YYYY');
};

export const convertTUDatetoFormattedDate = (
  date,
  isEpochMilliseconds,
  format,
) => {
  let dateFormat = format ? format : 'MMM DD, YYYY';
  if (isEpochMilliseconds) {
    return moment(parseInt(date)).format(dateFormat);
  }
  return moment(date).format(dateFormat);
};

export const getFullCurrentMonth = () => {
  return moment().format('MMMM');
};

export const getSimplifiedDate = s => {
  return getMoment(s).format('MM/DD/YYYY');
};

export const getSortableDate = s => {
  return getMoment(s).format('YYYYMMDD');
};

export const parseSortableDate = s => {
  return moment(s, 'YYYYMMDD');
};

export const parseDate = (s, pattern) => {
  return moment(s, pattern);
};

export const formatAsReport = s => {
  const m = getMoment(s);

  return m.format('M/D [at] h:mm a');
};

export const getDateWithoutTime = s => {
  const m = getMoment(s);
  m.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  return m;
};

export const getNextRecurringDayOfMonth = s => {
  const m = getMoment(s);
  const now = moment();

  m.set('month', now.month());
  m.set('year', now.year());
  m.set('hour', 0);
  m.set('minute', 0);
  m.set('seconds', 0);

  if (m.isBefore(now)) {
    m.set('month', now.month() + 1);
  }

  return m;
};

export const getDiffYearsUntilNow = date => {
  return moment().diff(date, 'years', false);
};

export const getDiffMonthsBetweenDates = (d1, d2) => {
  return getMoment(d1).diff(getMoment(d2), 'months');
};

export const getDiffDaysBetweenDates = (d1, d2) => {
  return getMoment(d1).diff(getMoment(d2), 'days');
};

export const getDiffHoursBetweenDates = (d1, d2) => {
  return getMoment(d1).diff(getMoment(d2), 'hours');
};

export const getDiffMsBetweenDates = (d1, d2) => {
  return getMoment(d1).diff(getMoment(d2));
};

export const isDateSameDateAsToday = date => {
  const now = moment();
  const targetDate = getMoment(date);

  return now.isSame(targetDate, 'day');
};

export const isDateSame = (date, targetDate) => {
  const now = getMoment(date);
  const compareTargetDate = getMoment(targetDate);

  return now.isSame(compareTargetDate, 'day');
};

export const isDateAfterTarget = (date, targetDate) => {
  return moment(date).isAfter(moment(targetDate));
};

export const isDateBeforeTarget = (date, targetDate) => {
  return moment(date).isBefore(moment(targetDate));
};

export const getStandardTimestamp = d => {
  const m = getMoment(d);
  return m.format();
};

export const parseStandardTimestamp = t => {
  return getMoment(t);
};

export const formatStandardTimestamp = t => {
  const m = getMoment(t);

  return m.format();
};

export const getDifferenceInMillis = (a, b) => {
  const aMoment = getMoment(a);
  const bMoment = getMoment(b);

  return aMoment.diff(bMoment);
};

export const formatRefCodeTimestamp = t => {
  const m = getMoment(t);

  return m.format('DD-MMM-YYYY').toLowerCase();
};

export const getDaysSince = t => {
  const m = getMoment(t);
  const now = moment();

  return now.diff(m, 'days');
};

export function getDaysSinceWithNull(stringDate) {
  if (!stringDate) {
    return 0;
  }
  return getDaysSince(stringDate);
}

export function getDaysSinceWithNullAs(stringDate, retValOnNull = null) {
  if (!stringDate) {
    return retValOnNull;
  }
  return getDaysSince(stringDate);
}

const getSetDayWithMax = (date, day) => {
  if (date === null || day === null) return null;
  if (date.daysInMonth() >= day) {
    return day;
  } else {
    return date.daysInMonth();
  }
};

export const getPremiumEnrollDate = userInfo => {
  let enrolledDate = null;
  if (userInfo) {
    enrolledDate = userInfo.premiumPlanInfo.enrolledDate;
    if (
      userInfo.premiumPlanInfo.trialEndDate &&
      !userInfo.premiumPlanInfo.freeTrialPeriod
    ) {
      enrolledDate = userInfo.premiumPlanInfo.trialEndDate;
    }
  }
  return enrolledDate
    ? formatAsYearMonthDay(parseSortableDate(enrolledDate))
    : null;
};

const minutesOfDay = m => {
  return m.minutes() + m.hours() * 60;
};

export const getCancelSubscriptionDate = userInfo => {
  if (!userInfo) return null;
  let enrollDateWithTrial = userInfo.premiumPlanInfo.trialEndDate
    ? userInfo.premiumPlanInfo.trialEndDate
    : userInfo.premiumPlanInfo.enrolledDate;
  const enrollDate = getMoment(enrollDateWithTrial).date();
  const now = getMoment(momentTimeZone.tz(moment(), PT_TIMEZONE));
  const today = now.date();
  const enrollHour = minutesOfDay(
    getMoment(momentTimeZone.tz(enrollDateWithTrial, PT_TIMEZONE)),
  );
  const todayHour = minutesOfDay(
    getMoment(momentTimeZone.tz(now, PT_TIMEZONE)),
  );
  let endDate = null;
  if (today <= enrollDate && todayHour <= enrollHour) {
    endDate = now.set('date', getSetDayWithMax(now, enrollDate));
  } else {
    endDate = now.add(1, 'M');
    endDate.set('date', getSetDayWithMax(endDate, enrollDate));
  }
  return endDate !== null ? formatAsYearMonthDay(endDate) : endDate;
};

export const getCancelSubscriptionForTrial = userInfo => {
  if (!userInfo || !userInfo.premiumPlanInfo.trialEndDate) return null;
  let cancellationDate = getMoment(userInfo.premiumPlanInfo.trialEndDate).add(
    1,
    'd',
  );
  return formatAsYearMonthDay(cancellationDate);
};

export function getMostRecentDate(date1, date2) {
  const firstDate = getMoment(date1);
  const secondDate = getMoment(date2);
  const recentDate = firstDate.isSameOrAfter(secondDate)
    ? firstDate
    : secondDate;
  return recentDate;
}

export function getNumberOfDaysUntilNextFirstOfMonth() {
  const today = new Date();
  const nextUpdateDays = moment()
    .endOf('month')
    .add(1, 'day')
    .diff(today, 'days');

  return nextUpdateDays === 1
    ? nextUpdateDays + ' day'
    : nextUpdateDays + ' days';
}

export const getMonthLastDayDate = month => {
  if (month) {
    return moment().month(month).endOf('month');
  }
  return moment().endOf('month');
};

export const dateIsBetweenTwoDates = (date, startDate, endDate, dateFormat) => {
  return moment(date).isBetween(
    getMoment(startDate, dateFormat),
    getMoment(endDate, dateFormat),
    undefined,
    '[]',
  );
};

export function monthsToYears(months) {
  let y = 0;
  let m = 0;
  if (months) {
    if (months < 12) {
      m = Math.floor(months);
    } else {
      y = parseInt(months / 12);
      m = Math.floor(months - y * 12); // JavaScript behaves badly sometime so to protect from that.
    }
    return `${y !== 0 ? `${y} ${y === 1 ? `yr` : `yrs`}` : ''}${
      y !== 0 && m !== 0 ? ' ' : ''
    }${m !== 0 ? `${m} ${m === 1 ? 'mo' : 'mos'}` : ''}`;
  } else {
    return 0;
  }
}

// get Number Of Months Since the Account Open Date
export function getMonthsSinceDate(date) {
  const openDateMoment = moment(date);

  if (!openDateMoment.isValid()) return NaN;

  const months = Math.floor(moment().diff(openDateMoment, 'months', true));
  return months;
}

export const months = {
  0: 'Jan',
  1: 'Feb',
  2: 'Mar',
  3: 'Apr',
  4: 'May',
  5: 'Jun',
  6: 'Jul',
  7: 'Aug',
  8: 'Sept',
  9: 'Oct',
  10: 'Nov',
  11: 'Dec',
};
export const months2DigitNumberFormat = {
  1: '01',
  2: '02',
  3: '03',
  4: '04',
  5: '05',
  6: '06',
  7: '07',
  8: '08',
  9: '09',
  10: '10',
  11: '11',
  12: '12',
};
export const monthsWith3Chars = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'Jun',
  7: 'Jul',
  8: 'Aug',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
};
export const getDate = (mm, dd, yyyy) => {
  return new Date(yyyy, mm - 1, dd);
};
export const get30daysFromNow = () => {
  let d = new Date();
  d.setDate(d.getDate() + 30);
  return getFormattedDate(d);
};
export const getFormattedDate = date => {
  return `${months[date.getUTCMonth()]} ${date.getUTCDate().toString()}, ${date
    .getUTCFullYear()
    .toString()}`;
};

export const addDays = (d, delta) => {
  const m = getMoment(d);

  m.add(delta, 'day');
  return m;
};

export const setDate = (s, date) => {
  const m = getMoment(s);
  m.set({ date });
  return m;
};

export const getDayOfMonth = date => {
  const s = date ? getMoment(date) : getMoment();
  return Number(getMoment(s).format('DD'));
};

export const dateIsValid = date => moment(date).isValid();

export const oneDayInMilliseconds = 86400000;

export const getDiffHoursAndMinsFromNow = () => {
  const now = getMoment();
  let tomorrow = moment().add(1, 'days').startOf('day');
  const duration = moment.duration(tomorrow.diff(now));
  const hours = parseInt(duration.asHours());
  const minutes = parseInt(duration.asMinutes()) % 60;
  return { hours, minutes };
};

export const getMonthName = c => {
  const m = c ? getMoment(c) : getMoment();
  return m.format('MMMM');
};

export const getMonthShortName = c => {
  const m = getMoment(c);
  return m.format('MMM');
};
export const formatDateMMDDYY = c => {
  const m = getMoment(c);
  return m.format('MM/DD/YY');
};

export const isValidDate = d => {
  return d instanceof Date && !isNaN(d);
};

export const getMonthsList = (short = null) => {
  if (short) {
    return moment.monthsShort();
  }
  return moment.months();
};
export const getMonth = date => {
  const m = date ? getMoment(date) : getMoment();
  return m.month();
};

export const getNextMonth = date => {
  const m = moment(date).add(1, 'months');
  return m.month();
};

export const getDiffMonthFromNow = date => {
  const m = getMoment(date);
  return m.diff(getMoment(), 'months');
};

export const getMonthByDay = (day, date) => {
  const m = date ? getMoment(date) : getMoment();
  return m.date(day);
};

export const getNextMonthByDay = day => {
  return moment().add(1, 'months').date(day);
};

export const getPreviousMonth = date => {
  const m = moment(date).subtract(1, 'months');
  return m.format('YYYY-MM-DD');
};

export const formatDateYYYYMMDD = date => {
  const m = getMoment(date);
  return m.format('YYYY-MM-DD');
};

export const formatDateMMMDDYYYY = date => {
  const m = getMoment(date);
  return m.format('MMM DD, YYYY');
};

export const getDay = date => {
  const m = date ? getMoment(date) : getMoment();
  return m.format('DD');
};

export const formatNotificationAlertDate = date =>
  moment(date).format('MMM D, YYYY');

export const dateOrdinal = date => {
  const dString = String(date);
  const last = +dString.slice(-2);
  if (last > 3 && last < 21) return 'th';
  switch (last % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

export const getMonthNumberFromDate = monthName => {
  const date = new Date(`${monthName} 1, 2000`);
  return date.getMonth() + 1;
};

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const getLastSixMonthsFirstCharacter = () => {
  const currentDate = new Date();
  const lastSixMonths = [];
  for (let i = 5; i >= 0; i--) {
    const monthIndex = (currentDate.getMonth() - i + 12) % 12;
    const monthName = monthNames[monthIndex];
    lastSixMonths.push(monthName);
  }
  return lastSixMonths;
};

export const hasPassedMore24Hours = date => {
  if (!date) return false;
  let hours = moment().diff(moment(date), 'hours');
  return hours > 24;
};

export const convertToCardExpiryDate = date => {
  const convertedDate = moment(date, 'MMYYYY').endOf('month');
  return convertedDate;
};
