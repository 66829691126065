import { LOGOUT } from 'types/app.constants';
import { SELECT_DETAILS_OFFER } from 'types/offersDetails.constants';

const initialState = {
  offerDetailsData: null,
};

export default function offerDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_DETAILS_OFFER: {
      return {
        ...state,
        offerDetailsData: action.offerDetailsData,
      };
    }
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
