import {
  selectShouldInitiateUserInfoLoad,
  selectUserInfo,
} from 'selectors/api.selector';
import {
  GET_SUCCESS,
  GET_FAILURE,
  GET_REQUEST,
} from 'types/userInfo.constants';

import { updateSessionTimeoutInLocal } from 'helpers/session';
import {
  getUserProfile,
  putUserAddress,
  patchUser as patchUserInfo,
  patchUserWithQueryParams as patchUserInfoWithQueryParams,
  userService,
} from 'externals/_services/user.service';
import { error } from 'actions/notification.actions';
import { wrapService } from 'actions/service_wrapper.actions';
import { getDecisionForFlag } from 'externals/_tracking/optimizely/optFlags';

const a_getUserProfile = wrapService(getUserProfile, {
  name: 'getUserProfile',
});
const a_putUserAddress = wrapService(putUserAddress, {
  name: 'putUserAddress',
});
const a_patchUser = wrapService(patchUserInfo, {
  name: 'patchUser',
});
const a_patchUserWithQueryParams = wrapService(patchUserInfoWithQueryParams, {
  name: 'patchUserWithQueryParams',
});
const a_userServiceUpdate = wrapService(userService.update, {
  name: 'userServiceUpdate',
});

function request() {
  return {
    type: GET_REQUEST,
    userInfo: null,
    userInfoError: null,
    userInfoLoading: true,
  };
}
export function __success(userInfo) {
  return {
    type: GET_SUCCESS,
    userInfo,
    userInfoError: null,
    userInfoLoading: false,
  };
}
function failure(userInfoError) {
  return {
    type: GET_FAILURE,
    userInfoError,
    userInfo: null,
    userInfoLoading: false,
  };
}

export const retrieveUserInfoLogin = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const vri2 = getDecisionForFlag('vri2', {
      defaultConfig: {
        vriVersion: 'click',
      },
    });
    dispatch(a_getUserProfile(vri2?.config?.vriVersion))
      .then(userInfo => {
        dispatch(__success(userInfo));
        resolve(userInfo);
      })
      .catch(err => {
        dispatch(failure(err.message));
        reject(err);
      });
  });
};

export const retrieveUserInfo = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const state = getState();
    if (selectShouldInitiateUserInfoLoad(state, true)) {
      dispatch(a_getUserProfile())
        .then(userInfo => {
          dispatch(__success(userInfo));
          resolve(userInfo);
        })
        .catch(err => {
          dispatch(failure(err.message));
          reject(err);
        });
    } else {
      resolve(selectUserInfo(state).userInfo);
    }
  });
};

export const guaranteeUserInfoPayload = ctx => async (dispatch, getState) => {
  const state = getState();
  const userState = selectUserInfo(state);
  const { userInfo, userInfoLoading, userInfoError } = userState;
  const { isUserSessionActive } = ctx;

  const requestWillStart =
    !userInfoLoading && !userInfoError && !userInfo && isUserSessionActive;

  if (requestWillStart) {
    const userInfoPromise = dispatch(retrieveUserInfoLogin());
    ctx?.registerPromise('userInfo', userInfoPromise);
    return userInfoPromise;
  } else {
    return userState;
  }
};

export function getUserInfo(doNotClear, vriVersion) {
  return (dispatch, getState, setCSContext) => {
    const state = getState();
    if (state.userInfo.userInfoLoading) return Promise.resolve('');

    if (!doNotClear) {
      dispatch(request());
    }

    return new Promise((resolve, reject) => {
      dispatch(a_getUserProfile(vriVersion))
        .then(userInfo => {
          dispatch(__success(userInfo));
          updateSessionTimeoutInLocal();
          resolve(userInfo);
        })
        .catch(err => {
          dispatch(failure(err.message));
          reject(err);
        });
    });
  };
}

export function updateUserAddress(address) {
  return (dispatch, getState, setCSContext) => {
    dispatch(request());

    return new Promise((resolve, reject) => {
      dispatch(a_putUserAddress(address))
        .then(userInfo => {
          dispatch(__success(userInfo));
          setCSContext(getState());
          resolve(userInfo);
        })
        .catch(err => {
          dispatch(failure(err));
          dispatch(error(err));
          reject(err);
        });
    });
  };
}

export const updateUser = userInfo => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(a_userServiceUpdate(userInfo))
      .then(_ => {
        return dispatch(getUserInfo(true));
      })
      .then(freshUserInfo => {
        resolve(freshUserInfo);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const patchUser = propertyObject => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(a_patchUser(propertyObject))
      .then(_ => {
        return dispatch(getUserInfo(true));
      })
      .then(freshUserInfo => {
        resolve(freshUserInfo);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const patchUserWithQueryParams =
  (propertyObject, queryParams) => dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(a_patchUserWithQueryParams(propertyObject, queryParams))
        .then(_ => {
          return dispatch(getUserInfo(true));
        })
        .then(freshUserInfo => {
          resolve(freshUserInfo);
        })
        .catch(err => {
          reject(err);
        });
    });
  };
