import React from 'react';
import { getImageBasePath } from 'helpers/env';
import Label from 'components/form/Label/Label';
import { Button } from 'components/form/Button/index';
import { ErrorTemplate } from './ErrorTemplate';

export const AccountLockedError = ({
  errorType,
  dynamicErrorMessage,
  ...props
}) => {
  let errorMessage = dynamicErrorMessage
    ? dynamicErrorMessage
    : 'Your account has been locked for 30 days due to too many failed sign-up attempts or incorrect answers.';
  return (
    <ErrorTemplate
      errorType={errorType}
      title="Account locked"
      imgSrc={`${getImageBasePath()}images/account_locked_error.png`}
    >
      <Label className="padTB8 margB16">{errorMessage}</Label>
      {!dynamicErrorMessage && (
        <Label className="padTB8 margB16">
          We will send you an email to inform you when that 30-day lock is over,
          and you can continue signing up.
        </Label>
      )}
      <Label className="padTB8 margB16">
        While you wait, consider building your credit by checking out some of
        the best offers from our partners.
      </Label>
      <Button mrphType="primary" onClick={props.onButtonClick}>
        Explore Offers
      </Button>
    </ErrorTemplate>
  );
};
