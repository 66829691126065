import { getDecisionForFlag } from 'externals/_tracking/optimizely/optFlags';
import { ReactComponent as MonthlyCreditScore } from 'components/premium/PremiumLandingCard/assets/monthlyCreditScore.svg';
import { ReactComponent as UnlimitedDailyScore } from 'components/premium/PremiumLandingCard/assets/unlimitedDailyScore.svg';
import { ReactComponent as FullCreditReports } from 'components/premium/PremiumLandingCard/assets/fullCreditReports.svg';
import { ReactComponent as AdvancedCreditMonitoring } from 'components/premium/PremiumLandingCard/assets/monthlyCreditScore.svg';
import { ReactComponent as CreditDisputingResolution } from 'components/premium/PremiumLandingCard/assets/unlimitedDailyScore.svg';
import { ReactComponent as IdAndSsn } from 'components/premium/PremiumLandingCard/assets/idAndSsn.svg';
import { ReactComponent as SesameTurboVar1A } from 'components/premium/PremiumLandingCard/assets/sesameTurboVar1-1.svg';
import { ReactComponent as SesameTurboVar1B } from 'components/premium/PremiumLandingCard/assets/sesameTurboVar2-2.svg';
import { ReactComponent as SesameTurboVar1C } from 'components/premium/PremiumLandingCard/assets/sesameTurboVar1-3.svg';
import { ReactComponent as GreenCheckMarkWeb } from 'components/premium/PremiumLandingCard/assets/checkmarkWeb.svg';
import { isPulse } from 'helpers/userHelper';
import { ReactComponent as GreenCheckmarkIcon } from 'style/_assets/icons/green-checkmark.svg';
import { ReactComponent as GreenCheckmarkIcon24 } from 'style/_assets/icons/green-checkmark24px.svg';
import { DEFAULT_PLAN_PLUS } from 'types/plus.constants';
import { DEFAULT_PLAN } from 'types/premium.constants';

//super hacky but can be incrementally phased out incrementally
const loadAllPlanMetadata = allPlanMetadataOptionalArg => {
  if (allPlanMetadataOptionalArg) return allPlanMetadataOptionalArg;
  const premiumLandingFlag = getDecisionForFlag('premium_data');
  return premiumLandingFlag?.config?.all_plans_metadata;
};

export const obtainPlanTitle = (checkoutItemId, allPlanMetadataOptionalArg) => {
  const allPlansMetadata = loadAllPlanMetadata(allPlanMetadataOptionalArg);
  const result = buildPlanFromConfiguration(allPlansMetadata, checkoutItemId);
  return result?.planName;
};

export const obtainPlanPricing = (
  config,
  checkoutItemId,
  noFormat,
  allPlanMetadataOptionalArg,
) => {
  const allPlansMetadata = loadAllPlanMetadata(allPlanMetadataOptionalArg);
  const result = buildPlanFromConfiguration(allPlansMetadata, checkoutItemId);
  return result?.pricing;
};

export const getConfigForPremiumCardItem = type => {
  const config = {
    monthlyCreditScore: {
      icon: <MonthlyCreditScore className="packageDetailIcon" />,
      title:
        'Monthly credit score updates from  TransUnion, Experian, and Equifax',
      text: '3 bureau scores from TransUnion, Experian, and Equifax are based on the VantageScore® 3.0 model',
    },
    unlimitedDailyScore: {
      icon: <UnlimitedDailyScore className="packageDetailIcon" />,
      title: 'Unlimited daily score updates',
      text: 'Daily credit score updates based on the TransUnion VantageScore® 3.0',
    },
    fullCreditReports: {
      icon: <FullCreditReports className="packageDetailIcon" />,
      title: 'Full credit reports from TransUnion, Experian, and Equifax',
      text: '3 bureau report includes your credit files from TransUnion, Experian, and Equifax combined in to one easy-to-read report',
    },
    advancedCreditMonitoring: {
      icon: <AdvancedCreditMonitoring className="packageDetailIcon" />,
      title: 'Advanced credit monitoring & alerts',
      text: 'For complete coverage of what’s happening on your credit report, we offer daily monitoring of TransUnion, Experian, and Equifax',
    },
    creditDisputingResolution: {
      icon: <CreditDisputingResolution className="packageDetailIcon" />,
      title: 'Credit dispute resolution assistance',
      text: "Get help from Experian's specialists for assistance with investigating and disputing incorrectly reported information without your credit file",
    },
    idAndSsn: {
      icon: <IdAndSsn className="packageDetailIcon" />,
      title: 'ID and SSN Monitoring',
      text: 'Monitors names, aliases, and addresses associated with your social security number',
    },
    sesameTurboVar1A: {
      icon: <SesameTurboVar1C className="packageDetailIcon" />,
      title: 'Get monthly 3 bureau credit reports',
      text: 'Access your full credit reports',
      textMobile: 'Access your full credit reports',
      smallText: 'Access your full credit reports',
    },
    sesameTurboVar1B: {
      icon: <SesameTurboVar1B className="packageDetailIcon" />,
      title: 'Get credit alerts from all 3 bureaus',
      text: 'Stay on top of your credit with advanced credit alerts from TransUnion and Experian',
      textMobile: 'Get credit alerts from all 3 bureaus',
      smallText: 'Get credit alerts from all 3 bureaus',
    },
    sesameTurboVar1C: {
      icon: <SesameTurboVar1A className="packageDetailIcon" />,
      title: 'Report rent payments to the bureaus',
      text: 'Get up to 24 months of credit history from rent payments',
      textMobile: 'Build your credit history automatically with rent payments',
      smallText: 'Build your credit history automatically with rent payments',
    },
    sesameNoDiscountVar2A: {
      icon: <SesameTurboVar1C className="packageDetailIcon" />,
      iconSmall: <GreenCheckmarkIcon />,
      title: 'Get monthly 3 bureau credit reports*',
      text: 'Access your full credit reports*',
      textMobile: 'Access your full credit reports*',
      smallText: 'Access your full credit reports*',
      subText: '*Available upon subscription',
    },
    sesameNoDiscountVar2B: {
      icon: <SesameTurboVar1B className="packageDetailIcon" />,
      iconSmall: <GreenCheckmarkIcon />,
      title: 'Get credit alerts from all 3 bureaus',
      text: 'Stay on top of your credit with advanced credit alerts from TransUnion and Experian',
      textMobile: 'Get credit alerts from all 3 bureaus',
      smallText: 'Get credit alerts from all 3 bureaus',
    },
    sesameNoDiscountVar2C: {
      icon: <SesameTurboVar1A className="packageDetailIcon" />,
      iconSmall: <GreenCheckmarkIcon />,
      title: 'Report rent payments to the bureaus',
      text: 'Get up to 24 months of credit history from rent payments',
      textMobile: 'Build your credit history automatically with rent payments',
      smallText: 'Build your credit history automatically with rent payments',
    },
    sesameNoDiscountVar4A: {
      icon: <SesameTurboVar1C className="packageDetailIcon" />,
      iconSmall: <GreenCheckmarkIcon />,
      title: 'Get monthly 3 bureau credit reports',
      text: 'Access your full credit reports',
      textMobile: 'Access your full credit reports',
      smallText: 'Access your full credit reports',
    },
    sesameNoDiscountVar4B: {
      icon: <SesameTurboVar1B className="packageDetailIcon" />,
      iconSmall: <GreenCheckmarkIcon />,
      title: 'Get credit alerts from all 3 bureaus',
      text: 'Stay on top of your credit with advanced credit alerts from TransUnion and Experian',
      textMobile: 'Get credit alerts from all 3 bureaus',
      smallText: 'Get credit alerts from all 3 bureaus',
    },
    sesameNoDiscountVar4C: {
      icon: <SesameTurboVar1A className="packageDetailIcon" />,

      iconSmall: <GreenCheckmarkIcon />,
      title: 'Report rent payments to the bureaus',
      text: 'Get up to 24 months of credit history from rent payments',
      textMobile: 'Build your credit history automatically with rent payments',
      smallText: 'Build your credit history automatically with rent payments',
    },
    sesameNoDiscountVar6C: {
      icon: <GreenCheckMarkWeb />,
      iconSmall: <GreenCheckmarkIcon />,
      title: 'Report rent payments to the bureaus',
      textMobile: 'Build your credit history automatically with rent payments',
      smallText: 'Build your credit history automatically with rent payments',
    },
    sesameNoDiscountVar6A: {
      icon: <GreenCheckMarkWeb />,
      iconSmall: <GreenCheckmarkIcon />,
      title: 'Get monthly 3 bureau credit reports',
      textMobile: 'Access your full credit reports',
      smallText: 'Access your full credit reports',
    },
    sesameNoDiscountVar6B: {
      icon: <GreenCheckMarkWeb />,
      iconSmall: <GreenCheckmarkIcon />,
      title: 'Get credit alerts from all 3 bureaus',
      textMobile: 'Get credit alerts from all 3 bureaus',
      smallText: 'Get credit alerts from all 3 bureaus',
    },
    sesameNoDiscountVar3A: {
      icon: <SesameTurboVar1B className="noTurboDiscountIconVar3" />,
      title: 'Get monthly 3 bureau credit reports',
      text: 'Access your full credit reports',
      textMobile: 'Access your full credit reports',
      smallText: 'Access your full credit reports',
    },
    sesameNoDiscountVar3B: {
      icon: <SesameTurboVar1A className="noTurboDiscountIconVar3" />,
      title: 'Get credit alerts from all 3 bureaus',
      text: 'Stay on top of your credit with advanced credit alerts from TransUnion and Experian',
      textMobile: 'Get credit alerts from all 3 bureaus',
      smallText: 'Get credit alerts from all 3 bureaus',
    },
    sesameNoDiscountVar3C: {
      icon: <SesameTurboVar1C className="noTurboDiscountIconVar3" />,
      title: 'Report rent payments to the bureaus',
      text: 'Get up to 24 months of credit history from rent payments',
      textMobile: 'Build your credit history automatically with rent payments',
      smallText: 'Build your credit history automatically with rent payments',
    },

    publicPremium2: {
      iconSmall: <GreenCheckmarkIcon24 />,
      title: 'Full 3 bureau credit reports',
      text: 'Get your monthly credit report from Experian, Equifax, and TransUnion',
      textMobile: 'Full 3 bureau credit reports',
      smallText: 'Full 3 bureau credit reports',
    },
    publicPremium2Var3: {
      iconSmall: <GreenCheckmarkIcon24 />,
      title: 'Full 3 bureau credit reports*',
      text: 'Get your monthly credit report from Experian, Equifax, and TransUnion',
      textMobile: 'Full 3 bureau credit reports*',
      smallText: 'Full 3 bureau credit reports*',
    },
    publicPremium3: {
      iconSmall: <GreenCheckmarkIcon24 />,
      title: 'Rent Reporting',
      text: 'Build your credit history automatically with rent payments',
      textMobile: 'Rent Reporting',
      smallText: 'Rent Reporting',
    },
    publicPremium4: {
      iconSmall: <GreenCheckmarkIcon24 />,
      title: 'Advanced credit monitoring & alerts',
      text: 'Stay on top of your credit with advanced credit alerts from TransUnion and Experian',
      textMobile: 'Advanced credit monitoring & alerts',
      smallText: 'Advanced credit monitoring & alerts',
    },
    publicPremium5: {
      iconSmall: <GreenCheckmarkIcon24 />,
      title: '24/7 live experts',
      text: "Get help from Experian's specialists investigating and disputing incorrectly reported information",
      textMobile: '24/7 live experts',
      smallText: '24/7 live experts',
    },
  };
  return config[type];
};

export const buildPlanFromConfiguration = (
  all_plans_metadata,
  subscriptionId,
  isPlus,
) => {
  let finalPlan = isPlus ? DEFAULT_PLAN_PLUS : DEFAULT_PLAN;
  if (all_plans_metadata) {
    Object.keys(all_plans_metadata).forEach(k => {
      const re = new RegExp(k, 'i');
      if (re.test(subscriptionId)) {
        finalPlan = all_plans_metadata[k];
      }
    });
  }

  const plan = {
    ...finalPlan,
    hasFeature: f => plan?.featureList?.includes(f),
  };

  return plan;
};

export const shouldDownload3bScores = userInfo => {
  const { premiumAccount } = userInfo ?? {};
  const {
    gate3BCreditReport,
    freeTrialPeriod,
    planSupportsFree3bReportDownload,
  } = userInfo?.premiumPlanInfo ?? {};
  const isPulseUser = isPulse(userInfo);
  return (
    ((freeTrialPeriod &&
      !gate3BCreditReport &&
      planSupportsFree3bReportDownload) || // user is in free trial, not gated, and plan supports 3b
      (premiumAccount &&
        !freeTrialPeriod &&
        planSupportsFree3bReportDownload)) && //user is a normal premium user in plan that supports 3b
    !isPulseUser
  );
};

export const loadAllTipsMetadata = () => {
  const premiumLandingFlag = getDecisionForFlag('premium_data');
  return premiumLandingFlag?.config?.tipsArray?.tips;
};

export const isTherePremiumBanner = props => {
  const {
    collapsed,
    premiumReactivateCancelHook,
    premiumReactivatePendingCancellationHook,
    premiumPaymentPendingHook,
    isPreviouslyPremiumPlusFlow,
  } = props;
  return (
    (isPreviouslyPremiumPlusFlow && !collapsed) ||
    premiumReactivateCancelHook?.isPremiumReactivateCancelUser ||
    premiumReactivatePendingCancellationHook?.isPremiumReactivatePendingCancellationUser ||
    premiumPaymentPendingHook?.isPremiumPaymentPendingUser
  );
};
