export const OVERVIEW = 'Overview';
export const FACTORS = 'Factors';
export const PAYMENT_HISTORY = 'Payment History';
export const CASH = 'Sesame Cash Overview';
export const CASH_UNENROLLED_CB_20 = 'Sesame Cash Overview (Unenrolled CB2.0)';
export const CASH_OVERVIEW_UN_ENROLLED_ABOVE_650 =
  'Sesame Cash Overview (Unenrolled Cash)';
export const CASH_OVERVIEW_UN_ENROLLED_BELOW_650 =
  'Sesame Cash Overview (Unenrolled CB2.0)';
export const CASH_TRANSACTIONS = 'Transaction History';
export const CASH_TRANSFER = 'CASH_TRANSFER';
export const CASH_BUILDER_ENROLLMENT_CHOOSE_PAYMENT =
  'Sesame Cash + Builder 2.0 Enroll - Choose Payment Method';
export const CASH_BUILDER_ENROLLMENT_LEGAL_ADDRESS =
  'Sesame Cash + Builder 2.0 Enroll - Legal/Address';
export const CASH_BUILDER_ENROLLMENT_FUNDING =
  'Sesame Cash + Builder 2.0 Enroll - Funding';
export const CASH_BUILDER_ENROLLMENT_WELCOME =
  'Sesame Cash + Builder 2.0 Enroll - Welcome';
export const CASH_BUILDER_ENROLLMENT_HOW_CREDIT_BUILDER_WORKS =
  'Sesame Cash + Builder 2.0 Enroll - How it Works 1';
export const CASH_BUILDER_ENROLLMENT_HOW_CREDIT_BUILDER_WORKS_2 =
  'Sesame Cash + Builder 2.0 Enroll - How it Works 2';
export const CASH_BUILDER_ENROLLMENT_MONTHLY_REPORTING =
  'Sesame Cash + Builder 2.0 Enroll - How it Works 3';
export const CASH_BUILDER_ENROLLMENT_NOW_ADD_MONEY =
  'Sesame Cash + Builder 2.0 Enroll - Funding Options';
export const CASH_BUILDER_ENROLLMENT_BUILD_PAYMENT_HISTORY =
  'Sesame Cash + Builder 2.0 Enroll - Abandon';

export const CB_20_ENROLLMENT_LANDING = 'CB2.0-Only Enrollment Landing Page';
export const CB_20_ENROLLMENT_CHOOSE_PAYMENT =
  'Builder 2.0 Enroll - Choose Payment Method';
export const CB_20_ENROLLMENT_LEGAL = 'Builder 2.0 Enroll - Legal';
export const CB_20_ENROLLMENT_FUNDING_FUNDED =
  'Builder 2.0 Enroll - Funding (Funded User)';
export const CB_20_ENROLLMENT_FUNDING_UN_FUNDED =
  'Builder 2.0 Enroll - Funding (Unfunded User)';

export const CASH_BUILDER_ENROLLMENT_LEGAL_ADDRESS_FLAG =
  'Sesame Cash Enroll - Legal/Address';
export const CASH_BUILDER_ENROLLMENT_FUNDING_FLAG =
  'Sesame Cash Enroll - Funding';
export const MANAGE_CASH = 'Sesame Cash Manage Card';
export const CREDIT_USAGE = 'Credit Usage';
export const CREDIT_AGE = 'Credit Age';
export const NEED_HELP_PASSWORD = 'Password Reset - Need Help';
export const HAVE_NO_ACCESS_PASSWORD = 'Password Reset - Have No Access';
export const HAVE_NO_ACCESS_PHONE = 'Password Reset - Have No Access to Phone';
export const HAVE_NO_ACCESS_EMAIL_AND_PHONE =
  'Password Reset - Have No Access to Email and Phone';
export const FORGOT_MY_PASSWORD = 'Forgot My Password';
export const FORGOT_MY_EMAIL = 'Forgot My Email';
export const ACCOUNT_MIX = 'Account Mix';
export const CREDIT_INQUIRIES = 'Credit Inquiries';
export const ACTIONS = 'My Actions';
export const ACTION_DETAILS = 'Action Details';
export const SELF_LENDER = 'Self Lender';
export const NEW_CARD_SCORE_INCREASE = 'New Card Score Increase';
export const NEGATIVE_MARKS = 'Negative Marks';
export const NEW_CARD_SCORE_OVER_TIME = 'New Card Score Increase Over Time';
export const NEGATIVE_MARKS_SIMULATION = 'Negative Marks Simulation';
export const REWARDS = 'Rewards';
export const CREDIT_LIMIT = 'Credit Limit';
export const MARKETPLACE = 'Marketplace';
export const CC_MARKETPLACE = 'Offers - Credit Card - All';
export const AUTOLOAN_MARKETPLACE = 'Offers - Auto Loan';
export const AUTO_REFINANCE_MARKETPLACE = 'Offers - Auto Refinance';
export const AUTO_REFINANCE_FAQS = 'FAQs - Auto Refinance';
export const AUTO_INSURANCE_MARKETPLACE = 'Offers - Insurance - Auto';
export const LIFE_INSURANCE = 'Offers - Insurance - Life';
export const HOME_INSURANCE = 'Offers - Insurance - Home';
export const INSURANCE_MARKETPLACE = 'Offers - Insurance';
export const INSURANCE_PRE_FILL = 'Offers - insurance - Pre-Fill - Form';
export const LIFE_INSURANCE_DETAIL = 'Offers - Life Insurance - Details';
export const HOME_INSURANCE_DETAIL = 'Offers - Home Insurance - Details';
export const AUTO_INSURANCE_DETAIL = 'Offers - Auto Insurance - Details';
export const AUTOLOANS_RESULTS = 'Auto Loans Results';
export const AUTOLOAN_DETAILS = 'Offers - Auto Loan - Details';
export const HOME_LOAN_MARKETPLACE = 'Home Loans';
export const BUSINESS_LOAN_MARKETPLACE = 'Offers - Business';
export const HOME_LOAN_INFORMATION_AND_TOOLS = 'Offers - Mortgage';
export const HOME_LOAN_PURCHASE = 'Home Purchase';
export const HOME_LOAN_REFINANCE = 'Home Refinance';
export const HOME_LOAN_ABOUT_REFINANCE = 'About Refinance';
export const HOME_LOAN_MONTHLY_PAYMENT_CALCULATOR =
  'Monthly Payment Calculator';
export const HOME_LOAN_ABOUT_MORTAGE = 'About Mortgage';
export const HOME_LOAN_EQUITY = 'Home Equity';
export const CC_CATEGORIES = 'Offers - Credit Card - Categories';
export const CC_COMPARISON = 'Offers - Credit Card - Comparison';
export const CC_DETAILS = 'Offers - Credit Card - Details';
export const CC_PREAPPROVAL = 'Credit Card Pre-approval';
export const LOANS_PERSONAL = 'Offers - Personal Loan';
export const LOANS_PERSONAL_DETAILS = 'Offers - Personal Loan - Details';
export const PROFILE = 'Profile';
export const PERSONAL_INFO = 'Profile Personal Info';
export const ACCOUNT_SETTINGS = 'Profile - Account Settings';
export const NOTIFICATION_SETTINGS = 'Profile - Notification Settings';
export const PROFILE_REFER_A_FRIEND = 'Refer a Friend';
export const SIGNOUT = 'Logout';
export const SIGNUP = 'Sign up';
export const LOG_IN = 'Log in';
export const ALEXA_INTEGRATION = 'ALEXA_INTEGRATION';
export const LOGIN_FROM_LOGOUT = 'Logout';
export const SESAME_CASH_ENROLL = 'Sesame Cash Enroll';
export const FORGOT_PASSWORD = 'Log in - Forgot Password';
export const CHANGE_EMAIL = 'Change Email';
export const EMAIL_SENT = 'Log in - Link Sent Reset Password';
export const PASSWORD_RECOVERY = 'Log in - Reset Password';
export const ALERTS = 'Monitoring Alerts';
export const PREMIUM_SIGNUP = 'Premium - Signup';
export const POST_PREMIUM_CROA = 'CROA_TOC';
export const PREMIUM_LANDING = 'Premium LP';
export const PLUS_LANDING = 'Plus LP';
export const PUBLIC_PREMIUM_LANDING = 'Public Premium LP';
export const IDPROTECTION_ACCOUNT_SETUP = 'ID Protection';
export const POST_PREMIUM_LANDING = 'Premium Purchase Confirmation';
export const PLUS_PURCHASE_CONFIRMATION = 'Plus Purchase Confirmation';
export const PLUS_CREDIT_TOOLS_UNLOCK = 'Plus Credit Tools Unlocked';
export const PLUS_UPGRADE = 'Plus LP Upgrade';
export const PLUS_UPGRADE_MODAL = 'Plus Upgrade Confirmation';
export const PLUS_DOWNGRADE_MODAL = 'Plus Downgrade Confirmation';
export const PLUS_UPGRADE_CHECKOUT = 'Checkout Plus Upgrade';
export const PLUS_UPGRADE_CONFIRMATION = 'Checkout Plus Upgrade Confirmation';
export const PLUS_REACTIVATE_LANDING = 'Plus LP Reactivation';
export const PLUS_REACTIVATE_CHECKOUT = 'Checkout Plus Reactivation';
export const PLUS_EDIT_PLAN = 'Plus Edit Plan';
export const PLUS_DIRECT_CHECKOUT = 'Plus LP Direct Checkout';
export const PLUS_3B_CONVERT_NOW = 'Plus 3B Convert Now - Congratulations';
export const PLUS_1B_CONVERT_NOW = 'Plus 1B Convert Now - Congratulations';
export const CREDIT_REPORTS = 'Credit Report';
export const CREDIT_REPORTS_3B = '3b report';
export const PREMIUM_PLAN = 'Premium Plan';
export const ID_PROTECTION = 'ID Protection';
export const CHECKOUT_SEVEN_DAY_UPDATE = 'Premium Checkout 7 Days Score Update';
export const CHECKOUT_THIRTY_DAY_UPDATE =
  'Premium Checkout 30 Days Score Update';
export const CHECKOUT_ONE_BUREAU = 'Premium Checkout 1 Bureau Credit Report';
export const CHECKOUT_THREE_BUREAU = 'Premium Checkout 3 Bureau Credit Report';
export const CHECKOUT_ADVANCED_CREDIT = 'Advanced Checkout';
export const CHECKOUT_PRO_CREDIT = 'Premium Checkout Pro Credit';
export const CHECKOUT_PLATINUM_CREDIT = 'Platinum Checkout';
export const PL_PREQUAL_FORM = 'Offers - Personal Loan - Prequal - Form';
export const PL_PREQUAL_RESULT = 'Offers - Personal Loan - Prequal - Congrats';
export const PL_PREQUAL_RESULT_SORRY =
  'Offers - Personal Loan - Prequal - Sorry';
export const CASH_ENROLLMENT_COMPLETE = 'Sesame Cash Enroll - Complete';
export const PRE_CHECKOUT = 'Premium package details';
export const CHECKOUT_PLATINUM_FREE_TRIAL = 'Free Trial Platinum Checkout';
export const JUMIO_LANDING_SUCCESS = 'Jumio Submit Success';
export const JUMIO_LANDING_FAILURE = 'Jumio Submit Failure';
export const DIRECT_DEPOSIT = 'Direct Deposit';
export const DIRECT_DEPOSIT_ENHANCED =
  'NU Sesame Cash Post-Enroll Direct Deposit Information';
export const RENT_PAYMENT_REPORTING = 'Rent Payment Reporting';
export const CHECKOUT_SESAME_TURBO_PLAN = 'Premium Checkout Turbo';
export const CHECKOUT_TURBO_2_MONTHS_FREE_PLAN = 'Premium Checkout Turbo';
export const CHECKOUT_TURBO_1_WEEK_FREE_PLAN = 'Premium Checkout Turbo';
export const CHECKOUT_TURBO_1_WEEK_FREE_PLAN_FULL_PRICE =
  'Turbo Free Trial Subscription 1599';

export const CHECKOUT_PUBLIC_PREMIUM_TURBO_FREE_PLAN =
  'Public Premium Checkout Turbo';

export const SIGNUP_CASH = 'NU_cash_enrollment';
export const CASH_FUND_ACCOUNT = 'Sesame Cash Post-Enroll Funding';
export const CASH_FUND_ACCOUNT_ENHANCED = 'NU Sesame Cash Post-Enroll Funding ';

export const SCRATCH = 'Scratch';
export const BOOSTER_ENROLLMENT_MARKETING = 'Booster Enrollment Marketing';
export const BOOSTER_ENROLLMENT_SECURITIZATION =
  'Booster securitization amount';
export const BOOSTER_ENROLLMENT_SECURITIZATION_CONFIRMATION =
  'Booster Securitization Confirmation';
export const BOOSTER_STATEMENTS = 'Credit Builder Statements';
export const BUILDER_TRANSACTIONS = 'BUILDER_TRANSACTIONS';
export const BUILDER_PAY_BALANCE = 'BUILDER_PAY_BALANCE';
export const QUARTERLY_REPORT = 'Quarterly Report';
export const GLOBAL_REVIEW = 'Global Review';
export const DEBT_PAYOFF_CALCULATOR = 'Card Debt Calculator';

//OCF
export const HOME_INSURANCE_OCF = 'OCF - Home Insurance';
export const SIMULATION_NEW_CARD_OR_DECLINE = 'OCF - Simulation New Card';
export const WHATS_CHANGED = 'OCF - Whats Changed';
export const CC_BORROWING_POWER = 'OCF - CC Borrowing Power';
export const INCREASE_CREDIT_LIMIT = 'OCF - Increase Total Credit Limit';
export const CARD_LIMITS = 'OCF - Card Limits';
export const USERS_LIKE_YOU = 'OCF - Members Like You';
export const CC_REWARDS_CATEGORIES = 'OCF - CC Rewards Categories';
export const REBALANCE_CREDIT_UTILIZATION =
  'OCF - Rebalance Credit Utilization';
export const INCREASE_CREDIT_LIMIT_BY_TRADELINES =
  'OCF - Increase Credit Limit By Tradelines';
export const ALL_PRODUCT = 'OCF - All Products';
export const CREDIT_FOUNDATION = 'OCF - Credit Foundation';
export const CREDIT_FOUNDATION_EXPERIMENT =
  'OCF - Credit Foundation experiment';
export const HOLIDAY_SPENDING = 'OCF - Holiday Spending';
export const CARD_COMPARISON = 'OCF - Card Comparison';
export const BECOME_ELIGIBLE_FOR_AWESOME_THINGS =
  'OCF - Become Eligible For Awesome Things';

export const HOME_REFINANCE_OCF = 'OCF - Home Refinance';
export const HOME_PURCHASE_OCF = 'OCF - Home Purchase';
export const CREDIT_CARD_CATEGORIES = 'OCF - Credit Card Categories';
export const AUTO_INSURANCE = 'OCF - Auto Insurance';
export const LIFE_INSURANCE_OCF = 'OCF - Life Insurance';
export const BALANCE_TRANSFER = 'OCF - Balance Transfer';
export const PRE_APPROVED_OCF = 'OCF - Pre Approved';
export const TEMPLATE_TOOL_OCF = 'OCF - Template Tool';

export const AUTO_REFINANCE_OCF = 'OCF - Auto Refinance';
export const AUTO_PURCHASE_OCF = 'OCF - Auto Purchase';
export const CC_APPROVAL_ODDS = 'OCF - Credit Card Approval Odds';
export const OTHER_MEMBERS_CARD_INTEREST = 'OCF - Other Members Card Interest';
export const CC_UTILIZATION = 'OCF - CC Utilization';
export const REFER_A_FRIEND = 'Refer a Friend OCF';
export const UNSUBSCRIBE = 'Email Unsubscribe';
export const BUILD_SCORE_OVER_TIME = 'Score Over Time OCF ';
export const PROFILE_LEGAL_AGREEMENT = 'Legal Agreements';
export const BORROWING_POWER_OCF = 'borrowingPower';
export const BUILDER_DEPOSIT_WITHDRAWAL = 'Builder Deposit Withdrawal';

export const INVISIBLE_LANDING = 'Credit Invisible Soft Landing';
export const INVISIBLE_CASH_ENROLLMENT_VERIFY_USER =
  'Sesame Cash Enroll Invisible - Verify User';
export const INVISIBLE_CASH_ENROLLMENT_EDIT_ADDRESS =
  'Sesame Cash Enroll Invisible - Edit Info - Address';
export const INVISIBLE_CASH_ENROLLMENT_CONFIRM_ADDRESS =
  'Sesame Cash Enroll Invisible - Info - Address';
export const INVISIBLE_CASH_ENROLLMENT_TERMS =
  'Sesame Cash Enrollment - Invisible';

export const BUILDER_MANUAL_CONFIRMATION = 'BUILDER_MANUAL_CONFIRMATION';
export const BUILDER_UTILIZATION_MANAGEMENT = 'BUILDER_UTILIZATION_MANAGEMENT';
export const BUILDER_VAULT_WITHDRAWAL = 'BUILDER_VAULT_WITHDRAWAL';
export const CHECKOUT_TURBO_3BGATING_7_DAY_FREE_PLAN = 'Turbo 3B Gating';
export const SUBSCRIPTION_VERTICAL = 'Subscription';

export const AUTOMATIC_DIRECT_DEPOSIT_LANDING = 'Direct Deposit Switch';
export const AUTOMATIC_DIRECT_DEPOSIT_LANDING_SUCCESS =
  'Direct Deposit Switch - Success Page';
export const AUTOMATIC_DIRECT_DEPOSIT_LANDING_PENDING =
  'Direct Deposit Switch - Processing Page';

//signup
export const SIGNUP_ACCT_CREATION = 'Signup 1 - Morpheus';
export const SIGNUP_PROFILE = 'Signup First_Last_Name - Morpheus';
export const SIGNUP_ADDRESS = 'Signup Address - Morpheus';
export const SIGNUP_PHONE_NUMBER = 'Signup Phone_Number - Morpheus';
export const SIGNUP_SSN = 'Signup Ssn - Morpheus';
export const SIGNUP_SSN_9_DIGIT = 'Signup Ssn 9 Digit - Morpheus';
export const SIGNUP_PASSWORD = 'Signup Password - Morpheus';
export const SIGNUP_QUESTIONS = 'Signup KBAs - Morpheus';
export const SIGNUP_DUPE_QUESTIONS = 'Signup DupSSN KBAs - Morpheus';
export const SIGNUP_DUPE_SSN = 'Signup SSN_Exist - Morpheus';
export const SIGNUP_SUMMARY = 'Signup Questions Not Received';
export const SIGNUP_BOOSTER_ENROLL = 'Signup Booster Waitlist';
export const SIGNUP_BOOSTER_CONGRATS = 'Signup Booster Congrats';
export const SIGNUP_CASH_ENROLLMENT = 'NU_cash_enrollment';
export const SIGNUP_DM_ACCT_CREATION = 'no_promocode_cash_account_creation';
export const SIGNUP_DUP_KBA_PHONE_VERIFICATION =
  'Signup DupSSN KBA Phone Verification';
export const SIGNUP_REVALIDATE_EMAIL = 'Signup Revalidate Email TU';
export const SIGNUP_TWO_FA_OTP_REQUEST =
  'Signup OTP Request before KBA - Morpheus';
export const SIGNUP_TWO_FA_OTP_VERIFY =
  'Signup OTP Verify before KBA - Morpheus';
export const SIGNUP_REVIEW_INFO =
  'Signup - Credit File Not Found - Review Information';
export const SIGNUP_UPDATE_INFO =
  'Signup - Credit File Not Found - Update Information';
export const SIGNUP_CHECK_OPTIONS =
  'Signup - Credit File Not Found - Check your options to Build Credit';
export const AFF_SIGNUP_DOB_SSN_FORM = 'Signup – Affiliate';
export const AFF_SIGNUP_INFO_FORM =
  'Signup Enter Additional User Details - Affiliate';
export const LIVE_EXPERTS = 'Live Experts';
export const RENT_PAYMENT_REPORTING_HOME = 'RENT PAYMENT REPORTING HOME';
export const PAYMENT_PENDING_SUBSCRIPTION = 'Payment Pending Subscription';

//one click signup
export const OCS_ACCT_CREATION_PREFILLED =
  'One Click Signup 1 - Prefilled Phone';
export const OCS_ACCT_CREATION_NO_PREFILL = 'One Click Signup 1 - No Prefill';
export const OCS_SMS_SENT = 'One Click Signup 1 - Sms Sent';
export const OCS_OTP_SENT = 'One Click Signup 1 - Otp Sent';
export const OCS_VERIFY_SMS_SUCCESS = 'One Click Signup 1 - Verify Sms Success';
export const OCS_VERIFY_SMS_FAILURE = 'One Click Signup 1 - Verify Sms Failure';
export const OCS_INIT_SMS_VERIFICATION =
  'One Click Signup 1 - Init SMS Verification';
export const OCS_INIT_SMS_VERIFICATION_FAILURE =
  'One Click Signup 1 - Init SMS Verification Failure';
export const OCS_CONFIRM_INFO_FORM = 'One Click Signup 2 - Confirm Info Form';
export const OCS_EDIT_ALL_FORM = 'One Click Signup 2 -  Edit All Form';
export const OCS_DUPE_SSN_LOADING = 'One Click Signup 2 - Dupe Ssn Loading';
export const OCS_EDIT_ADDRESS = 'One Click Signup 2 - Edit Address';
export const OCS_EMAIL_STEP_FALLBACK_FORM =
  'One Click Signup 2 - Email Fallback Form';
export const OCS_ADDRESS_STEP_FALLBACK_FORM =
  'One Click Signup 2 - Address Fallback Form';
export const OCS_PASSWORD_STEP_FALLBACK_FORM =
  'One Click Signup 2 - Password Fallback Form';
export const CREDIT_REPORTS_TU_VIEW = 'Credit Report TU View';
export const CREDIT_REPORTS_TU_PRINT = 'Credit Report TU Print';
export const CREDIT_LOCK_LEARN_MORE = 'Credit Lock Learn more';
export const CLAIM_OFFER = 'Claim Offer';
export const PREMIUM_STARTER_PACK = 'Premium Starter Pack';
export const ENROLL_PREMIUM_MARKETING_CAMPAIGN =
  'Enroll Premium Marketing Campaign';
export const SIGNUP_KBA_ENTER_NAME_AND_DOB = 'Signup KBAs - Enter Name and DOB';
export const SIGNUP_KBA_ENTER_HOME_ADDRESS = 'Signup KBAs - Enter Home Address';
export const SIGNUP_KBA_REVIEW_INFORMATION = 'Signup KBAs - Review Information';
export const OCS_CHECK_YOUR_TEXT_SENT =
  'One Click Signup 1 - Instant Link Sent';

//login passwordless
export const LOGIN = 'Log in';
export const PASSWORD_LESS_LOGIN =
  'One Time Password Login - Enter Phone Number';
export const PASSWORD_LESS_LOGIN_SSN =
  'One Time Password Login - Enter Last 4 Digits of SSN';
export const PASSWORD_LESS_LOGIN_OTP = 'One Time Password Login - Enter OTP';

export const REACTIVATE_PREMIUM = 'Reactivate Premium ';
export const CHECKOUT = 'CHECKOUT';
export const CHECKOUT_PLUS = 'Checkout Plus';

export const GOALS = 'Goals';
export const CHANGE_PAYMENT_METHOD = 'Change Payment Method';
export const SELECT_PAYMENT_METHOD = 'Select Payment Method';

export const BUILDER_MANUAL_PAYMENT_V2 = 'Manual Payment';
export const BUILDER_MANUAL_PAYMENT_SUCCESS_V2 = 'Manual Payment Success';

export const ONE_CLICK_APPLICATION = 'CC Hosted Application';
export const ATM_LOCATOR = 'Sesamecash ATM Locator';
export const CREDIT_REPORTS_TABS = 'Credit Report Tabs';
export const CARD_ACTIVATION_LANDING = 'Activate Sesame Cash Card';
export const CARD_ACTIVATION_SUCCESS = 'Sesame Cash Card Activated Success';
export const CREDIT_MANAGER = 'Calendar';
export const ORDER_HISTORY = 'Order History';
export const SCORE_SIMULATOR_PAGE = 'Score Simulation Page';
