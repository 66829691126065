import React from 'react';
import { AccountLockedError } from './AccountLockedError';
import { CreditProfileError } from './CreditProfileError';
import { DefaultError } from './DefaultError';
import {
  GENERAL,
  GENERAL_TU,
  DEVICE_BLOCKED,
  DUP_DROP,
  DUP_ERROR,
  ACCOUNT_LOCKOUT,
  CREDIT_PROFILE_ERR,
  QUESTIONS_NOT_RECEIVED,
} from '../types/signupError.constants';
import '../../signup.scss';

export const SignupErrorPrimaryView = props => {
  const {
    errorType,
    gotoCustomerSupport,
    gotoCCMarketplace,
    showButtonToExploreOffers,
  } = props;

  switch (errorType) {
    case ACCOUNT_LOCKOUT:
      return (
        <AccountLockedError
          errorType={errorType}
          onButtonClick={gotoCCMarketplace}
          dynamicErrorMessage={props.signup.errorMessage}
        />
      );
    case CREDIT_PROFILE_ERR:
    case QUESTIONS_NOT_RECEIVED:
      return (
        <CreditProfileError
          errorType={errorType}
          onButtonClick={gotoCCMarketplace}
          showButtonToExploreOffers={showButtonToExploreOffers}
        />
      );
    case GENERAL_TU:
      return (
        <DefaultError
          hideCustomerSupportCTA
          showThirtyDayMessage
          errorType={errorType}
          onButtonClick={gotoCustomerSupport}
        />
      );
    case GENERAL:
    case DEVICE_BLOCKED:
    case DUP_DROP:
    case DUP_ERROR:
    default:
      return (
        <DefaultError
          errorType={errorType}
          onButtonClick={gotoCustomerSupport}
        />
      );
  }
};
