import { LOGOUT } from 'types/app.constants';
import { OCS_EDIT_ALL_FORM } from 'types/mrph.pages.constants';
import { oneClickSignupConstants as signupConstants } from './OneClickSignup.constants';

const initialState = {
  loading: false,
  signup1Step: null,
  signup2Step: null,
  redirectUrl: null,
  email: null,
  phoneNumber: null,
  last4Ssn: null,
  signup2Data: null,
  dupeSsnFirstName: null,
  tempPassword: null,
  addressValidated: false,
  isSsnValidation: false,
  formInitCompleted: false,
  goOneClickSignup2: false,

  initialDataForApiTemp: null,
  eligibleForPrefill: null,
  prefillPhoneEmail: false,
  clearSSN: false,
  proveElegibleAudience: false,
  errorTU: null,
  errorSectionTU: null,

  fingerPrintVerificationLoading: false,
  firgerPrintVerification: null,
  fingerPrintVerificationError: null,
};

const oneClickSignupReducer = (
  state = Object.assign({}, initialState),
  action,
) => {
  switch (action.type) {
    case signupConstants.LOADING_START:
      return Object.assign({}, state, {
        loading: true,
      });
    case signupConstants.LOADING_FINISH:
      return Object.assign({}, state, {
        loading: false,
      });
    case signupConstants.SET_REDIRECT_URL:
      return Object.assign({}, state, {
        redirectUrl: action.redirectUrl,
      });
    case signupConstants.PREFILL_START_SUCCESS:
      return Object.assign({}, state, {
        redirectUrl: action.redirectUrl,
      });
    case signupConstants.SIGNUP_1_NO_PREFILL_SUBMIT_SUCCESS:
      return Object.assign({}, state, {
        email: action.email,
        phoneNumber: action.phoneNumber,
        last4Ssn: action.last4Ssn,
        dob: action.dob,
        tempPassword: action.tempPassword,
        changeSSNbyDOB: action.changeSSNbyDOB,
      });
    case signupConstants.INIT_SSN_VALIDATION:
      return Object.assign({}, state, {
        isSsnValidation: true,
      });
    case signupConstants.INIT_SIGNUP_2:
      return Object.assign({}, state, {
        email: action.email,
        phoneNumber: action.phoneNumber,
        last4Ssn: action.last4Ssn,
        tempPassword: action.tempPassword,
        signup2Data: action.signup2Data,
        signup1Step: null,
      });
    case signupConstants.CREATE_USER_SUCCESS:
    case signupConstants.UPDATE_PASSWORD_SUCCESS:
      return Object.assign({}, state, {
        tempPassword: action.tempPassword,
      });
    case signupConstants.UPDATE_ADDRESS_FAIL:
      return Object.assign({}, state, {
        loading: false,
        addressValidated: action.addressValidated,
      });
    case signupConstants.REPORT_TU_ERROR:
      return Object.assign({}, state, {
        errorTU: action.errorTU,
        errorSectionTU: action.errorSectionTU,
      });
    case signupConstants.CLEAR_TU_ERROR:
      return Object.assign({}, state, {
        errorTU: null,
        errorSectionTU: null,
      });
    case signupConstants.INIT_DUPE_SSN_LOADING:
      return Object.assign({}, state, {
        dupeSsnFirstName: action.dupeSsnFirstName,
      });
    case signupConstants.INIT_EDIT_ALL_FORM:
      return Object.assign({}, state, {
        signup2Data: action.signup2Data,
        formInitCompleted: false,
        signup2Step: OCS_EDIT_ALL_FORM,
      });
    case signupConstants.FORM_INIT_COMPLETED:
      return Object.assign({}, state, {
        formInitCompleted: true,
      });
    case signupConstants.SET_SIGNUP_1_STEP:
      return Object.assign({}, state, {
        signup1Step: action.step,
      });
    case signupConstants.SET_SIGNUP_2_STEP:
      return Object.assign({}, state, {
        signup2Step: action.step,
      });
    case signupConstants.SET_SIGNUP_ELIGIBLE_FOR_PREFILL:
      return Object.assign({}, state, {
        eligibleForPrefill: action.eligibleForPrefill,
      });
    case signupConstants.SET_SIGNUP_PROVE_ELEGIBLE_AUDIENCE:
      return Object.assign({}, state, {
        proveElegibleAudience: action.proveElegibleAudience,
      });
    case signupConstants.SET_SIGNUP_PREFILL_PHONE_EMAIL:
      return Object.assign({}, state, {
        prefillPhoneEmail: action.prefillPhoneEmail,
        clearSSN: action.prefillPhoneEmail,
      });
    case signupConstants.SET_ONE_CLICK_SIGNUP_2:
      return Object.assign({}, state, {
        goOneClickSignup2: true,
      });
    case signupConstants.SET_FINGER_PRINT_VERIFICATION:
      return {
        ...state,
        firgerPrintVerification: action?.firgerPrintVerification,
        fingerPrintVerificationLoading: action?.fingerPrintVerificationLoading,
        fingerPrintVerificationError: action?.fingerPrintVerificationError,
      };
    case signupConstants.CLEAR_SIGNUP_STATE:
    case LOGOUT:
      return Object.assign({}, initialState);
    default:
      return state;
  }
};

export default oneClickSignupReducer;
